    import React, { useContext, useEffect, useState } from 'react';
    import { AuthContext } from '../AuthContext';
    import { Link, useNavigate } from 'react-router-dom';
    // import './SideNavBar.css';

    const SideNavBar = ({ 
        currentProgram,
        currentView,
        membershipDetails,
        organizationPrograms,
        setCurrentProgram,
        setCurrentView, 
        setProgramToModify,
        setShowCreateProgramModal,
        setShowPaywallModal,
        allGoodFitScoresWithActiveStatusForOrganization,
        allFunderScoresWithActiveStatusForOrganization,
        setSelectedOrganizationEin,
        showOnboardingFlow,
        setShowOnboardingFlow
    }) => {

        const { authUser, handleSignOut } = useContext(AuthContext);    
        const navigate = useNavigate();

        const handleSignOutButtonClicked = () => {
            console.log("Signing out");
            setShowOnboardingFlow(false);
            handleSignOut();            
        }

        const handleCreateProgramButtonClicked = () => {
            if (!membershipDetails) {
                setShowPaywallModal(true);
            } else {
                if (organizationPrograms.length <= 3) {
                    console.log("Create program")
                    setProgramToModify(null)
                    setShowCreateProgramModal(true)
                } else {
                    alert("You have reached the maximum number of programs for your plan. Please upgrade to a higher plan to create more programs.");
                    setShowPaywallModal(true);
                }
            }
        };

        return (
            <div className='md:bg-gray-300 hidden md:block md:w-1/4 p-6 text-left flex flex-col items-start h-screen'>
                {/* Desktop Side Nav */}
                <div className='hidden md:block '>

                    <div className='w-full flex flex-col'>
                        <button 
                            className='text-lg mt-6 text-left' 
                            style={{fontStyle: 'cursive', fontWeight: '900'}} 
                            onClick={() => {
                                console.log("Logo home button clicked")
                                setSelectedOrganizationEin(null); // resets selected organization ein; without this, navigating out of /profiles/organization pages takes two clicks
                                navigate('/');
                                setCurrentView('homeDashboard');                                
                            }}>🪄 GrantMagic</button>
                        <p className='text-xs text-gray-700 mt-2'>Building Daisy, the AI grant writer</p>
                    </div>
                    {authUser && !showOnboardingFlow ? (
                        <div className='w-full' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', height: '100vh' }}>                                               

                            <button 
                                    className={`w-full mt-12 text-md py-3 text-left px-2 rounded-lg hover:bg-gray-200 ${(currentView === 'homeDashboard' || currentView === 'selectedProposalPage') ? 'bg-gray-400 font-bold text-white hover:bg-gray-400' : ''}`} 
                                    onClick={() => {
                                        setSelectedOrganizationEin(null); // resets selected organization ein; without this, navigating out of /profiles/organization pages takes two clicks
                                        navigate('/');
                                        setCurrentView('homeDashboard');
                                    }}
                                >
                                    🏠  Home
                            </button>   
                            
                            {/* Programs Section */}
                            <div className='w-full mt-6'>
                                <div className='flex justify-between items-center'>
                                    <p className='text-xs font-bold'>YOUR PROGRAMS</p>
                                    <button 
                                        className='bg-gray-200 py-1 px-2 text-xs rounded-lg'
                                        onClick={handleCreateProgramButtonClicked}
                                    >
                                        + NEW
                                    </button>
                                </div>
                                
                                {/* List out all programs */}
                                <div className={`flex flex-col gap-2 my-2 ${organizationPrograms.length <= 2 ? 'auto' : 'h-36 overflow-y-scroll'}`}>
                                    {organizationPrograms.map((program, index) => (
                                        <button 
                                            key={program.id} 
                                            className={`w-full py-3 px-2 hover:bg-gray-200 rounded-lg text-left ${(currentView === 'selectedProgramView' && currentProgram && currentProgram.programName === program.programName) ? 'bg-gray-400 font-bold text-white hover:bg-gray-400' : ''}`}
                                            onClick={() => {
                                                console.log("Program clicked", program);
                                                setCurrentView('selectedProgramView');
                                                setCurrentProgram(organizationPrograms[index]);
                                            }}
                                        >
                                            <div className='flex justify-between items-center'>
                                                <p className='text-sm'>{program.programName}</p>
                                                {membershipDetails && (
                                                    <div className='text-xs text-center text-white bg-indigo-600 rounded-lg px-3 py-1'>
                                                        {allGoodFitScoresWithActiveStatusForOrganization?.filter(obj => obj.grantseeking_program_id === program.id).length + allFunderScoresWithActiveStatusForOrganization?.filter(obj => obj.grantseeking_program_id === program.id).length}
                                                    </div>
                                                )}
                                            </div>

                                        </button>
                                    ))}
                                </div>
                            </div>
                                
                            
                            <div className="w-full mt-6 text-md">
                                <p className='text-xs font-bold my-4'>OTHER FEATURES</p>
                                <button 
                                    className={`w-full py-3 text-left px-2 rounded-lg hover:bg-gray-200 ${(currentView === 'awardsAdvancedSearch' || currentView === 'selectedProposalPage') ? 'bg-gray-400 font-bold text-white hover:bg-gray-400' : ''}`} 
                                    onClick={() => {
                                        setSelectedOrganizationEin(null); // resets selected organization ein; without this, navigating out of /profiles/organization pages takes two clicks
                                        navigate('/');
                                        setCurrentView('awardsAdvancedSearch');
                                    }}
                                >
                                    🔍 Awards Search
                                </button>      
                                
                                <button 
                                    className={`w-full py-3 text-left px-2 rounded-lg hover:bg-gray-200 ${(currentView === 'documentLibrary' || currentView === 'grantDocumentEditor') ? 'bg-gray-400 font-bold text-white hover:bg-gray-400' : ''}`} 
                                    onClick={() => {
                                        setSelectedOrganizationEin(null); // resets selected organization ein; without this, navigating out of /profiles/organization pages takes two clicks
                                        navigate('/');
                                        setCurrentView('documentLibrary');
                                    }}
                                >
                                    🖊️  All Magic Drafts
                                </button>
                                <button 
                                    className={`w-full py-3 text-left px-2 rounded-lg hover:bg-gray-200 ${(currentView === 'tasksDashboard' || currentView === 'selectedProposalPage') ? 'bg-gray-400 font-bold text-white hover:bg-gray-400' : ''}`} 
                                    onClick={() => {
                                        setSelectedOrganizationEin(null); // resets selected organization ein; without this, navigating out of /profiles/organization pages takes two clicks
                                        navigate('/');
                                        setCurrentView('tasksDashboard');
                                        console.log("Tasks button clicked");
                                    }}
                                >
                                    🗂️  All Tasks 
                                </button>
                                <button 
                                    className={`w-full px-2 rounded-lg py-3 hover:bg-gray-200 text-left ${currentView === 'daisySettings' ? 'bg-gray-400 font-bold text-white hover:bg-gray-400' : ''}`} 
                                    onClick={() => {
                                        setSelectedOrganizationEin(null); // resets selected organization ein; without this, navigating out of /profiles/organization pages takes two clicks
                                        navigate('/');
                                        setCurrentView('daisySettings');
                                    }}
                                >
                                    🧠 Training Data 
                                </button>
                            </div>
                            
                            
                            <div className='w-full flex flex-col text-sm mt-6 text-gray-700 '>
                                <a className='mb-1 hover:text-gray-900' href="https://ossified-pluto-bdd.notion.site/GrantMagic-Documentation-17546353e32d8076a042c52f7b792c8d?pvs=4" target="_blank" rel="noopener noreferrer">Documentation</a>                    
                                <button 
                                    className='mb-1 text-left hover:text-gray-900' 
                                    onClick={() => {
                                        setSelectedOrganizationEin(null); // resets selected organization ein; without this, navigating out of /profiles/organization pages takes two clicks
                                        setCurrentView('accountSettings');
                                    }}
                                >Account Settings</button>
                                <a className='mb-1 hover:text-gray-900' href="https://tally.so/r/mDVa5j" target="_blank" rel="noopener noreferrer">Support</a>                    
                                <a className='mb-1 hover:text-gray-900' href="#" onClick={handleSignOutButtonClicked} >Logout</a>    
                            </div>

                        </div>
                    ) : (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <h2 className="font-bold mt-10">Settings</h2>
                            <a href="https://tally.so/r/mDVa5j" className="" target="_blank" rel="noopener noreferrer">Support</a>
                            {!authUser && (
                                <>
                                    <Link to="/signup" className="">Sign Up</Link>          
                                    <Link to="/login" className="">Log In</Link>
                                </>
                            )}
                            {showOnboardingFlow == true && authUser && (
                                <>
                                    <a className='mb-1 hover:text-gray-900' href="#" onClick={handleSignOutButtonClicked} >Logout</a>
                                </>
                            )}

                        </div>
                    )}
                </div>
            </div>
        );
    };

    export default SideNavBar;
