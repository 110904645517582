import React, { useState, useEffect, useContext } from 'react';
import { markFileAsDeletedInFirestore, fetchGrantDocumentsFromFirestoreForClientOrgId, uploadFileToFirebaseStorage, saveFileMetadataToFirestore, saveOpenAiFileIdToFirestore } from '../utilityFunctions/firestoreUtilities.js';
import { deleteFileFromOpenAiVectorStore, uploadFileToOpenAI, addFileToAssistantVectorStore } from '../utilityFunctions/openAiUtilities.js';
import InfoBubble from './HelperComponents/InfoBubble.js';
import { generateUniqueFileId } from '../utilityFunctions/uxUtilities';
import { AuthContext } from '../AuthContext';
import daisySprite from '../assets/daisySprite.png';

function DaisySettings({
    assistantFiles,
    assistantId,
    deleteAssistantFile,
    assistantVectorStoreId,
    setAssistantFiles,
    setShowLoadingModal,
    fetchAssistantFiles,    
}) {    

    const { authUser } = useContext(AuthContext);

    // Upon modal load, fetch the assistant files from the vector store
    useEffect(() => {
        handleFileRetrieval();
    }, []);

    async function handleFileRetrieval() {
        setShowLoadingModal(true);
        await fetchAssistantFiles(assistantVectorStoreId);
        setShowLoadingModal(false);
    }

    // Upload a user file to Firestore and OpenAI, then add the file to the assistant vector store
    const handleUserFileUpload = async (file) => { 
        if (!file) {
            alert("No file selected for upload.");
            console.error("No file selected for upload.");
            return;
        }

        // Check file size (2 MB = 2 * 1024 * 1024 bytes)
        if (file.size > 2 * 1024 * 1024) {
            alert("File size exceeds 2 MB. Please select a smaller file.");
            console.error("File size exceeds 2 MB.");
            return;
        }
        
        try {
            setShowLoadingModal(true)

            // Set a timeout for the upload process
            const timeout = new Promise((_, reject) => 
                setTimeout(() => reject(new Error("File upload timed out. Please try again.")), 30000) // 30 seconds timeout
            );

            const uniqueFileId = generateUniqueFileId();
            const downloadURL = await Promise.race([uploadFileToFirebaseStorage(file, uniqueFileId), timeout]);
            const firestoreRef = await saveFileMetadataToFirestore(uniqueFileId, file.name, downloadURL, authUser.uid);
            const openaiFileId = await Promise.race([uploadFileToOpenAI(file), timeout]);
            await saveOpenAiFileIdToFirestore(firestoreRef, openaiFileId);
            await addFileToAssistantVectorStore(assistantVectorStoreId, openaiFileId);
            fetchAssistantFiles(assistantVectorStoreId);
            setShowLoadingModal(false);
        } catch (error) {
            setShowLoadingModal(false); // Ensure loading modal is hidden
            alert(`Error uploading file: ${error.message}`); // Convey error to user
            console.error("Error uploading file:", error);
        }
    };  

    // Delete an assistant file from the vector store and firestore
    async function deleteAssistantFile(fileId) {
        if (window.confirm("Are you sure you want to permanently delete this file?")) {
            try {
                await deleteFileFromOpenAiVectorStore(assistantVectorStoreId, fileId);
                await markFileAsDeletedInFirestore(fileId);
                setAssistantFiles(prevFiles => prevFiles.filter(file => file.id !== fileId));
            } catch (error) {
                console.error("Failed to delete assistant file: ", error);
            }
        }
    }

    return (
        <div className="p-12">
            <p className="text-3xl font-bold text-left mb-4">Training Data</p> 
            
            {/* Daisy Header */}
            <div className="w-full rounded-lg bg-gray-200 p-4 ">
                <div className="flex items-center">                     
                    <div className="flex items-center">
                        <img src={daisySprite} alt="Daisy Sprite" className="w-12 h-12 mr-4 rounded-full" />
                    </div>                    
                    <p className="text-lg">I will learn from the documents you upload on this page. <span className="text-indigo-600 hover:underline cursor-pointer" onClick={() => window.open("https://www.notion.so/GrantMagic-Documentation-17546353e32d8076a042c52f7b792c8d?pvs=4#17546353e32d804c9c9bc0c8616854d0", "_blank")}>Learn more</span></p>                    
                </div>
            </div>           

            <div className="text-left">
                <div className="flex-1 my-4">
                    <div className="flex justify-between items-center mb-4">
                        <div className="flex items-center">
                            <p className="text-left text-gray-400 font-bold">UPLOADED FILES</p>                   
                            <InfoBubble 
                                text="These are documents uploaded to GrantMagic that will be used to train the AI assistant." 
                                learnMoreUrl="https://www.google.com"
                            />
                        </div>
                        <input 
                            type="file" 
                            id="fileInput" 
                            accept=".doc, .docx, .md, .pdf, .txt"
                            style={{ display: 'none' }} 
                            onChange={async (event) => {
                                const file = event.target.files[0];
                                if (file) {
                                    await handleUserFileUpload(file); 
                                }
                            }} 
                        />
                        <button 
                            onClick={() => document.getElementById('fileInput').click()} 
                            className="text-white bg-indigo-500 hover:bg-blue-700 text-sm font-bold py-2 px-4 rounded"
                        >
                            Upload
                        </button>
                    </div>
                    
                    <div className="flex-1 border-2 border-gray-100 rounded-md text-center h-full">                            
                    {assistantId ? (
                        <>                            
                            <table className="min-w-full text-left divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            File Name
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Created At
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {assistantFiles.map((file, index) => (
                                        <tr key={index}>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                {file.downloadURL ? (
                                                    <a href={file.downloadURL} target="_blank" rel="noopener noreferrer" className="text-sm text-indigo-600 hover:underline">
                                                        {file.fileName}
                                                    </a>
                                                ) : (
                                                    <div className="text-sm text-gray-900">{file.fileName}</div>
                                                )}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-gray-500">{new Date(file.created_at * 1000).toLocaleString()}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap  text-sm ">
                                                <button onClick={() => deleteAssistantFile(file.id)} className="text-red-300 hover:text-red-600">Delete</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            </>
                        ) : (
                            <p>No assistant files found</p>
                        )}
                    </div>
                </div>
                <div className="flex-1 mt-8">
                    <div className="flex justify-between items-center mb-4">
                        <div className="flex items-center">
                            <p className="text-left text-gray-400 font-bold">DAISY SETTINGS (FOR DEBUGGING)</p>
                        </div> 
                    </div>
                    <div className="flex-1 text-gray-500 italic rounded-lg h-full text-left text-sm">                            
                        <p>Assistant ID: {assistantId}</p>
                        <p>Assistant Vector Store ID: {assistantVectorStoreId}</p>
                    </div>
                </div>
            </div>                
        </div>                
    );
}

export default DaisySettings;