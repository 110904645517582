import { useContext, useState, useEffect } from 'react';
// import { createProgramInFirestore, updateProgramInFirestore, deleteProgramFromFirestore } from '../../utilityFunctions/firestoreUtilities';
import { AuthContext } from '../../AuthContext';
import {  updateTaskInFirestore, deleteTaskInFirestore } from '../../utilityFunctions/firestoreUtilities';
// import { cleanText } from '../../utilityFunctions/uxUtilities';

const ManageTaskModal = ({ 
    setShowManageTaskModal,
    setSelectedTask,
    organizationPrograms,
    currentClientOrganization,
    selectedTask,
    refreshTasks,
    savedOpportunities
    }) => {

    const { authUser } = useContext(AuthContext);
    

    const handleUpdateTask = async (e) => {
        e.preventDefault();

        const formattedDeadline = new Date(deadline).toISOString().split('T')[0];

        const task = {
            name: name,
            deadline: formattedDeadline,
            linkedOpportunity: linkedOpportunity,
            linkedProgram: linkedProgram,
            currentClientOrganizationId: currentClientOrganization.id,
            userId: authUser.uid,
            status: status,
            createdAt: new Date(),
            updatedAt: new Date()
        };
        await updateTaskInFirestore(selectedTask.id, task);
        refreshTasks()
        console.log("Task: ", task);
        setShowManageTaskModal(false);        
    };

    const handleDeleteTask = async (e) => {
        e.preventDefault();
        const userConfirmed = window.confirm("Are you sure you want to delete this task?");
        if (userConfirmed) {
            await deleteTaskInFirestore(selectedTask.id);
            refreshTasks();
            setShowManageTaskModal(false);
        }
    };


    // New state variables for the form fields
    const [name, setName] = useState(selectedTask?.name || null);
    const [deadline, setDeadline] = useState(selectedTask?.deadline || null);
    const [status, setStatus] = useState(selectedTask?.status || null);
    const [linkedOpportunity, setLinkedOpportunity] = useState(selectedTask?.linkedOpportunity?.id || selectedTask?.linkedOpportunity || null);
    const [linkedProgram, setLinkedProgram] = useState(selectedTask?.linkedProgram?.id || selectedTask?.linkedProgram || null);

    // Function to handle form submission
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     console.log({ name, deadline, linkedOpportunity, linkedProgram });
    // };

    const handleModalDismiss = () => {
        setSelectedTask(null);
        setShowManageTaskModal(false);
        
    };

    return (
        <div 
            className="modal-overlay" 
            style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}
            onClick={handleModalDismiss}
        >
            <div 
                className="modal p-8 overflow-auto max-h-full" 
                style={{ 
                    cursor: 'default', 
                    width: '40vw', 
                    height: '70vh', 
                    backgroundColor: 'white', 
                    borderRadius: '10px'
                }}
                onClick={(e) => e.stopPropagation()} // Prevent click event from propagating to the overlay
            >
                <div className="text-2xl font-bold py-4">Manage Task</div>
                <form className="flex flex-col gap-2 text-left">
                    <label className="mb-1 font-semibold text-sm text-gray-500">Task</label>
                    <input 
                        type="text" 
                        placeholder="Create a first draft of the RFP" 
                        value={name} 
                        onChange={(e) => setName(e.target.value)} 
                        className="mb-2 p-2 border border-gray-300 rounded"
                    />
                    <label className="mb-1 font-semibold text-sm text-gray-500">Status</label>
                    <select 
                        value={status} 
                        onChange={(e) => setStatus(e.target.value)} 
                        className="mb-2 p-2 border border-gray-300 rounded"
                    >
                        <option value="Not Started">Not Started</option>
                        <option value="In Progress">In Progress</option>
                        <option value="Completed">Completed</option>
                    </select>
                    <label className="mb-1 font-semibold text-sm text-gray-500">Deadline</label>
                    <input 
                        type="date" 
                        value={deadline} 
                        onChange={(e) => setDeadline(e.target.value)} 
                        className="mb-2 p-2 border border-gray-300 rounded"
                    />
                    <label className="mb-1 font-semibold text-sm text-gray-500">Linked Program</label>
                    <select 
                        value={linkedProgram || ""}
                        onChange={(e) => setLinkedProgram(e.target.value)} 
                        className="mb-2 p-2 border border-gray-300 rounded"
                    >
                        <option value="" disabled>Select a Program</option>
                        {organizationPrograms.map((program) => (
                            <option key={program.id} value={program.id}>
                                {program.programName}
                            </option>
                        ))}
                    </select>
                    <label className="mb-1 font-semibold text-sm text-gray-500">Linked Opportunity</label>
                    <select 
                        value={linkedOpportunity || ""} 
                        onChange={(e) => setLinkedOpportunity(e.target.value)} 
                        className="mb-2 p-2 border border-gray-300 rounded"
                    >
                        <option value="" disabled>Select an Opportunity</option>                        
                        {savedOpportunities
                            .filter(opportunity => opportunity.grantSeekingProgramId === linkedProgram)
                            .map((opportunity) => (
                                <option key={opportunity.id} value={opportunity.id}>
                                    {opportunity.name}
                                </option>
                            ))}
                    </select>
                    
                    <button 
                        type="submit" 
                        onClick={handleUpdateTask} 
                        className="p-2 bg-indigo-700 hover:bg-indigo-900 text-white rounded"
                    >
                        Update
                    </button>
                    <button 
                        onClick={handleDeleteTask} 
                        className="p-2 text-red-500"
                    >
                        Delete
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ManageTaskModal;