
const TasksTab = ({        
    selectedSavedOpportunity,
    setSelectedTask,
    setShowManageTaskModal,
    setShowCreateTaskModal,
    tasks
}) => {

    // Filter out tasks that are not for the selected opportunity
    const filteredTasks = tasks.filter(task => task.linkedOpportunity === selectedSavedOpportunity.id);
    
    return (
        <div>
            {filteredTasks.length > 0 ? (
                <div className='p-4'>
                    {filteredTasks.map(task => (
                        <div key={task.id} 
                            className='p-4 bg-gray-100 rounded-md mb-4 cursor-pointer'
                            onClick={() => {
                                console.log("Task to update from SOT: ", task);
                                setSelectedTask(task);
                                setShowManageTaskModal(true);                                
                            }}
                        >
                            <h2 className='text-xl font-bold'>{task.name}</h2>
                            <p className='text-sm text-gray-500'>Status: {task.status}</p>
                            <p className='text-sm text-gray-500'>Deadline: {task.deadline ? task.deadline : "No deadline set"}</p>
                            <p className='text-sm text-gray-500'>Created At: {new Date(task.createdAt.seconds * 1000).toLocaleString()}</p>
                        </div>
                    ))}

                    <button className='bg-gray-500 text-white px-4 py-2 rounded-md' onClick={() => {
                        console.log("Create new task from SOT");
                        setShowCreateTaskModal(true);
                    }}>Create new task</button>
                </div>
            ) : (
                <div className='p-12 text-center'>
                    <p>No tasks found for this opportunity.</p>
                    <button className='bg-gray-500 my-4 text-white px-4 py-2 rounded-md' onClick={() => {
                        console.log("Create new task from SOT");
                        setShowCreateTaskModal(true);
                    }}>Create new task</button>
                </div>
                
            )}
        </div>
    );
};

export default TasksTab;