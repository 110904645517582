import React, { useState, useEffect } from 'react';
import { updateOpportunityIdOfADocumentInFirestore, updateOpportunityIdOfATaskInFirestore } from '../../utilityFunctions/firestoreUtilities';

function UnknownOpportunityBubble({ 
        rowData,
        savedOpportunities,
        documentOrTask,
        refreshOrganizationDocuments,
        refreshTasks
    }) {
    
    const [filteredSavedOpportunities, setFilteredSavedOpportunities] = useState([]);

    console.log("savedOpportunities", savedOpportunities);
        
    useEffect(() => {
        if (savedOpportunities) {
            setFilteredSavedOpportunities(savedOpportunities.filter(opportunity => opportunity.grantSeekingProgramId === rowData.linkedProgram));
        }
    }, [savedOpportunities, rowData]);
    
    const [isSelectOpportunityModalOpen, setIsSelectOpportunityModalOpen] = useState(false);
    const [modalPosition, setModalPosition] = useState({ top: '50%', left: '50%' });

    const handleCloseModal = () => {
        setIsSelectOpportunityModalOpen(false);
    };

    const handleAddProgamClicked = (event) => {
        const { clientX, clientY } = event;
        setModalPosition({ top: `${clientY}px`, left: `${clientX}px` });
        setIsSelectOpportunityModalOpen(true);
    };

    const opportunitySelectedInModal = async (opportunityId) => {    
        console.log("opportunitySelectedInModal! Opportunity ID: ", opportunityId);
        console.log("documentOrTask", documentOrTask);
        
        if (documentOrTask === "document") {
            await updateOpportunityIdOfADocumentInFirestore(rowData.id, opportunityId);
            console.log("Document updated successfully!");
            refreshOrganizationDocuments();
        } else if (documentOrTask === "task") {
            await updateOpportunityIdOfATaskInFirestore(rowData.id, opportunityId);
            console.log("Task updated successfully!");
            refreshTasks();
        }
    };


    return (
        <>                
        <button 
            className="px-2 py-1 rounded text-indigo-500 hover:font-bold hover:text-indigo-700"
            onClick={handleAddProgamClicked}
        >
            + Opportunity
        </button>
         
        {/* Select Opportunity Modal */}
        {isSelectOpportunityModalOpen && (
            <div 
                onClick={handleCloseModal}
                style={{ 
                    position: 'absolute', 
                    top: '0', 
                    left: '0', 
                    width: '100%', 
                    height: '100%', 
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    cursor: 'default',
                    zIndex: 999
                }}
            >
                <div 
                    onClick={(e) => e.stopPropagation()}
                    style={{ 
                        position: 'absolute', 
                        top: modalPosition.top, 
                        left: modalPosition.left, 
                        transform: 'translate(-50%, -50%)', 
                        backgroundColor: 'white', 
                        border: '1px solid #ccc', 
                        borderRadius: '8px', 
                        padding: '20px', 
                        zIndex: 1000
                    }}
                >
                    <p className='text-xs text-gray-500'>Select an Opportunity</p>
                    <ul className='py-4'>
                        {filteredSavedOpportunities.map(opportunity => (
                            <li 
                                key={opportunity.id} 
                                onClick={() => { opportunitySelectedInModal(opportunity.id); handleCloseModal(); }}
                                className="cursor-pointer hover:bg-gray-100 px-2 py-2 rounded-md"
                            >
                                {opportunity.name}
                            </li>
                        ))}
                    </ul>
                    <button className='text-gray-500 hover:font-bold hover:text-gray-700' onClick={handleCloseModal}>Close</button>
                </div>
            </div>
        )}
        </>
    );
}

export default UnknownOpportunityBubble;