// src/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
// import { auth } from './firebase';
import { auth } from "./config/firebaseConfig";
// import { onAuthStateChanged, signOut } from 'firebaseConfig/auth';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import posthog from 'posthog-js';
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log('user', user);
      if (user) {        
        setAuthUser(user);
        if(user.email){
          posthog.identify(user.email)
          posthog.people.set({ email: user.email })
        }
        if(user.uid){
          posthog.identify(user.uid)
        }
      }
    });
  
    return () => unsubscribe();
  }, []);
  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log('sign out successful');
        window.location.reload(); // Refresh the page after successful sign out
      })
      .catch((error) => console.log(error));
  };
  return (
    <AuthContext.Provider value={{ authUser, handleSignOut }}>
      {children}
    </AuthContext.Provider>
  );
};      
