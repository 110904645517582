import React from 'react';

function InfoBubble({ 
        text, 
        learnMoreUrl 
    }) {
    const [isHovered, setIsHovered] = React.useState(false);

    return (
        <div 
            className="ml-2 relative group"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <span className="text-blue-500 cursor-pointer">ℹ️</span>
            {isHovered && (
                <div className="absolute bottom-full w-64 p-2 bg-gray-700 text-white text-xs rounded-md">
                    {text}{" "}
                    {learnMoreUrl && (
                        <a href={learnMoreUrl} className="text-blue-300 underline" target="_blank" rel="noopener noreferrer">
                             Learn More
                        </a>
                    )}
                </div>
            )}
        </div>
    );
}

export default InfoBubble;