import React, { useState, useEffect } from 'react';
import OpportunityAndFunderDrawer from '../OpportunityAndFunderDrawer/OpportunityAndFunderDrawer';
import { updateStatusOfScoreInSupabase } from '../../utilityFunctions/supabaseUtilities';

const PotentialGrantProgramsTab = ({
    currentProgram,
    refreshGrantMakingPrograms,
    setShowLoadingModal,        
    allGmpGspFitScoresForOrganization,
    setGrantmakingProgramIdForSaveOpportunityModalFromEntryPoint,
    setFunderEinForSaveOpportunityModalFromEntryPoint,
    openSaveOpportunityModalWithKnownFunderAndOrGrantmakingProgram,
    setFunderEinForDrawerView,
    funderEinForDrawerView,
    membershipDetails
}) => {

    const [selectedDrawerView, setSelectedDrawerView] = useState('opportunity');
    const [goodFitGmpsRowData, setGoodFitGmpsRowData] = useState([]);
    const [badFitGmpsRowData, setBadFitGmpsRowData] = useState([]);
    const [archivedGmpsRowData, setArchivedGmpsRowData] = useState([]);
    const [savedGmpsRowData, setSavedGmpsRowData] = useState([]);
    const [rowData, setRowData] = useState([goodFitGmpsRowData]);
    const [tableViewMode, setTableViewMode] = useState("goodFits");
    const [grantmakingProgramIdForDrawerView, setGrantmakingProgramIdForDrawerView] = useState(null);

    useEffect(() => {

        // Filter out scores that are not for current program
        const filteredScores = allGmpGspFitScoresForOrganization.filter(score => score.grantseeking_program_id === currentProgram.id);
        
        // Group into goodFitGmps, badFitGmps, archivedGmps, savedGmps
        const goodFitGmps = []
        const badFitGmps = []
        const archivedGmps = []
        const savedGmps = []

        for (const score of filteredScores) {
            if (score.status === "saved") {
                savedGmps.push(score);
            } else if (score.status === "archived") {
                archivedGmps.push(score);
            } else if (score.fit_category === "Good Fit") {
                goodFitGmps.push(score);
            } else if (score.fit_category === "Bad Fit") {
                badFitGmps.push(score);
            }
        }
        
        setGoodFitGmpsRowData(goodFitGmps);
        setBadFitGmpsRowData(badFitGmps);
        setArchivedGmpsRowData(archivedGmps);
        setSavedGmpsRowData(savedGmps);
        
    }, [allGmpGspFitScoresForOrganization, currentProgram]);


    // when view 

    useEffect(() => {
        if (tableViewMode === "goodFits") {
            setRowData(goodFitGmpsRowData);
            console.log("goodFitGmpsRowData", goodFitGmpsRowData);
        } else if (tableViewMode === "badFits") {
            setRowData(badFitGmpsRowData);
            console.log("badFitGmpsRowData", badFitGmpsRowData);
        } else if (tableViewMode === "archived") {
            setRowData(archivedGmpsRowData);
        } else if (tableViewMode === "saved") {
            setRowData(savedGmpsRowData);
        }
    }, [tableViewMode, goodFitGmpsRowData, badFitGmpsRowData, archivedGmpsRowData, savedGmpsRowData]);

    const updateStatusOfScore = async (scoreId, newStatus) => {
        await updateStatusOfScoreInSupabase(scoreId, newStatus);
        await refreshGrantMakingPrograms();
    }

    const handleRowClick = (score) => {        
        console.log("Score selected: ", score);
        setGrantmakingProgramIdForDrawerView(score.grantmaking_program_id);        
        setGrantmakingProgramIdForSaveOpportunityModalFromEntryPoint(score.grantmaking_program_id);
        setFunderEinForSaveOpportunityModalFromEntryPoint(score.ein);
        console.log("Changing funderEinForDrawerView to: ", score.funder_ein);
        setFunderEinForDrawerView(score.funder_ein);
        setSelectedDrawerView('opportunity');
    }

    return (
        <div className="flex w-full h-full" >
            <div className='w-1/3 h-full text-left'>                                       
                {/* Header Buttons */}
                <div className='flex flex-row px-4 py-3 justify-between border-b border-gray-300 h-16 text-xs'>                    
                    <button className={` px-2 py-1 rounded-md ${tableViewMode === "goodFits" ? 'bg-indigo-500 text-white font-bold' : 'bg-indigo-100'}`} onClick={() => setTableViewMode("goodFits")}>Good Fits ({goodFitGmpsRowData.length})</button>
                    <button className={` px-2 py-1 rounded-md ${tableViewMode === "badFits" ? 'bg-red-300 font-bold' : 'bg-red-100'}`} onClick={() => setTableViewMode("badFits")}>Bad Fits ({badFitGmpsRowData.length})</button>
                    <button className={` px-2 py-1 rounded-md ${tableViewMode === "archived" ? 'bg-gray-300 font-bold' : 'bg-gray-100'}`} onClick={() => setTableViewMode("archived")}>Archived ({archivedGmpsRowData.length})</button>
                    <button className={` px-2 py-1 rounded-md ${tableViewMode === "saved" ? 'bg-pink-300 font-bold' : 'bg-green-100'}`} onClick={() => setTableViewMode("saved")}>Saved ({savedGmpsRowData.length})</button>
                </div>

                {/* Table */}
                <div className='w-full h-full text-left overflow-auto max-h-[calc(100vh-8rem)]'>                    
                    {rowData.map((score, index) => (
                        <div 
                            key={index} 
                        className='bg-orange-100 p-4 rounded-md my-3 mx-2 cursor-pointer'
                        onClick={() => handleRowClick(score)}
                    >
                        <p className=''>{score.grantmaking_program_name}</p>                        
                        <p className='text-sm italic'>
                            {score.fit_category} | Discovered {new Date(score.created_at).toLocaleDateString()}
                        </p>                        
                        <div className='flex flex-row mt-2 gap-2' onClick={(e) => e.stopPropagation()}>
                            {tableViewMode !== 'saved' && (
                                <button className='text-sm text-blue-500 bg-gray-100 px-2 py-1 rounded-md' onClick={() => updateStatusOfScore(score.id, "saved")}>Save</button>
                            )}
                            {tableViewMode !== 'archived' && (
                                <button className='text-sm text-blue-500 bg-gray-100 px-2 py-1 rounded-md' onClick={() => updateStatusOfScore(score.id, "archived")}>Archive</button>
                            )}
                            {tableViewMode === 'archived' && (
                                <button className='text-sm text-blue-500 bg-gray-100 px-2 py-1 rounded-md' onClick={() => updateStatusOfScore(score.id, "active")}>Unarchive</button>
                            )}
                            {tableViewMode === 'saved' && (
                                <button className='text-sm text-blue-500 bg-gray-100 px-2 py-1 rounded-md' onClick={() => updateStatusOfScore(score.id, "active")}>Unsave</button>
                            )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            
            <div className='w-2/3 h-full'>  
                <OpportunityAndFunderDrawer 
                    selectedDrawerView={selectedDrawerView}
                    setSelectedDrawerView={setSelectedDrawerView}
                    setShowLoadingModal={setShowLoadingModal}
                    grantmakingProgramIdForDrawerView={grantmakingProgramIdForDrawerView}
                    openSaveOpportunityModalWithKnownFunderAndOrGrantmakingProgram={openSaveOpportunityModalWithKnownFunderAndOrGrantmakingProgram}
                    funderEinForDrawerView={funderEinForDrawerView}
                    currentProgram={currentProgram}
                />
            </div>
        </div>
    );
}

export default PotentialGrantProgramsTab;