import React, { useState } from 'react';
import daisySprite from '../../assets/daisySprite.png';
function HomeDashboard({
    setCurrentView,
    setShowHelpModal,
    setShowGrantDiscoveryOptionsModal
}) {    

    return (
        <div className="p-12 flex flex-col gap-4 text-left">
            <p className="text-3xl font-bold">Welcome to GrantMagic</p>
            {/* Daisy Header */}
            <div className="w-full rounded-lg bg-gray-200 p-4 ">
                <div className="flex items-center">                     
                    <div className="flex items-center">
                        <img src={daisySprite} alt="Daisy Sprite" className="w-12 h-12 mr-4 rounded-full" />
                    </div>                    
                    <p className="text-lg">I'm Daisy, your AI grant writer. How can I help you today?</p>                    
                </div>
            </div>           
            
            {/* Two side by side sections */}
            <div className="flex gap-24 mt-4">                
                {/* Action shortcuts */}
                <div className="flex-1">
                    <p className="text-xl font-bold">Shortcuts</p>
                    <div className="grid grid-cols-2 gap-4 mt-4">
                        <div 
                            onClick={() => setShowGrantDiscoveryOptionsModal(true)} 
                            className="cursor-pointer bg-indigo-100 hover:bg-indigo-300 text-indigo-900 px-6 py-8 rounded-lg flex flex-col items-center "
                        >   
                            <p className="text-4xl mr-4 py-2">🔎</p>
                            <p className="text-xl mt-4">Discover new grant opportunities</p>        
                        </div>
                        <div onClick={() => setCurrentView('documentLibrary')} className="cursor-pointer bg-indigo-100 hover:bg-indigo-300 text-indigo-900 px-6 py-8 rounded-lg flex flex-col items-center ">   
                            <p className="text-4xl mr-4 py-2">✏️</p> 
                            <p className="text-xl mt-4">Generate magic first drafts instantly</p>                             
                        </div>
                        <div onClick={() => setCurrentView('daisySettings')} className="cursor-pointer bg-indigo-100 hover:bg-indigo-300 text-indigo-900 px-6 py-8 rounded-lg flex flex-col items-center ">   
                            <p className="text-4xl mr-4 py-2">🧠</p>
                            <p className="text-xl mt-4">Teach Daisy more about my nonprofit</p>  
                        </div>
                        <div onClick={() => setShowHelpModal(true)} className="cursor-pointer bg-indigo-100 hover:bg-indigo-300 text-indigo-900 px-6 py-8 rounded-lg flex flex-col items-center ">   
                            <p className="text-4xl mr-4 py-2">❓</p>
                            <p className="text-xl mt-4">Help me learn how to use GrantMagic</p>
                        </div>
                    </div>
                </div>   
            </div>       
        </div>
    );
}

export default HomeDashboard;