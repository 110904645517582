import React, { useState, useEffect, useContext } from 'react';
import { searchForOrganizationsInSupabase, searchForGrantMakingProgramsInSupabaseByEin } from '../../utilityFunctions/supabaseUtilities.js';
import { saveOpportunityToFirestore } from '../../utilityFunctions/firestoreUtilities.js';
import { AuthContext } from '../../AuthContext.js';
const SaveOpportunityModal = ({    
    setShowSaveOpportunityModal,
    organizationPrograms,
    // currentProgram,
    currentClientOrganization,
    setShowLoadingModal,
    grantmakingProgramIdForSaveOpportunityModalFromEntryPoint,
    funderEinForSaveOpportunityModalFromEntryPoint,
    funderNameForSaveOpportunityModalFromEntryPoint,
    refreshSavedOpportunities
    
}) => {

    const { authUser } = useContext(AuthContext);
    // Define state variables
    const [opportunityName, setOpportunityName] = useState('');
    const [proposalAmount, setProposalAmount] = useState('');
    // const [funder, setFunder] = useState('');
    const [grantMakingProgram, setGrantMakingProgram] = useState('');
    const [grantSeekingProgramId, setGrantSeekingProgramId] = useState('');
    const [status, setStatus] = useState('Saved');
    const [proposalNotes, setProposalNotes] = useState('');
    const [deadline, setdeadline] = useState('');
    const [website, setWebsite] = useState('');
    
    const [showMatchingFundersDropdown, setShowMatchingFundersDropdown] = useState(false);

    // Funder selection state variables
    const [userDefinedManualFunderName, setUserDefinedManualFunderName] = useState(null);
    const [userSelectedFunderEin, setUserSelectedFunderEin] = useState(null);
    const [funderSelected, setFunderSelected] = useState(false);    
    const [funderSelectedName, setFunderSelectedName] = useState(null);

    // Grant making program selection state variables
    const [matchingGrantPrograms, setMatchingGrantPrograms] = useState([]);
    const [showMatchingGrantProgramsDropdown, setShowMatchingGrantProgramsDropdown] = useState(false);
    const [grantMakingProgramSelected, setGrantMakingProgramSelected] = useState(false);
    const [grantMakingProgramSelectedName, setGrantMakingProgramSelectedName] = useState(null);
    const [userDefinedManualGrantMakingProgramName, setUserDefinedManualGrantMakingProgramName] = useState(null);
    const [userSelectedGrantMakingProgram, setUserSelectedGrantMakingProgram] = useState(null);

    const [funderNameString, setFunderNameString] = useState("");
    const [debounceTimeout, setDebounceTimeout] = useState(null);    
    const [matchingOrganizations, setMatchingOrganizations] = useState([]); // Change to an array of objects
    

    // If a funder or grant making program is already selected from the entry point, then set the state variables to the known values
    useEffect(() => {
        if (funderEinForSaveOpportunityModalFromEntryPoint) {
            console.log("Save opportunity modal loaded with funder ein from entry point: ", funderEinForSaveOpportunityModalFromEntryPoint);
            setUserSelectedFunderEin(funderEinForSaveOpportunityModalFromEntryPoint);
            setFunderSelected(true);
        }
        if (funderNameForSaveOpportunityModalFromEntryPoint) {
            setFunderSelectedName(funderNameForSaveOpportunityModalFromEntryPoint);
        }
    }, [funderEinForSaveOpportunityModalFromEntryPoint, grantmakingProgramIdForSaveOpportunityModalFromEntryPoint]);

    // Once a user selects a known funder or defines a new funder, set the funderSelected state to true and define its name
    useEffect(() => {
        if (userSelectedFunderEin !== null && funderEinForSaveOpportunityModalFromEntryPoint !== null) {
            setFunderSelected(true);
        }
        
        else if (userDefinedManualFunderName !== null || userSelectedFunderEin !== null)  {
            setFunderSelected(true);
            setFunderSelectedName(userDefinedManualFunderName || matchingOrganizations.find(org => org.ein === userSelectedFunderEin).business_name);
        } else {
            setFunderSelected(false);
        }
    }, [userDefinedManualFunderName, userSelectedFunderEin]);   
    
    // Once a funder is selected, hide the matching funders dropdown
    useEffect(() => {
        console.log("Funder selected:", funderSelected);
        setShowMatchingFundersDropdown(false);
    }, [funderSelected]);

    // If funderSelected is true, and grantMakingProgramSelected is not true, then show the matching grant programs dropdown
    useEffect(() => {
        if (funderSelected && grantMakingProgramSelected === false) {
            setShowMatchingGrantProgramsDropdown(true);
        } else {
            setShowMatchingGrantProgramsDropdown(false);
        }
    }, [funderSelected, grantMakingProgramSelected]);

    // Once a user selects a known grantmaking_program or defines a manual program, set the grantMakingProgramSelected state to true and define its name
    useEffect(() => {
        if (userDefinedManualGrantMakingProgramName !== null || userSelectedGrantMakingProgram !== null) {
            setGrantMakingProgramSelected(true);
            setGrantMakingProgramSelectedName(userDefinedManualGrantMakingProgramName || userSelectedGrantMakingProgram.name);
        } else {
            setGrantMakingProgramSelected(false);
        }
    }, [userDefinedManualGrantMakingProgramName, userSelectedGrantMakingProgram]);


    const handleGrantProgramSearch = async () => {
        const grantPrograms = await searchForGrantMakingProgramsInSupabaseByEin(userSelectedFunderEin);
        setMatchingGrantPrograms(grantPrograms);
    }

    // If user chooses an EIN, go hunt for any grant programs that match the EIN
    useEffect(() => {
        if (userSelectedFunderEin !== null)  {
            handleGrantProgramSearch();
        }
    }, [userSelectedFunderEin]);

    // Define a debounced function
    const debounce = (func, delay) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };



    useEffect(() => {
        console.log("Matching organizations:", matchingOrganizations);
        if (matchingOrganizations.length > 0 || funderNameString.length > 0) {
            setShowMatchingFundersDropdown(true);
        } else {
            setShowMatchingFundersDropdown(false);
        }
    }, [matchingOrganizations]);
    
    // Handle when content changes in the editor
    const handleFunderNameChange = (newNameString) => {
        
        setFunderNameString(newNameString);
        setMatchingOrganizations([]);
        
        // Clear the previous timeout if it exists
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        // Don't search if string is less than 2 characters
        if (newNameString.length < 2) {
            return;
        }

        // Set a new timeout to update Firestore after 2 seconds
        const timeout = setTimeout(async () => {
            setShowLoadingModal(true);
            const fetchedOrganizations = await searchForOrganizationsInSupabase(newNameString);
            const newMatchingOrganizations = [...matchingOrganizations]; // Create a copy of the existing array

            for (const organization of fetchedOrganizations) {
                // Check if EIN is already in the array
                if (!newMatchingOrganizations.some(org => org.ein === organization.ein)) {
                    newMatchingOrganizations.push(organization); // Add the organization object to the array
                    // console.log(organization.ein, organization.business_name, organization.city_name, organization.state_abbreviation_cd);
                }
            }
            setMatchingOrganizations(newMatchingOrganizations); // Update the state with the new array
            console.log("Matching organizations:", newMatchingOrganizations);
            setShowLoadingModal(false);
        }, 1000); 

        setDebounceTimeout(timeout);
    };
    
    const handleSaveOpportunity = async (
        funderSelectedName,
        userSelectedFunderEin,
        userDefinedManualFunderName,
        userSelectedGrantMakingProgramId,
        userDefinedManualGrantMakingProgramName,
        grantSeekingProgramId,
        opportunityName,
        amount,
        status,
        notes,
        deadline,
        website,
        currentClientOrganizationId,
        userId
    ) => {

        
        // Fail if no funder is selected
        if (!userDefinedManualFunderName && !userSelectedFunderEin) {
            console.log("Save opportunity failed. No funder selected");
            return;
        }

        // Fail if no grant making program is selected
        if (!userSelectedGrantMakingProgramId && !userDefinedManualGrantMakingProgramName) {
            console.log("Save opportunity failed. No grant making program selected");
            return;
        }

        // Fail if no grant seeking program is selected
        if (!grantSeekingProgramId) {
            console.log("Save opportunity failed. No grant seeking program selected");
            return;
        }

        // Fail if no opportunity name is provided
        if (!opportunityName) {
            console.log("Save opportunity failed. No opportunity name provided");
            return;
        }
        
        const opportunityData = {
            ...(funderSelectedName && { funderSelectedName }),
            ...(userSelectedFunderEin && { userSelectedFunderEin }),
            ...(userDefinedManualFunderName && { userDefinedManualFunderName }),
            ...(userSelectedGrantMakingProgramId && { userSelectedGrantMakingProgramId }),
            ...(userDefinedManualGrantMakingProgramName && { userDefinedManualGrantMakingProgramName }),
            ...(grantSeekingProgramId && { grantSeekingProgramId }),
            ...(opportunityName && { opportunityName }),
            ...(amount && { amount }),
            ...(status && { status }),
            ...(notes && { notes }),
            ...(deadline && { deadline }),
            ...(website && { website }),
            ...(currentClientOrganizationId && { currentClientOrganizationId }),
            ...(userId && { userId }),
        };

        // Log each key/value pair
        console.log("Preparing to save opportunity to Firestore:", opportunityData);

        try {
            await saveOpportunityToFirestore(opportunityData);
            setShowSaveOpportunityModal(false);
            alert ("Opportunity saved successfully. Visit Tracker tab to view.");
            await refreshSavedOpportunities();
        } catch (error) {
            alert("Error saving opportunity. Please try again.");
        }
    }

    // Define the handleAddButtonClicked function
    const handleSaveButtonClicked = () => {

        console.log("handleSaveButtonClicked");
        
        // Handling required funder selection
        if (userSelectedFunderEin) {
            console.log("Known funder selected. EIN:", userSelectedFunderEin);
        } else if (userDefinedManualFunderName) {
            console.log("Manual funder entered. Name:", userDefinedManualFunderName);
        } else {
            alert("No funder selected");
            return;
        }

        // Handling required grant making program selection
        if (userSelectedGrantMakingProgram) {
            console.log("Known grant making program selected. Name:", userSelectedGrantMakingProgram.name);
        } else if (userDefinedManualGrantMakingProgramName) {
            console.log("Manual grant making program entered. Name:", userDefinedManualGrantMakingProgramName);
        } else {
            alert("No grant making program selected");
            return;
        }

        // Handle required Grant Seeking Program
        if (grantSeekingProgramId) {
            console.log("Grant seeking program selected. ID:", grantSeekingProgramId);
        } else {
            alert("No grant seeking program selected");
            return;
        }

        // Handle required Opportunity Name
        if (opportunityName) {
            console.log("Saved Opportunity Name:", opportunityName);
        } else {
            alert("No opportunity name entered");
            return;
        }        

        // Save the opportunity to Firestore
        handleSaveOpportunity(
            funderSelectedName || null,
            userSelectedFunderEin || null,
            userDefinedManualFunderName || null,
            userSelectedGrantMakingProgram ? userSelectedGrantMakingProgram.id : null,
            userDefinedManualGrantMakingProgramName || null,
            grantSeekingProgramId ? grantSeekingProgramId : null,
            opportunityName || null,
            proposalAmount || null,
            status || null,
            proposalNotes || null,
            deadline || null,
            website || null,
            currentClientOrganization.id,
            authUser.uid
        );        

    };

    return (
        <div className="bg-gray-900 bg-opacity-50" 
             style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: '9999', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
             onClick={() => setShowSaveOpportunityModal(false)}
        >
            
            {/* Modal */}
            <div className='text-left bg-white rounded-lg p-16' 
                 style={{ width: '70vw', height: '70vh', overflowY: 'auto' }}
                 onClick={(e) => e.stopPropagation()}>
                
                {/* Header */}                                
                <div className="flex w-full text-center text-gray-500 justify-between">
                    <p className='text-3xl font-bold'>Save Opportunity</p>                                        
                    <button 
                        onClick={() => setShowSaveOpportunityModal(false)} 
                        className="text-gray-500 py-2 px-4"
                    >
                        Cancel
                    </button>
                </div>
                
                {/* Body */}
                <div className="">

                    {/* Selected Funder Field */}
                    {funderSelected && (
                        <div className='mt-6'>
                            <p className='font-bold text-gray-500 mb-1'>FUNDER</p>
                            <div className='flex items-center justify-between'>
                                <p className='text-gray-500 bg-gray-100 p-2 rounded-md'>{funderSelectedName}</p>
                                <button 
                                    className='bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded'
                                    onClick={() => {
                                        setFunderSelected(false);
                                        setUserDefinedManualFunderName(null);
                                        setUserSelectedFunderEin(null);
                                    }}
                                >X</button>
                            </div>
                        </div>
                    )}

                    {/* Funder Search Field */}
                    {!funderSelected && (
                        <div className='mt-6'>
                            <p className='font-bold text-gray-500 mb-1'>FUNDER<span className="text-red-500"> *</span></p>
                            <p className='text-gray-500 text-xs mb-2'>Add the name of the funder for this grant opportunity</p>
                            <input id="funder" type="text" required
                            className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full" 
                            value={funderNameString} onChange={(e) => handleFunderNameChange(e.target.value)}                         
                        />                            
                        {showMatchingFundersDropdown && (
                            <div className='border border-gray-300 rounded-md '>
                                <div className='max-h-60 overflow-y-auto'>
                                    <ul>
                                        {matchingOrganizations.map(org => (
                                            <li 
                                                key={org.ein} 
                                                onClick={() => {
                                                    setUserSelectedFunderEin(org.ein);
                                                }}
                                                className='text-xs cursor-pointer rounded-md p-1 mb-1 hover:bg-gray-100 p-1'
                                            >
                                                <p>{org.business_name}</p>                                            
                                                <p className='text-gray-500 text-xs'>{org.city_name}, {org.state_abbreviation_cd} | {org.ein}</p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <button 
                                    className='w-full bg-indigo-200 text-left text-indigo-700 p-3'
                                    onClick={() => {
                                        console.log('Save as new funder:', funderNameString);
                                        // setFunderSelected(true);
                                        setUserDefinedManualFunderName(funderNameString);
                                        // setUserSelectedFunderEin(null);

                                    }}
                                >
                                    Save "{funderNameString}" as new funder
                                </button>
                            </div>
                        )}
                        </div>  
                    )}


                    {/* If funder selected, then show grant making program search field */}
                    {funderSelected && (
                        <>
                        
                            {/* Grant Making Program */}
                            <div className='mt-6'>
                                <p className='font-bold text-gray-500 mb-1'>GRANT MAKING PROGRAM</p>
                                <p className='text-gray-500 text-xs mb-2'>Select a funding opportunity to see deadlines and info. Can't find it? Add any name, e.g. Gates Foundation Grant.</p>

                                {grantMakingProgramSelected ? (
                                    <div className='mt-6'>
                                        <div className='flex items-center justify-between'>
                                            <p className='text-gray-500 bg-gray-100 p-2 rounded-md'>{grantMakingProgramSelectedName}</p>
                                            <button 
                                                className='bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded'
                                                onClick={() => {
                                                    setGrantMakingProgramSelected(false);
                                                    setUserDefinedManualGrantMakingProgramName(null);
                                                    setUserSelectedGrantMakingProgram(null);
                                                }}
                                                >X</button>
                                        </div>
                                    </div>
                                ) : (
                                    
                                    // If no grant making program selected, then show the search field
                                    <div>
                                        <input id="grant-making-program" type="text" 
                                            className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full" 
                                            value={grantMakingProgram} onChange={(e) => setGrantMakingProgram(e.target.value)} 
                                        />

                                        {/* If there are matching grant programs, then show the dropdown */}
                                        {showMatchingGrantProgramsDropdown && (
                                            <div className='border border-gray-300 rounded-md'>
                                                <div className='max-h-60 overflow-y-auto'>
                                                    <ul>
                                                        {matchingGrantPrograms.map(program => (
                                                            <li 
                                                                key={program.id}
                                                                onClick={() => {
                                                                    // setGrantMakingProgram(program.name);
                                                                    setUserSelectedGrantMakingProgram(program);
                                                                }}
                                                                className='text-xs cursor-pointer rounded-md p-1 mb-1 hover:bg-gray-100 p-2'
                                                            >
                                                                <p>{program.name}</p>                                            
                                                                {/* <p className='text-gray-500 text-xs'>{program.ein}</p> */}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                {/* If there is user text, then show the manual creation button */}
                                                {grantMakingProgram.length > 0 && (
                                                    <button 
                                                        className='w-full bg-indigo-200 text-left text-indigo-700 p-3'
                                                        onClick={() => {
                                                            setUserDefinedManualGrantMakingProgramName(grantMakingProgram);
                                                        }}
                                                    >
                                                        Save "{grantMakingProgram}" as new grant program
                                                    </button>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>                                                
                        </>
                    )}

                    {/* If grant making program selected, then show rest of search fields */}
                    {grantMakingProgramSelected && funderSelected && (
                        <div>
                            <div className='mt-6'>
                                <p className='font-bold text-gray-500 mb-1'>GRANT SEEKING PROGRAM<span className="text-red-500"> *</span></p>
                                <select id="grant-seeking-program" required
                                    className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full" 
                                    value={grantSeekingProgramId} onChange={(e) => setGrantSeekingProgramId(e.target.value)}>
                                    <option value="">Select a program</option>
                                    {organizationPrograms.map((program) => (
                                        <option key={program.id} value={program.id}>
                                            {program.programName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            
                            <div className='mt-6'>
                                <p className='font-bold text-gray-500 mb-1'>OPPORTUNITY NAME<span className="text-red-500"> *</span></p>
                                <input id="proposal-name" type="text" required
                                    className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full"                                     
                                    value={opportunityName} onChange={(e) => setOpportunityName(e.target.value)} placeholder={`${grantMakingProgramSelectedName} (2025)`} />
                            </div>                    
                            
                            <div className='mt-6'>
                                <p className='font-bold text-gray-500 mb-1'>AMOUNT</p>
                                <input id="proposal-amount" type="number" 
                                    className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full" 
                                    value={proposalAmount} onChange={(e) => setProposalAmount(e.target.value)} />
                            </div>                    
                            


                            <div className='mt-6'>
                                <p className='font-bold text-gray-500 mb-1'>STATUS</p>
                                <select id="status" 
                                    className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full" 
                                    value={status || "Saved"} onChange={(e) => setStatus(e.target.value)}>
                                    <option value="Saved">Saved</option>
                                    <option value="In Progress">In Progress</option>
                                    <option value="Submitted">Submitted</option>
                                    <option value="Won">Won</option>
                                    <option value="Lost">Lost</option>
                                </select>
                            </div>

                            <div className='mt-6'>
                                <p className='font-bold text-gray-500 mb-1'>NOTES</p>
                                <input id="proposal-notes" type="text" 
                                    className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full" 
                                    value={proposalNotes} onChange={(e) => setProposalNotes(e.target.value)} />
                            </div>

                            <div className='mt-6'>
                                <p className='font-bold text-gray-500 mb-1'>DEADLINE</p>
                                <input type="date" value={deadline} onChange={(e) => setdeadline(e.target.value)} 
                                    className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full" />
                            </div>

                            <div className='mt-6'>
                                <p className='font-bold text-gray-500 mb-1'>WEBSITE</p>
                                <input id="website" type="url" 
                                    className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full" 
                                    value={website} onChange={(e) => setWebsite(e.target.value)} />
                            </div>

                            <hr className='my-6' />

                            <div className="mt-4 space-x-4">
                                <button onClick={handleSaveButtonClicked} className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded">Save</button>
                                <button onClick={() => setShowSaveOpportunityModal(false)} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Cancel</button>                    
                            </div>     
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SaveOpportunityModal;
