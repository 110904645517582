import React, { useState, useEffect } from 'react';
// import daisySprite from '../../assets/daisySprite.png';
// Import AgGrid
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; 
import 'ag-grid-community/styles/ag-theme-alpine.css'; 

// Import UnknownOpportunityBubble
import UnknownOpportunityBubble from '../HelperComponents/UnknownOpportunityBubble';

// Import KnownOpportunityBubble
import KnownOpportunityBubble from '../HelperComponents/KnownOpportunityBubble';

const ProgramTasksTab = ({
    setShowCreateTaskModal,
    tasks,
    refreshTasks,
    savedOpportunities, 
    currentProgram,
    setSelectedTask,
    setSelectedSavedOpportunity,
    // refreshOrganizationDocuments
    
}) => {

    const [rowData, setRowData] = useState([]);

    useEffect(() => {

        const filteredTasks = [];
        const currentProgramId = currentProgram.id;
        tasks.forEach(task => {
            if (task.linkedProgram === currentProgramId) {
                filteredTasks.push(task);
            }
        });

        setRowData(filteredTasks);
    }, [currentProgram, tasks]);

    // When row clicked, set selected opportunity and show opportunity / funder modal
    const handleRowClick = (data) => {
        console.log("Setting selected task: ", data);
        setSelectedTask(data);
    };

    return (
        <div className='p-12'>
        
            {/* Daisy Header */}
            
            <div className='flex text-xl items-center my-4 justify-between'>
                <p className="text-left text-gray-400 font-bold">TASKS FOR {currentProgram.programName.toUpperCase()}</p>            
                <button className='bg-indigo-100 hover:bg-indigo-300 text-indigo-900 text-sm px-4 py-2 rounded-lg' onClick={() => setShowCreateTaskModal(true)}>+ Add New</button>
            </div>
            
            <div className="flex-1 border-2 border-gray-100 rounded-md text-center h-full">                            
                <table className="min-w-full text-left divide-y divide-gray-200 text-sm">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Task Name</th>
                            <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                            <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Deadline</th>
                            <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Linked Opportunity</th>
                            <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {rowData.map((task) => (
                            <tr key={task.id} 
                                className="bg-white border-b cursor-pointer hover:bg-gray-100"
                                onClick={() => handleRowClick(task)}
                            >
                                <td className="px-4 py-4">{task.name}</td>
                                <td className='px-4 py-4'>{task.status}</td>
                                <td className='px-4 py-4'>{task.deadline ? new Date(task.deadline).toLocaleDateString('en-US', { timeZone: 'UTC' }) : 'N/A'}</td>
                                <td className='px-4 py-4'>
                                    {task.linkedOpportunity ? (
                                        <span 
                                            onClick={(e) => e.stopPropagation()} 
                                        >
                                            <KnownOpportunityBubble 
                                                opportunityId={task.linkedOpportunity}
                                                savedOpportunities={savedOpportunities}
                                                setSelectedSavedOpportunity={setSelectedSavedOpportunity}
                                            />
                                        </span>
                                    ) : (
                                        <span 
                                            onClick={(e) => e.stopPropagation()} 
                                        >
                                            <UnknownOpportunityBubble 
                                                rowData={task}
                                                savedOpportunities={savedOpportunities}
                                                documentOrTask={"task"}
                                                refreshTasks={refreshTasks}
                                            />
                                        </span>
                                    )}
                                </td>                                
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ProgramTasksTab;