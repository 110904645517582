import React, { useState, useEffect } from 'react';
import OpportunityAndFunderDrawer from '../OpportunityAndFunderDrawer/OpportunityAndFunderDrawer';
import { fetchLatest990FromSupabase } from '../../utilityFunctions/supabaseUtilities';
import { fetchOpportunitiesFromFirestoreForEin } from '../../utilityFunctions/firestoreUtilities';
import OverviewTab from './OverviewTab';
import TasksTab from './TasksTab';
import DocumentsTab from './DocumentsTab';
const SelectedOpportunityModal = ({    
    setShowSelectedOpportunityModal,
    selectedSavedOpportunity,
    setSelectedSavedOpportunity,
    setSelectedTask,
    setShowManageTaskModal,
    tasks,
    organizationPrograms,
    setShowLoadingModal,
    showSelectedOpportunityModal,
    organizationDocuments,
    setShowCreateTaskModal,
    setShowGenerateAiDocumentModal,
    setCurrentGrantDocumentId,
    refreshSavedOpportunities,
    refreshTasks,
    refreshOrganizationDocuments,
    membershipDetails,
    setShowPaywallModal
}) => {
    const [activeDetailsTab, setActiveDetailsTab] = useState('Overview');
    const [latest990, setLatest990] = useState(null)
    const [funderName, setFunderName] = useState('')
    const [opportunitiesForFunder, setOpportunitiesForFunder] = useState([])
    const [selectedDrawerView, setSelectedDrawerView] = useState('opportunity');
    const [grantmakingProgramIdForDrawerView, setGrantmakingProgramIdForDrawerView] = useState(null);
    const [funderEinForDrawerView, setFunderEinForDrawerView] = useState(null);

    // Handle naming
    useEffect(() => {
        if (latest990) {
            setFunderName(latest990.business_name)
            console.log("Naming: ", latest990.business_name)
        } else {
            setFunderName(selectedSavedOpportunity.userDefinedManualFunderName)
        }
    }, [latest990])



    // When page loads...
    useEffect(() => {
        
        // if grantmaking program id is provided...
        if (selectedSavedOpportunity && selectedSavedOpportunity.userSelectedGrantMakingProgramId) {
            setGrantmakingProgramIdForDrawerView(selectedSavedOpportunity.userSelectedGrantMakingProgramId)
        }
        
        // If EIN is provided...
        if (selectedSavedOpportunity && selectedSavedOpportunity.userSelectedFunderEin) {            
            setFunderEinForDrawerView(selectedSavedOpportunity.userSelectedFunderEin)            
            
            // Fetch the latest 990
            const fetchFunderData = async (funderEin) => {
                const latest990 = await fetchLatest990FromSupabase(funderEin)
                setLatest990(latest990)        
                console.log(latest990)
            }
            fetchFunderData(selectedSavedOpportunity.userSelectedFunderEin)

            // Fetch opportunities for that funder
            const fetchOpportunitiesForFunder = async () => {
                const opportunitiesForFunder = await fetchOpportunitiesFromFirestoreForEin(selectedSavedOpportunity.userSelectedFunderEin);
                setOpportunitiesForFunder(opportunitiesForFunder);
            };
            fetchOpportunitiesForFunder();
        }
    }, [selectedSavedOpportunity])

    return (
        <div className="bg-gray-900 bg-opacity-50" 
             style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: '9999', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
             onClick={() => {
                 setShowSelectedOpportunityModal(false);
                 setSelectedSavedOpportunity(null);
             }}
        >
            
            {/* Modal */}
            <div className='text-left bg-white rounded-lg h-full overflow-hidden' 
                 style={{ width: '90vw', height: '90vh' }}
                 onClick={(e) => e.stopPropagation()}>
                
                {/* Header */}                                
                <div className="flex w-full text-center text-gray-500 bg-indigo-900">
                    <p className='text-3xl font-bold p-4 ml-4 text-white'>{selectedSavedOpportunity.name}</p>                                        
                </div>
                {/* Body */}
                <div className='flex w-full'>
                    
                    {/* Side Nav */}
                    {/* <div className='w-1/5 bg-indigo-100 p-6' style={{ height: 'calc(100vh - 100px)' }}>
                        <div className='flex w-full items-center justify-between'>
                            <p className='text-xl mb-4 font-bold'>Opportunities</p>
                            
                    
                        </div>
                        {opportunitiesForFunder.map((opportunity) => (
                            <div  
                                key={opportunity.id} 
                                onClick={() => setSelectedSavedOpportunity(opportunity)}
                                className={`${selectedSavedOpportunity.name === opportunity.name ? 'bg-blue-200 font-bold' : ''} my-4 rounded-lg`}
                            >
                                <div className='flex w-full justify-between py-4 px-2 '>
                                    <p>{opportunity.name}</p>
                    
                                </div>
                            </div>
                        ))}
                    </div> */}

                    <div className='w-1/2 '>
                        {/* Opporunity Name */}
                        {/* <div className='p-4'>
                            <p className='text-3xl font-bold text-left'>{selectedSavedOpportunity ? selectedSavedOpportunity.name : 'No Opportunity Selected'}</p>
                        </div> */}
                        {/* Tab Buttons */}
                        <div className='flex py-6'>
                            <button 
                                className={`text-gray-400 border-b-2 border-gray-200 px-4 py-2 w-full ${activeDetailsTab === 'Overview' ? 'font-bold text-indigo-700 border-indigo-700' : ''}`} 
                                onClick={() => setActiveDetailsTab('Overview')}
                            >
                                Overview
                            </button>
                            <button 
                                className={`text-gray-400 border-b-2 border-gray-200 px-4 py-2  w-full ${activeDetailsTab === 'Tasks' ? 'font-bold text-indigo-700 border-indigo-700' : ''}`} 
                                onClick={() => setActiveDetailsTab('Tasks')}
                            >
                                Tasks
                            </button>
                            <button 
                                className={`text-gray-400 border-b-2 border-gray-200 px-4 py-2  w-full ${activeDetailsTab === 'Documents' ? 'font-bold text-indigo-700 border-indigo-700' : ''}`} 
                                onClick={() => setActiveDetailsTab('Documents')}
                            >
                                Documents
                            </button>
                        </div>
                        {/* <div>Active tab: {activeDetailsTab}</div> */}
                        <div className='h-full py-2 px-8'>    
                            {activeDetailsTab === 'Overview' && 
                                <OverviewTab 
                                    selectedSavedOpportunity={selectedSavedOpportunity} 
                                    setSelectedSavedOpportunity={setSelectedSavedOpportunity}
                                    organizationPrograms={organizationPrograms}
                                    refreshSavedOpportunities={refreshSavedOpportunities}
                                    setShowSelectedOpportunityModal={setShowSelectedOpportunityModal}
                                    refreshTasks={refreshTasks}
                                    refreshOrganizationDocuments={refreshOrganizationDocuments}
                                />
                            }
                            {activeDetailsTab === 'Tasks' && 
                                <TasksTab 
                                    selectedSavedOpportunity={selectedSavedOpportunity} 
                                    setSelectedSavedOpportunity={setSelectedSavedOpportunity}
                                    setSelectedTask={setSelectedTask}
                                    setShowManageTaskModal={setShowManageTaskModal}
                                    setShowCreateTaskModal={setShowCreateTaskModal}
                                    tasks={tasks}
                                />
                            }
                            {activeDetailsTab === 'Documents' && 
                                <DocumentsTab 
                                    selectedSavedOpportunity={selectedSavedOpportunity} 
                                    setSelectedSavedOpportunity={setSelectedSavedOpportunity}
                                    organizationDocuments={organizationDocuments}
                                    setShowGenerateAiDocumentModal={setShowGenerateAiDocumentModal}
                                    setCurrentGrantDocumentId={setCurrentGrantDocumentId}
                                    setShowSelectedOpportunityModal={setShowSelectedOpportunityModal}
                                    membershipDetails={membershipDetails}
                                    setShowPaywallModal={setShowPaywallModal}
                                />
                            }
                        </div>                        
                    </div>
                    <div className='w-1/2 h-full border-l border-gray-300' style={{ height: 'calc(100vh - 100px)' }}>
                        <OpportunityAndFunderDrawer
                            selectedDrawerView={selectedDrawerView}
                            setSelectedDrawerView={setSelectedDrawerView}
                            setShowLoadingModal={setShowLoadingModal}
                            grantmakingProgramIdForDrawerView={grantmakingProgramIdForDrawerView}
                            funderEinForDrawerView={funderEinForDrawerView}
                            showSelectedOpportunityModal={showSelectedOpportunityModal}
                        ></OpportunityAndFunderDrawer>
                    </div>

                </div>
                
            </div>
        </div>
    );
};

export default SelectedOpportunityModal;
