import React from 'react';

const HelpModal = ({    
    setShowHelpModal
}) => {
    
    return (
        <div className="bg-gray-900 bg-opacity-50" 
             style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: '9999', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
             onClick={() => setShowHelpModal(false)}
        >
            
            {/* Modal */}
            <div className='text-left bg-white rounded-lg p-16' 
                 style={{ width: '60vw', height: '55vh', overflowY: 'auto' }}
                 onClick={(e) => e.stopPropagation()}>
                
                {/* Header */}                                
                <div className="flex w-full text-center text-gray-500 p-4">
                    <p className='text-3xl font-bold'>Help</p>                                        
                </div>
                
                {/* Body */}
                <div className="">
                    <div className="cursor-pointer hover:bg-gray-200 p-4 rounded-lg" onClick={() => { window.open('https://ossified-pluto-bdd.notion.site/GrantMagic-Documentation-17546353e32d8076a042c52f7b792c8d', '_blank'); setShowHelpModal(false); }}>
                        <h2 className="text-xl font-bold">📚 Read Documentation ➡️</h2>
                        <p>Learn how to use Daisy to find and apply for grants</p>
                    </div>
                    <div className="cursor-pointer hover:bg-gray-200 p-4 rounded-lg" onClick={() => { window.open('https://tally.so/r/mDVa5j', '_blank'); setShowHelpModal(false); }}>
                        <h2 className="text-xl font-bold">💬 Contact Support ➡️</h2>
                        <p>If you need help, please contact support</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HelpModal;
