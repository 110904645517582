import { useContext, useState, useEffect } from 'react';
// import { createProgramInFirestore, updateProgramInFirestore, deleteProgramFromFirestore } from '../../utilityFunctions/firestoreUtilities';
import { AuthContext } from '../../AuthContext';
import {  updateGrantDocumentDetailsInFirestore, deleteGrantDocumentFromFirestore } from '../../utilityFunctions/firestoreUtilities';
// import { cleanText } from '../../utilityFunctions/uxUtilities';

const ManageDocumentModal = ({ 
    setShowManageDocumentModal,
    organizationPrograms,
    currentClientOrganization,
    refreshDocuments,
    savedOpportunities,
    grantDocumentToManage,
    setGrantDocumentToManage
    }) => {

    const { authUser } = useContext(AuthContext);
    

    const handleUpdateDocument = async (e) => {
        e.preventDefault();

        // const formattedDeadline = new Date(deadline).toISOString().split('T')[0];

        const document = {
            documentName: documentName,
            // deadline: formattedDeadline,
            linkedProgramId: linkedProgramId,
            linkedOpportunityId: linkedOpportunityId,            
            currentClientOrganizationId: currentClientOrganization.id,
            userId: authUser.uid,
            // status: status,
            // createdAt: new Date(),
            updatedAt: new Date()
        };
        await updateGrantDocumentDetailsInFirestore(grantDocumentToManage.id, document);
        refreshDocuments()
        console.log("Document: ", document);
        setShowManageDocumentModal(false);        
    };

    const handleDeleteDocument = async (e) => {
        e.preventDefault();
        const confirmDelete = window.confirm("Are you sure you want to delete this document?");
        if (confirmDelete) {
            await deleteGrantDocumentFromFirestore(grantDocumentToManage.id);
            refreshDocuments();
            setShowManageDocumentModal(false);
        }
    };


    // New state variables for the form fields
    const [documentName, setDocumentName] = useState(grantDocumentToManage?.documentName || null);    
    const [linkedOpportunityId, setLinkedOpportunityId] = useState(grantDocumentToManage?.linkedOpportunityId || null);
    const [linkedProgramId, setLinkedProgramId] = useState(grantDocumentToManage?.linkedProgramId || null);

    // Function to handle form submission
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     console.log({ name, deadline, linkedOpportunity, linkedProgram });
    // };

    const handleModalDismiss = () => {
        setGrantDocumentToManage(null);
        setShowManageDocumentModal(false);
        
    };

    return (
        <div 
            className="modal-overlay" 
            style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}
            onClick={handleModalDismiss}
        >
            <div 
                className="modal p-8 overflow-auto max-h-full" 
                style={{ 
                    cursor: 'default', 
                    width: '40vw', 
                    height: '70vh', 
                    backgroundColor: 'white', 
                    borderRadius: '10px'
                }}
                onClick={(e) => e.stopPropagation()} // Prevent click event from propagating to the overlay
            >
                <div className="text-2xl font-bold py-4">Manage Document</div>
                <form className="flex flex-col gap-2 text-left">
                    <label className="mb-1 font-semibold text-sm text-gray-500">Document Name</label>
                    <input 
                        type="text" 
                        placeholder="Create a first draft of the RFP" 
                        value={documentName} 
                        onChange={(e) => setDocumentName(e.target.value)} 
                        className="mb-2 p-2 border border-gray-300 rounded"
                    />
                    <label className="mb-1 font-semibold text-sm text-gray-500">Linked Program</label>
                    <select 
                        value={linkedProgramId || ""}
                        onChange={(e) => setLinkedProgramId(e.target.value)} 
                        className="mb-2 p-2 border border-gray-300 rounded"
                    >
                        <option value="" disabled>Select a Program</option>
                        {organizationPrograms.map((program) => (
                            <option key={program.id} value={program.id}>
                                {program.programName}
                            </option>
                        ))}
                    </select>
                    <label className="mb-1 font-semibold text-sm text-gray-500">Linked Opportunity</label>
                    <select 
                        value={linkedOpportunityId || ""} 
                        onChange={(e) => setLinkedOpportunityId(e.target.value)} 
                        className="mb-2 p-2 border border-gray-300 rounded"
                    >
                        <option value="" disabled>Select an Opportunity</option>
                        {savedOpportunities
                            .filter(opportunity => opportunity.grantSeekingProgramId === linkedProgramId)
                            .map((opportunity) => (
                                <option key={opportunity.id} value={opportunity.id}>
                                    {opportunity.name}
                                </option>
                            ))}
                    </select>                                        
                    <button 
                        type="submit" 
                        onClick={handleUpdateDocument} 
                        className="p-2 bg-indigo-700 hover:bg-indigo-900 text-white rounded"
                    >
                        Update
                    </button>
                    <button 
                        onClick={handleDeleteDocument} 
                        className="p-2 text-red-500"
                    >
                        Delete
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ManageDocumentModal;