import React from 'react';

function KnownProgramBubble({ 
        programId,
        organizationPrograms,
        setCurrentView,
        setCurrentProgram,
        clickingDisabled,
        rowData
    }) {

    // Stitch together the program name from the programId
    const programName = organizationPrograms.find(program => program.id === programId)?.programName;    

    const knownProgramClicked = () => {
        if (clickingDisabled) {
            return;
        }
        setCurrentProgram(organizationPrograms.find(program => program.id === programId));
        setCurrentView('selectedProgramView');
    };


    return (
        <>                
        <button 
            className={`px-2 py-1 rounded text-indigo-800 bg-indigo-100 hover:bg-indigo-300 text-left text-xs ${clickingDisabled ? 'cursor-default' : 'cursor-pointer'}`} 
            onClick={knownProgramClicked}
        >
            {programName ? programName : ''} →
        </button>                
        </>
    );
}

export default KnownProgramBubble;