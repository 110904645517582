// Import libraries
import React, { useEffect, useState } from 'react';
import OpportunitiesTab from './OpportunitiesTab';
import PotentialFundersTab from './PotentialFundersTab';
import PotentialGrantProgramsTab from './PotentialGrantProgramsTab';
import ProgramTasksTab from './ProgramTasksTab';

const SelectedProgramView = ({ 
        currentProgram,        
        setProgramToModify,
        setShowCreateProgramModal,  
        currentClientOrganization,
        setShowSaveOpportunityModal,
        setShowSelectedOpportunityModal,
        setSelectedSavedOpportunity,    
        setShowCreateTaskModal,
        tasks,
        refreshTasks,
        setSelectedTask,
        savedOpportunities,
        organizationDocuments,
        setShowLoadingModal,
        relevantFundersCountsByProgram,
        refreshGrantMakingPrograms,
        allGmpGspFitScoresForOrganization,
        allGoodFitScoresWithActiveStatusForOrganization,
        allFunderScoresWithActiveStatusForOrganization,
        allFunderScoresForOrganization,
        refreshFunderScoresForOrganization,
        setGrantmakingProgramIdForSaveOpportunityModalFromEntryPoint,
        setFunderEinForSaveOpportunityModalFromEntryPoint,  
        openSaveOpportunityModalWithKnownFunderAndOrGrantmakingProgram,
        activeChildViewOfSelectedProgramView, 
        setActiveChildViewOfSelectedProgramView,
        fetchProgramsForOrganization,
        membershipDetails,
        setShowPaywallModal
    }) => {    

    const [funderEinForDrawerView, setFunderEinForDrawerView] = useState(null);

    const handleManageProgramButtonClicked = () => {
        setProgramToModify(currentProgram)
        setShowCreateProgramModal(true)
    }

    useEffect(() => {
        setFunderEinForDrawerView(null);
    }, [activeChildViewOfSelectedProgramView])

    const handleTabButtonClicked = (tab) => {
        if (tab === 'fundersAwardsSearch' || tab === 'potentialFundingPrograms') {
            if (membershipDetails) {
                setActiveChildViewOfSelectedProgramView(tab);
            } else {
                setShowPaywallModal(true);
            }
        } else {
            setActiveChildViewOfSelectedProgramView(tab);
        }
    }



    const renderChildView = () => {  
        switch (activeChildViewOfSelectedProgramView) {          
            case 'opportunitiesTracker':
                return <OpportunitiesTab                 
                    currentClientOrganization={currentClientOrganization}                
                    setShowSelectedOpportunityModal={setShowSelectedOpportunityModal}
                    setShowSaveOpportunityModal={setShowSaveOpportunityModal}
                    currentProgram={currentProgram}
                    setSelectedSavedOpportunity={setSelectedSavedOpportunity}
                    tasks={tasks}
                    organizationDocuments={organizationDocuments}
                    savedOpportunities={savedOpportunities}
                    fetchProgramsForOrganization={fetchProgramsForOrganization}
            />;

            case 'programTasksTab':
                return <ProgramTasksTab 
                    setShowCreateTaskModal={setShowCreateTaskModal}
                    tasks={tasks}
                    refreshTasks={refreshTasks}
                    savedOpportunities={savedOpportunities} 
                    currentProgram={currentProgram}
                    setSelectedTask={setSelectedTask}
                    setSelectedSavedOpportunity={setSelectedSavedOpportunity}
                />;
            case 'fundersAwardsSearch':
                return <PotentialFundersTab 
                    currentProgram={currentProgram}    
                    setShowLoadingModal={setShowLoadingModal}
                    allFunderScoresForOrganization={allFunderScoresForOrganization}
                    refreshFunderScoresForOrganization={refreshFunderScoresForOrganization}
                    openSaveOpportunityModalWithKnownFunderAndOrGrantmakingProgram={openSaveOpportunityModalWithKnownFunderAndOrGrantmakingProgram}
                    funderEinForDrawerView={funderEinForDrawerView}
                    setFunderEinForDrawerView={setFunderEinForDrawerView}
                    setProgramToModify={setProgramToModify}
                    setShowCreateProgramModal={setShowCreateProgramModal}
                />;
            case 'potentialFundingPrograms':
                return <PotentialGrantProgramsTab 
                    currentProgram={currentProgram}    
                    refreshGrantMakingPrograms={refreshGrantMakingPrograms}
                    setShowLoadingModal={setShowLoadingModal}        
                    allGmpGspFitScoresForOrganization={allGmpGspFitScoresForOrganization}
                    setGrantmakingProgramIdForSaveOpportunityModalFromEntryPoint={setGrantmakingProgramIdForSaveOpportunityModalFromEntryPoint}
                    setFunderEinForSaveOpportunityModalFromEntryPoint={setFunderEinForSaveOpportunityModalFromEntryPoint}
                    openSaveOpportunityModalWithKnownFunderAndOrGrantmakingProgram={openSaveOpportunityModalWithKnownFunderAndOrGrantmakingProgram}
                    funderEinForDrawerView={funderEinForDrawerView}
                    setFunderEinForDrawerView={setFunderEinForDrawerView}
                />;
          default:
            return null;
        }
    };
    
    return (
        <div className='w-full h-full'>
            
            {/* Program header */}
            <div className='flex flex-col items-center w-full text-left'>
                
                {/* First Line */}
                <div className='flex items-center w-full p-8 space-x-4'>
                    <p className='text-2xl font-bold'>{currentProgram.programName}</p>
                    <button onClick={handleManageProgramButtonClicked} className='bg-gray-400 hover:bg-gray-500 text-white px-3 py-1 text-sm rounded-md'>Modify</button>
                </div>

                {/* Second Line */}
                <div className='flex items-center w-full'>
                    <button 
                        onClick={() => handleTabButtonClicked('opportunitiesTracker')} 
                        className={`text-gray-400 border-b-2 px-4 py-2 w-full ${activeChildViewOfSelectedProgramView === 'opportunitiesTracker' ? 'border-gray-800 font-bold text-gray-800' : 'border-gray-200'}`}
                    >
                        Tracker
                    </button>
                    <button 
                        onClick={() => handleTabButtonClicked('programTasksTab')} 
                        className={`text-gray-400 border-b-2 px-4 py-2 w-full ${activeChildViewOfSelectedProgramView === 'programTasksTab' ? 'border-gray-800 font-bold text-gray-800' : 'border-gray-200'}`}
                    >
                        Tasks
                    </button>
                    <button 
                        onClick={() => handleTabButtonClicked('fundersAwardsSearch')} 
                        className={`text-gray-400 border-b-2 px-4 py-2 w-full ${activeChildViewOfSelectedProgramView === 'fundersAwardsSearch' ? 'border-gray-800 font-bold text-gray-800' : 'border-gray-200'}`}
                    >
                        <div className='flex items-center justify-center'>   
                            Potential Funders 
                            <div className='ml-2 text-xs text-center text-white bg-indigo-600 rounded-lg px-3 py-1'>
                                {membershipDetails ? 
                                    allFunderScoresWithActiveStatusForOrganization?.filter(obj => obj.grantseeking_program_id === currentProgram.id).length
                                    : 
                                    <span className='text-xs'>🔒</span>
                                }
                            </div>
                        </div>
                    </button>
                    <button 
                        onClick={() => handleTabButtonClicked('potentialFundingPrograms')} 
                        className={`text-gray-400 border-b-2 px-4 py-2 w-full ${activeChildViewOfSelectedProgramView === 'potentialFundingPrograms' ? 'border-gray-800 font-bold text-gray-800' : 'border-gray-200'}`}
                    >
                        <div className='flex items-center justify-center'> 
                            <p className='mr-2'>Potential Grants</p>
                            <div className='text-xs text-center text-white bg-indigo-600 rounded-lg px-3 py-1'>
                                {membershipDetails ? 
                                    allGoodFitScoresWithActiveStatusForOrganization?.filter(obj => obj.grantseeking_program_id === currentProgram.id).length
                                    : 
                                    <span className='text-xs'>🔒</span>
                                }
                            </div>
                        </div>
                    </button>
                </div>                
            </div>
            {/* SelectedProgramChildView */}
            <div className='h-full'>
                {renderChildView()}
            </div>
            
        </div>
    );
};

export default SelectedProgramView;