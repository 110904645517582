import React, { useState } from 'react';
import openai from '../../config/openaiConfig';

const EditExcerptWithAiModal = ({ 
    setShowEditExcerptWithAiModal, 
    setShowLoadingModal, 
    highlightedTextForEditing,  
}) => {
    
    const [aiGeneratedResponse, setAiGeneratedResponse] = useState(null)
    const [prepopulatedInstructions, setPrepopulatedInstructions] = useState(null)

    const handleGenerateButtonClicked = async () => {
        
        const editingInstructions = document.getElementById('editingInstructions').value; // Get the value from the textarea
        if (!editingInstructions.trim()) {
            alert("Editing instructions cannot be empty.");
            return;
        }
        
        document.getElementById('editingInstructions').disabled = true; // Disable the textarea
        setShowLoadingModal(true)
    
        const response = await openai.chat.completions.create({
            model: "gpt-4o",
            messages: [
                { role: "system", content: "You are the world's best grant writer, working for a nonprofit."},
                { role: "user", content: `Edit the following text using these instructions: ${editingInstructions}. Text to edit: ${highlightedTextForEditing}`},
            ],
            temperature: 0.4,
            n: 1,
        });
    
        if (!response || !response.choices || response.choices.length === 0) {
            throw new Error("Invalid response from OpenAI API: 'response' or 'response.choices' is undefined");
        }
    
        const result = response.choices[0].message.content;
        setShowLoadingModal(false)
        setAiGeneratedResponse(result)
    }

    return (
        <div className="bg-gray-900 bg-opacity-50" 
        style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: '9999', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        onClick={() => {
            setShowEditExcerptWithAiModal(false);
            setPrepopulatedInstructions("");
        }}
   >
       
       {/* Modal */}
       <div className='text-left bg-white rounded-lg p-8' 
            style={{ width: '80vw', height: '80vh', overflowY: 'auto' }}
            onClick={(e) => e.stopPropagation()}>
           
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button onClick={() => setShowEditExcerptWithAiModal(false)} className="py-2 px-4 mb-4 bg-gray-500 text-white rounded-lg">Close</button>
                </div>
                <div>                    
                    <div className='bg-gray-200 p-8 text-left mb-6 rounded-lg'>
                        <p className='font-bold text-sm mb-4'>CURRENT VALUE</p>
                        <p className=''>{highlightedTextForEditing}</p>
                    </div>
                    
                    <div className='bg-gray-200 p-8 text-left mb-6 rounded-lg'>
                        <p className='font-bold text-sm'>EDITING INSTRUCTIONS</p>
                        <textarea 
                            id="editingInstructions" 
                            className="border border-black mt-4 w-full h-40 p-2 rounded-lg" 
                            placeholder="Make my text much sillier, and 50% shorter, so that it's about ~100 words"
                            defaultValue={prepopulatedInstructions || ''}
                        ></textarea>
                        <div className='text-sm mt-2'>
                            <p className='text-gray-500 italic text-sm mb-2'>Suggested prompts</p>
                            <button 
                                onClick={() => document.getElementById('editingInstructions').value = "Make my text 50% shorter"}
                                className="border border-gray-500 text-gray-500 font-bold py-2 px-4 rounded mr-2"
                            >
                                Shorten
                            </button>
                            <button 
                                onClick={() => document.getElementById('editingInstructions').value = "Extend my text by 50%"}
                                className="border border-gray-500 text-gray-500 font-bold py-2 px-4 rounded mr-2"
                            >
                                Extend
                            </button>
                            <button 
                                onClick={() => document.getElementById('editingInstructions').value = "Personalize my text for the following opportunity: (paste funder / opportunity details here)"}
                                className="border border-gray-500 text-gray-500 font-bold py-2 px-4 rounded"
                            >
                                Personalize
                            </button>
                        </div>
                    </div>

                    <div className='bg-gray-200 p-8 text-left rounded-lg'>
                        <p className='font-bold text-sm'>DAISY'S RESPONSE</p>
                        {aiGeneratedResponse 
                            ? <div>
                                <p className='italic text-gray-500 mb-4 text-sm'>Copy and paste if you like this response</p> 
                                <p>{aiGeneratedResponse}</p> 
                                <button 
                                    onClick={() => {
                                        navigator.clipboard.writeText(aiGeneratedResponse);
                                        alert("Response copied to clipboard!");
                                    }} 
                                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-2"
                                >
                                    Copy to Clipboard
                                </button>
                            </div>
                            : <button onClick={handleGenerateButtonClicked} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Generate</button>
                        }
                    </div>                                        
                </div>                
            </div>
        </div>
    );
};

export default EditExcerptWithAiModal;