// Import libraries
import React, { useEffect, useState } from 'react';
import { fetchOpportunitiesFromFirestoreForProgramId, updateProgramGoalAmountInFirestore } from '../../utilityFunctions/firestoreUtilities';

import KnownFunderBubble from '../HelperComponents/KnownFunderBubble';
import ManualFunderNameBubble from '../HelperComponents/ManualFunderNameBubble';
import KnownGrantMakingProgramBubble from '../HelperComponents/KnownGrantMakingProgramBubble';
import ManualGrantMakingProgramBubble from '../HelperComponents/ManualGrantMakingProgramBubble';

// Import utility functions
import db from '../../config/firebaseConfig'; 

// Import Firestore functions
import { addDoc, collection, serverTimestamp, query, where, getDocs, updateDoc } from "firebase/firestore";

const OpportunitiesTab = ({ 
        currentClientOrganization,
        setShowSelectedOpportunityModal,
        setShowSaveOpportunityModal,
        currentProgram,
        setSelectedSavedOpportunity,
        tasks,
        organizationDocuments,
        savedOpportunities,
        fetchProgramsForOrganization
    }) => {    

    const [goalAmount, setGoalAmount] = useState(null); 
    
    useEffect(() => {
        setGoalAmount(currentProgram.goalAmount);
    }, [currentProgram]);
    

    // Fetch opportunities from Firestore
    const [savedOpportunitiesForCurrentProgram, setSavedOpportunitiesForCurrentProgram] = useState([]);

    const countOfTasksForOpportunity = (opportunityId, allTasks) => {
        const completedTasks = allTasks.filter(task => task.linkedOpportunity === opportunityId && task.status === 'Completed').length;
        const notCompletedTasks = allTasks.filter(task => task.linkedOpportunity === opportunityId && task.status !== 'Completed').length;
        return { completed: completedTasks, notCompleted: notCompletedTasks };
    }

    const countOfDocumentsForOpportunity = (opportunityId, allDocuments) => {
        return allDocuments.filter(document => document.linkedOpportunityId === opportunityId).length;
    }    

    // Fetch opportunities from Firestore
    useEffect(() => {
        if (currentProgram && currentProgram.id) {

            // Filter savedOpportunities to only include opportunities for the current program
            const filteredSavedOpportunities = savedOpportunities.filter(opportunity => opportunity.grantSeekingProgramId === currentProgram.id);
            setSavedOpportunitiesForCurrentProgram(filteredSavedOpportunities);

            console.log("filteredSavedOpportunities", filteredSavedOpportunities);
            console.log("currentProgram", currentProgram);

        }
    }, [currentProgram, savedOpportunities]);


    // // Fetch goal from Firestore when component mounts
    // useEffect(() => {
    //     if (currentClientOrganization && currentClientOrganization.id) {
    //         const fetchGoal = async () => {
    //             try {
    //                 const goalsQuery = query(
    //                     collection(db, "goals"),
    //                     where("organizationId", "==", currentClientOrganization.id)
    //                 );
    //                 const querySnapshot = await getDocs(goalsQuery);

    //                 if (!querySnapshot.empty) {
    //                     const goalDoc = querySnapshot.docs[0];
    //                     setGoalAmount(goalDoc.data().goalAmount);
    //                 }
    //             } catch (error) {
    //                 console.error("Error fetching goal from Firestore: ", error);
    //             }
    //         };

    //         fetchGoal();
    //     }
    // }, [currentClientOrganization]);

    const totalWonAmount = savedOpportunities
        .filter(opportunity => opportunity.status === "Won" && opportunity.grantSeekingProgramId === currentProgram.id && opportunity.amount != null)
        .reduce((sum, opportunity) => sum + Number(opportunity.amount), 0);

    const totalSubmittedAmount = savedOpportunities
        .filter(opportunity => opportunity.status === "Submitted" && opportunity.grantSeekingProgramId === currentProgram.id)
        .reduce((sum, opportunity) => sum + Number(opportunity.amount), 0);

    const totalInProgressAmount = savedOpportunities
        .filter(opportunity => opportunity.status === "In Progress" && opportunity.grantSeekingProgramId === currentProgram.id && opportunity.amount != null)
        .reduce((sum, opportunity) => sum + Number(opportunity.amount), 0);

    const totalSavedAmount = savedOpportunities
        .filter(opportunity => opportunity.status === "Saved" && opportunity.grantSeekingProgramId === currentProgram.id && opportunity.amount != null)
        .reduce((sum, opportunity) => sum + Number(opportunity.amount), 0);


    const handleGoalClick = async () => {
        const newGoal = prompt("Enter a new goal amount:", goalAmount);
        if (newGoal !== null) {
            setGoalAmount(Number(newGoal));            
            // Update the program goal amount in Firestore
            try {                                
                await updateProgramGoalAmountInFirestore(currentProgram.id, newGoal);
                console.log("Program goal amount updated in Firestore successfully.");
                await fetchProgramsForOrganization(currentClientOrganization.id);
            } catch (error) {
                console.error("Error updating program goal amount in Firestore: ", error);
            }
        }
    };    

    return (
        <div className='p-12 w-full h-full text-xs'>
            <div>
                <div className="flex space-x-4 text-left">
                    
                    <div className="bg-gray-400 text-black font-bold py-4 px-4 rounded w-1/5">
                        <p className="text-3xl">${totalSavedAmount ? totalSavedAmount.toLocaleString() : '0'}</p>
                        <p className="text-xs font-normal mt-2">Saved</p>
                    </div>
                    <div className="bg-gray-400 text-black font-bold py-4 px-4 rounded w-1/5">
                        <p className="text-3xl">${totalInProgressAmount ? totalInProgressAmount.toLocaleString() : '0'}</p>
                        <p className="text-xs font-normal mt-2">In Progress</p>
                    </div>
                    <div className="bg-gray-400 text-black font-bold py-4 px-4 rounded w-1/5">
                        <p className="text-3xl">${totalSubmittedAmount ? totalSubmittedAmount.toLocaleString() : '0'}</p>
                        <p className="text-xs font-normal mt-2">Submitted</p>
                    </div>
                    <div className="bg-indigo-500 text-white font-bold py-4 px-4 rounded w-1/5">
                        <p className="text-3xl">${totalWonAmount ? totalWonAmount.toLocaleString() : '0'}</p>
                        <p className="text-xs font-normal mt-2">Won</p>
                    </div>
                    <div 
                        className="bg-green-500 text-white font-bold py-4 px-4 rounded w-1/5"
                        onClick={handleGoalClick}
                        style={{ cursor: 'pointer' }} 
                    >
                        <p className="text-3xl">${goalAmount ? goalAmount.toLocaleString() : '0'}</p>
                        <div className="flex justify-between mt-2">
                            <p className="text-xs font-normal">Goal</p>
                            <span className="text-xs">EDIT</span>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className="flex justify-between items-center mt-10">
                <p className="text-xl font-bold text-gray-400 text-left">SAVED OPPORTUNITIES</p>
                <div className="flex space-x-4">
                    <button onClick={() => setShowSaveOpportunityModal(true)} className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded">
                        Add New
                    </button>
                </div>
            </div>


            <div className="mt-6 border-2 border-gray-100 rounded-md">
                
                <table className="min-w-full text-left divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Grant Name</th>
                            <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Deadline</th>
                            <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                            <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Amount ($)</th>
                            <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Tasks</th>
                            <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Documents</th>
                            {/* <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Linked Funder</th> */}
                            {/* <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Linked Grant Program</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {savedOpportunitiesForCurrentProgram.map((savedOpportunity) => (
                            <tr 
                                key={savedOpportunity.id} 
                                onClick={() => {
                                    setSelectedSavedOpportunity(savedOpportunity)
                                    console.log(savedOpportunity)
                                    setShowSelectedOpportunityModal(true)
                                }}
                                className="bg-white border-b hover:bg-gray-200 cursor-pointer"
                            >
                                <td className="px-4 py-4">{savedOpportunity.name}</td>
                                <td className="px-4 py-4">
                                    {savedOpportunity.deadline ? new Date(savedOpportunity.deadline).toLocaleDateString('en-US', { timeZone: 'UTC' }) : ''}                                    
                                </td>
                                <td className="px-4 py-4">{savedOpportunity.status}</td>
                                <td className="px-4 py-4">{savedOpportunity.amount ? `$${Number(savedOpportunity.amount).toLocaleString()}` : '$0'}</td>
                                <td className="px-4 py-4">
                                    {countOfTasksForOpportunity(savedOpportunity.id, tasks).completed} / {countOfTasksForOpportunity(savedOpportunity.id, tasks).notCompleted + countOfTasksForOpportunity(savedOpportunity.id, tasks).completed} completed
                                </td>
                                <td className="px-4 py-4">{countOfDocumentsForOpportunity(savedOpportunity.id, organizationDocuments)}</td>
                                {/* <td className="px-4 py-4" onClick={(e) => e.stopPropagation()}>
                                    {
                                        savedOpportunity.userSelectedFunderEin ? 
                                            <KnownFunderBubble ein={savedOpportunity.userSelectedFunderEin} rowData={savedOpportunity} />
                                                : 
                                                    savedOpportunity.userDefinedManualFunderName ? 
                                                        <ManualFunderNameBubble manualFunderName={savedOpportunity.userDefinedManualFunderName} rowData={savedOpportunity} /> 
                                                        : 'N/A'
                                    }
                                </td>
                                <td className="px-4 py-4" onClick={(e) => e.stopPropagation()}>
                                    {
                                        savedOpportunity.userSelectedGrantMakingProgramId ? 
                                            <KnownGrantMakingProgramBubble programId={savedOpportunity.userSelectedGrantMakingProgramId} rowData={savedOpportunity} />
                                                : 
                                                    savedOpportunity.userDefinedManualGrantMakingProgramName ? 
                                                        <ManualGrantMakingProgramBubble manualGrantMakingProgramName={savedOpportunity.userDefinedManualGrantMakingProgramName} rowData={savedOpportunity} /> 
                                                        : 'N/A'
                                    }
                                </td> */}
                                
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default OpportunitiesTab;