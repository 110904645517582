import React from 'react';
import gmLogoName from '../../assets/gmLogoName.png';

function PaywallComponent({
    onClose
}) {
    const handleGetAccessClick = () => {
        // Add your logic here
        console.log('Get Access Now button clicked');
        window.open('https://satodadj.gumroad.com/l/rlqzat', '_blank');
    };

    return (
        <div className='fixed inset-0 flex flex-col justify-center bg-gray-100 bg-opacity-80 backdrop-blur-md md:p-16 rounded-lg' style={{ zIndex: 2147483647 }}>
            <div>            
                <button 
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 font-bold py-2 px-4 rounded"
                    onClick={onClose}
                >
                    Close
                </button>
                <img src={gmLogoName} alt="GM Logo" className="w-40 mx-auto mt-4 mb-2" />
                <p className="text-center text-2xl mt-2 py-4 md:py-0">You need a Premium plan to access this functionality.</p>                 
            </div>
            
            <div className="flex 
                flex-col md:flex-row 
                px-8 md:px-0 
                space-y-8 md:space-y-0
                overflow-y-auto md:overflow-y-hidden  
                h-screen 
                justify-center 
                items-center 
                md:space-x-16 
                py-5 ">
                <div className="bg-white w-full md:w-2/5 h-full p-8 rounded-lg shadow-xl">
                    <h2 className="text-xl font-bold text-center">Starter</h2>
                    <p className="text-center text-xs mt-2 text-gray-500">For those getting started with GrantMagic</p>
                    <p className="text-4xl font-bold text-left mt-2">Free</p>
                    <p className="text-left text-sm mt-2 text-gray-500">In your spare time, find possible funders</p>
                    <hr className="my-2 border-t border-gray-200 w-full" />                                                            
                    <ul className="text-left text-sm mt-4 space-y-2">
                        <li>✅ 1M+ grant awards database</li>                        
                        <li>✅ Grants / tasks organizer</li>
                        <li>❌ AI personalized grant discovery</li>                                                
                        <li>❌ AI magic drafts (LOIs, cover letters)</li>                                                                    
                        <li>❌ Create multiple programs</li>                        
                        <li>❌ Add teammates to your organization</li>
                        <li>❌ Dedicated account manager</li>
                    </ul>
                    <button className="bg-gray-200 text-white font-bold py-2 px-4 rounded mt-6 w-full mb-4 cursor-not-allowed">Already Joined</button>
                </div>
                <div className="bg-white w-full md:w-2/5 h-full p-8 rounded-lg shadow-xl">
                    <h2 className="text-xl font-bold text-center">Premium</h2>
                    <p className="text-center text-xs mt-2 text-gray-500">For nonprofits ready to win more grant funding</p>
                    <p className="text-left mt-4"><span className="text-4xl font-bold">$5 / month</span><span className="text-xs ml-2 text-left mt-2 text-gray-500">billed annually</span></p>
                    
                    <p className="text-left text-sm mt-2 text-gray-500">Win more grants, for the price of a latte</p>
                    <hr className="my-2 border-t border-gray-200 w-full" />    
                    <ul className="text-left text-sm mt-4 space-y-2">
                        <li>✅ 1M+ grant awards database</li>                        
                        <li>✅ Grants / tasks organizer</li>
                        <li>✅ AI personalized grant discovery</li>                                                
                        <li>✅ AI magic drafts (LOIs, cover letters)</li>                                                                    
                        <li>✅ Create up to 3 programs</li>
                        <li>❌ Add teammates to your organization</li>
                        <li>❌ Dedicated account manager</li>
                    </ul>                                                        
                    <button className="bg-indigo-500 text-white font-bold py-2 px-4 rounded mt-6 w-full" onClick={handleGetAccessClick}>Get Access Now</button>
                </div>
                <div className="bg-white w-full md:w-2/5 h-full p-8 rounded-lg shadow-xl">
                    <h2 className="text-xl font-bold text-center">Enterprise</h2>
                    <p className="text-center text-xs mt-2 text-gray-500">For teams to collaborate on winning grants</p>
                    <p className="text-left mt-4"><span className="text-4xl font-bold">Contact Us</span></p>
                    
                    <p className="text-left text-sm mt-2 text-gray-500">Tailored solutions for your organization</p>
                    <hr className="my-2 border-t border-gray-200 w-full" />    
                    <ul className="text-left text-sm mt-4 space-y-2">
                        <li>✅ 1M+ grant awards database</li>                        
                        <li>✅ Grants / tasks organizer</li>
                        <li>✅ AI personalized grant discovery</li>                                                
                        <li>✅ AI magic drafts (LOIs, cover letters)</li>                                                                    
                        <li>✅ Create up to 10 programs</li>
                        <li>✅ Add teammates to your organization</li>
                        <li>✅ Dedicated account manager</li>                                                                        
                    </ul>                                                        
                    <button className="bg-indigo-500 text-white font-bold py-2 px-4 rounded mt-6 w-full" onClick={() => window.open('https://tally.so/r/mDVa5j', '_blank')}>Contact Us</button>
                </div>
            </div>
            
        </div>                    
  );
}

export default PaywallComponent;
