
const DocumentsTab = ({        
    selectedSavedOpportunity,
    organizationDocuments,
    setShowGenerateAiDocumentModal,
    setCurrentGrantDocumentId,
    setShowSelectedOpportunityModal,
    membershipDetails,
    setShowPaywallModal
}) => {

    // Filter out tasks that are not for the selected opportunity
    const filteredDocuments = organizationDocuments.filter(document => document.linkedOpportunityId === selectedSavedOpportunity.id);

    const handleCreateNewDocument = () => {
        if (membershipDetails) {
            setShowGenerateAiDocumentModal(true);
        } else {
            setShowPaywallModal(true);
        }
    }
    
    return (
        <div>
            {filteredDocuments.length > 0 ? (
                <div className=''>
                    {filteredDocuments.map(document => (
                        <div key={document.id} 
                            className='p-4 bg-gray-100 rounded-md mb-4'
                            onClick={() => {
                                console.log("Document clicked: ", document);                                                               
                            }}
                        >
                            <h2 className='text-xl font-bold'>{document.documentName}</h2>
                            <p className='text-sm text-gray-500'>Created At: {new Date(document.createdAt.seconds * 1000).toLocaleString()}</p> 
                            <p className='text-sm text-gray-500'>Updated At: {new Date(document.updatedAt.seconds * 1000).toLocaleString()}</p> 
                            <button className='bg-gray-500 text-white px-4 py-2 rounded-md mt-2' onClick={() => {
                                setCurrentGrantDocumentId(document.id);
                                // setShowGenerateAiDocumentModal(true);
                                setShowSelectedOpportunityModal(false);
                            }}>Open in Editor</button>
                        </div>
                    ))}
                    <button className='bg-indigo-500 text-white px-4 py-2 rounded-md' onClick={handleCreateNewDocument}>Generate Magic Draft</button>
                </div>
            ) : (
                <div className='p-12 text-center'>
                    <p>No documents found for this opportunity.</p>
                    <button className='bg-indigo-500 text-white px-4 py-2 rounded-md' onClick={handleCreateNewDocument}>Generate Magic Draft</button>
            </div>            
            )}
        </div>
    );
};

export default DocumentsTab;