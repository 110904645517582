import InfoBubble from '../HelperComponents/InfoBubble';
import React, { useEffect } from 'react';
import { createGrantDocumentInFirestore } from '../../utilityFunctions/firestoreUtilities';
import KnownProgramBubble from '../HelperComponents/KnownProgramBubble'; 
import daisySprite from '../../assets/daisySprite.png';

import KnownOpportunityBubble from '../HelperComponents/KnownOpportunityBubble';
// import UnknownProgramBubble from '../HelperComponents/UnknownProgramBubble';
// import UnknownOpportunityBubble from '../HelperComponents/UnknownOpportunityBubble';
    
function DocumentLibrary({
    currentClientOrganization,
    setGenerateAiDocumentModalDefaultValue,
    setShowGenerateAiDocumentModal,
    organizationPrograms,
    organizationDocuments,
    setCurrentView,
    setCurrentProgram,
    refreshOrganizationDocuments,
    setCurrentGrantDocumentId,
    savedOpportunities,
    setSelectedSavedOpportunity,
    setShowManageDocumentModal,
    setGrantDocumentToManage,
    membershipDetails,
    setShowPaywallModal
}) {    

    const handleTemplateButtonClick = (templateName) => {
        if (!membershipDetails) {
            setShowPaywallModal(true);
        } else {
            setGenerateAiDocumentModalDefaultValue(templateName);
            setShowGenerateAiDocumentModal(true);
        }
    }

    const handleManualDocumentButtonClick = async () => {
        const documentName = prompt("Please enter the document name:");
        if (documentName) {
            const newDocumentId = await createGrantDocumentInFirestore(currentClientOrganization.id, "", documentName, null, null);
            refreshOrganizationDocuments();
            setCurrentGrantDocumentId(newDocumentId);
            setCurrentView("grantDocumentEditor");
        } else {
            alert("Document name is required.");
        }
    }

    useEffect(() => {
        console.log(organizationDocuments);
    }, [organizationDocuments]);

    return (
        <div className="p-12">
            <p className="text-3xl font-bold text-left mb-4">All Magic Drafts</p>            
            {/* Daisy Header */}
            <div className="w-full rounded-lg bg-gray-200 p-4 ">
                <div className="flex items-center">                     
                    <div className="flex items-center">
                        <img src={daisySprite} alt="Daisy Sprite" className="w-12 h-12 mr-4 rounded-full" />
                    </div>                    
                    <p className="text-lg">I can help you generate first drafts of grant documents in seconds. <span className="text-indigo-500 cursor-pointer" onClick={() => window.open('https://www.notion.so/GrantMagic-Documentation-17546353e32d8076a042c52f7b792c8d?pvs=4#17546353e32d805baecefacea1adccad', '_blank')}>Learn More</span></p>
                </div>
            </div>    
            
            <div className="flex flex-col gap-4 mt-6">
                
                {/* Start a new document */}
                
                <p className="text-left text-gray-400 font-bold">TEMPLATES</p>
                <div className='flex gap-4 rounded-lg text-center h-full'>
                    {/* <p className='text-left text-gray-500 py-2'>Generate a first draft document in seconds</p> */}
                    
                    {/* Document types */}
                    {/* <div className='flex gap-4'> */}
                        <button className='bg-indigo-100 hover:bg-indigo-300 text-indigo-900 px-4 py-4 rounded-md flex-1' onClick={() => handleTemplateButtonClick('Cover Letter')}>    
                            <p className='text-4xl'>+</p>
                            <p className=''>AI Cover Letter</p>
                        </button>
                        <button className='bg-indigo-100 hover:bg-indigo-300 text-indigo-900 px-4 py-4 rounded-md flex-1' onClick={() => handleTemplateButtonClick('LOI')}>
                            <p className='text-4xl'>+</p>
                            <p className=''>AI Letter of Intent</p>
                        </button>
                        <button className='bg-indigo-100 hover:bg-indigo-300 text-indigo-900 px-4 py-4 rounded-md flex-1' onClick={() => handleTemplateButtonClick('Custom')}>
                            <p className='text-4xl'>+</p>
                            <p className=''>Custom AI Draft</p>
                        </button>
                        <button className='bg-gray-100 hover:bg-gray-300 text-gray-900 px-4 py-4 rounded-md flex-1' onClick={handleManualDocumentButtonClick}>
                            <p className='text-4xl'>+</p>
                            <p className=''>Manual Draft</p>
                        </button>
                    {/* </div> */}

                </div>
                {/* Recent Documents */}
                {/* <div className=""> */}
                <div className="flex justify-between items-center mt-4">
                    <div className="flex items-center">
                        <p className="text-left text-gray-400 font-bold">RECENTLY UPDATED</p>
                        <InfoBubble 
                            text="These are documents created within GrantMagic that can be edited with AI." 
                            learnMoreUrl="https://www.google.com"
                        />
                    </div>
                    
                </div>
                <div className="flex-1 border-2 border-gray-100 rounded-md text-center h-full">                            
                    <table className="min-w-full text-left divide-y divide-gray-200 text-sm">
                        <thead className="bg-gray-50">
                            <tr>
                                
                                <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>                                
                                <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Created</th>
                                <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Updated</th>
                                <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Linked Program</th>
                                <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Linked Opportunity</th>   
                                <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>                                
                            </tr>
                        </thead>
                        <tbody>
                            {organizationDocuments.map((document, index) => (
                                <tr 
                                    key={index} 
                                    className="bg-white border-b"
                                    // onClick={() => {
                                    //     setCurrentGrantDocumentId(document.id);
                                    //     setCurrentView('grantDocumentEditor');
                                    // }}
                                >
                                    
                                    <td className="px-4 py-4">{document.documentName}</td>
                                    <td className="px-4 py-4">{document.createdAt.toDate().toLocaleDateString()}</td>
                                    <td className="px-4 py-4">{document.updatedAt.toDate().toLocaleDateString()}</td>
                                    
                                    <td className="px-4 py-4">
                                        {document.linkedProgramId ? (
                                            <span 
                                                onClick={(e) => e.stopPropagation()} 
                                            >
                                                <KnownProgramBubble 
                                                    programId={document.linkedProgramId} 
                                                    organizationPrograms={organizationPrograms}
                                                    setCurrentView={setCurrentView}
                                                    setCurrentProgram={setCurrentProgram}
                                                />
                                            </span>
                                        ) : (
                                            // <span 
                                            //     onClick={(e) => e.stopPropagation()} 
                                            // >
                                            //     <UnknownProgramBubble 
                                            //         rowData={document}
                                            //         organizationPrograms={organizationPrograms}
                                            //         documentOrTask={"document"}
                                            //         refreshOrganizationDocuments={refreshOrganizationDocuments}
                                            //     />
                                            // </span>
                                            <p className='text-gray-500'>-</p>
                                        )}
                                    </td>
                                    <td className="px-4 py-4">
                                        {document.linkedOpportunityId ? (
                                            <span 
                                                onClick={(e) => e.stopPropagation()} 
                                            >
                                                <KnownOpportunityBubble 
                                                    opportunityId={document.linkedOpportunityId}
                                                    savedOpportunities={savedOpportunities}
                                                    setSelectedSavedOpportunity={setSelectedSavedOpportunity}
                                                />
                                            </span>
                                        ) : (
                                            // <span 
                                            //     onClick={(e) => e.stopPropagation()} 
                                            // >
                                            //     <UnknownOpportunityBubble 
                                            //         rowData={document}
                                            //         savedOpportunities={savedOpportunities}
                                            //         documentOrTask={"document"}
                                            //         refreshOrganizationDocuments={refreshOrganizationDocuments}
                                            //     />
                                            // </span>
                                            <p className='text-gray-500'>-</p>
                                        )}
                                    </td>
                                    <td className='flex gap-2 py-4 text-xs'>
                                        <button 
                                            className='bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded-md'
                                            onClick={() => {
                                                setShowManageDocumentModal(true);
                                                setGrantDocumentToManage(document);
                                            }}
                                        >
                                            <p className='text-sm'>Manage</p>

                                        </button>
                                        <button 
                                            className='bg-indigo-500 hover:bg-indigo-600 text-white px-4 py-2 rounded-md'
                                            onClick={() => {
                                                setCurrentGrantDocumentId(document.id);
                                                setCurrentView('grantDocumentEditor');
                                            }}
                                        >
                                            <p className='text-sm'>Open in Editor</p>

                                        </button>

                                    </td>
                                    
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                
                {/* </div> */}

                
            </div>                
        </div>
        
    );
}

export default DocumentLibrary;