import React from 'react';

// Import AGGrid Components
import { AgGridReact } from 'ag-grid-react'; 
import 'ag-grid-community/styles/ag-grid.css'; 
import 'ag-grid-community/styles/ag-theme-alpine.css'; 
import FunderRenderer from '../HelperComponents/FunderRenderer';
import RecipientRenderer from '../HelperComponents/RecipientRenderer';

function AwardsAdvancedSearchResults({
    fetchedAwards,
}) {

    const awardsGridColumnDefs = [
        { 
            headerName: "Tax Year", 
            field: "tax_year",
            width:100
        },        
        { 
            headerName: "Funder", 
            field: "funder_name",
            filter: "agTextColumnFilter",
            cellRenderer: FunderRenderer,
            cellStyle: {fontWeight: 'bold', color: '#4338CA', cursor: 'pointer'}
        },
        { 
            headerName: "Recipient", 
            field: "recipient_name",
            filter: "agTextColumnFilter",            
            cellRenderer: RecipientRenderer,
            cellStyle: {fontWeight: 'bold', color: '#4338CA', cursor: 'pointer'}
        },
        { 
            headerName: "Size", 
            field: "cash_grant_amt",
            valueFormatter: ({ value }) => value ? `$${parseFloat(value).toLocaleString()}` : '',
            width: 100
        },
        { 
            headerName: "Purpose", 
            field: "purpose",            
        },
        { 
            headerName: "Recipient NTEE", 
            field: "ntee_codes.ntee_full",   
            width: 100         
        },
        { 
            headerName: "Recipient City", 
            field: "city_name",            
        },
        { 
            headerName: "Recipient State", 
            field: "state_abbreviation_cd",            
        },
    ];    

    return (
        <div className="text-left mt-4">
            <div className="ag-theme-alpine" style={{ height: 800, width: '100%' }}>
                <AgGridReact
                    rowData={fetchedAwards}
                    columnDefs={awardsGridColumnDefs}
                />
            </div>
        </div>
    );
}

export default AwardsAdvancedSearchResults;