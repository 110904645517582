import React from 'react';

function ManualGrantMakingProgramBubble({ 
        manualGrantMakingProgramName
    }) {

    const manualGrantMakingProgramClicked = () => {
        console.log("manual grant making program clicked");
    };

    return (
        <>                
        <button 
            className="px-2 py-1 rounded text-gray-800 text-sm bg-gray-100 hover:bg-gray-300 text-left text-xs"
            onClick={manualGrantMakingProgramClicked}
        >
            {manualGrantMakingProgramName}
        </button>                
        </>
    );
}

export default ManualGrantMakingProgramBubble;