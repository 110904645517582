import React, { useState } from 'react';
import { updateOpportunityInFirestore, deleteSavedOpportunityInFirestore, unlinkTasksForDeletedOpportunity, unlinkGrantDocumentsForDeletedOpportunity } from '../../utilityFunctions/firestoreUtilities';

const OverviewTab = ({        
    selectedSavedOpportunity,
    refreshSavedOpportunities,    
    setShowSelectedOpportunityModal,
    setSelectedSavedOpportunity,
    refreshTasks,
    refreshOrganizationDocuments
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedOpportunity, setEditedOpportunity] = useState(selectedSavedOpportunity);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedOpportunity({ ...editedOpportunity, [name]: value });
    };

    const handleSave = async () => {
        console.log("Saving opportunity:", editedOpportunity);
        await updateOpportunityInFirestore(selectedSavedOpportunity.id, editedOpportunity);
        await refreshSavedOpportunities();
        setIsEditing(false);
    };

    const handleCancel = () => {
        setIsEditing(false);
    };

    const handleDelete = async () => {
        console.log("Queued for deletion opportunity:", selectedSavedOpportunity);
        if (window.confirm("Are you sure you want to delete this opportunity?")) {
            await deleteSavedOpportunityInFirestore(selectedSavedOpportunity.id);
            await unlinkTasksForDeletedOpportunity(selectedSavedOpportunity.id);
            await unlinkGrantDocumentsForDeletedOpportunity(selectedSavedOpportunity.id);
            await refreshSavedOpportunities();
            await refreshTasks();
            await refreshOrganizationDocuments();
            setSelectedSavedOpportunity(null);
            setShowSelectedOpportunityModal(false);                        
        }
    };

    return (
        <div className='w-full h-full overflow-y-scroll' style={{ maxHeight: '80vh'}}>
            {selectedSavedOpportunity ? (
                <>
                    {isEditing ? (
                        <div className="flex flex-col gap-2 mb-24">
                            <label className="text-xs font-bold text-gray-400">OPPORTUNITY NAME</label>                            
                            <input className="text-md border-2 border-gray-400 rounded-md p-2" name="opportunityName" value={editedOpportunity.name} onChange={handleChange} />

                            <label className="text-xs font-bold text-gray-400">STATUS</label>
                            <select className="text-md border-2 border-gray-400 rounded-md p-2" name="status" value={editedOpportunity.status !== null ? editedOpportunity.status : ''} onChange={handleChange}>
                                <option value="Saved">Saved</option>
                                <option value="In Progress">In Progress</option>
                                <option value="Submitted">Submitted</option>
                                <option value="Won">Won</option>
                                <option value="Lost">Lost</option>
                            </select>     

                            <label className="text-xs font-bold text-gray-400">AMOUNT</label>
                            <input className="text-md border-2 border-gray-400 rounded-md p-2" name="amount" value={editedOpportunity.amount} onChange={handleChange} type="number" />
                            <label className="text-xs font-bold text-gray-400">DEADLINE</label>
                            <input 
                                className="text-md border-2 border-gray-400 rounded-md p-2" 
                                name="deadline" 
                                value={editedOpportunity.deadline ? new Date(editedOpportunity.deadline).toISOString().split('T')[0] : ''} 
                                onChange={handleChange} 
                                type="date" />
                            <label className="text-xs font-bold text-gray-400">WEBSITE</label>
                            <input className="text-md border-2 border-gray-400 rounded-md p-2" name="website" value={editedOpportunity.website} onChange={handleChange} />
                            <label className="text-xs font-bold text-gray-400">NOTES</label>
                            <textarea 
                                className="text-md border-2 border-gray-400 rounded-md p-2" 
                                name="notes" 
                                value={editedOpportunity.notes} 
                                onChange={handleChange} 
                                style={{ height: '100px' }}
                            />                                                                               
                            <div className='flex justify-center gap-4'> 
                                <button className='bg-blue-500 text-white px-4 py-2 rounded-md' onClick={handleSave}>Save</button>
                                <button className='bg-gray-500 text-white px-4 py-2 rounded-md' onClick={handleCancel}>Cancel</button>
                            </div>                            
                        </div>
                    ) : (
                        <div className="flex flex-col gap-3">                            
                            <label className="text-xs font-bold text-gray-400">OPPORTUNITY NAME</label>
                            <h2 className='text-md'>{selectedSavedOpportunity.name}</h2>
                            <label className="text-xs font-bold text-gray-400">STATUS</label>
                            <p className='text-md'>{selectedSavedOpportunity.status !== null ? selectedSavedOpportunity.status : 'N/A'}</p>
                            <label className="text-xs font-bold text-gray-400">AMOUNT</label>
                            {selectedSavedOpportunity.amount !== null ? (
                                <p className='text-md'>${selectedSavedOpportunity.amount.toLocaleString('en-US')}</p>
                            ) : (
                                <p></p>
                            )}
                            <label className="text-xs font-bold text-gray-400">DEADLINE</label>
                            {selectedSavedOpportunity.deadline !== null ? (
                                <p className='text-md'>{selectedSavedOpportunity.deadline ? new Date(selectedSavedOpportunity.deadline).toLocaleDateString('en-US', { timeZone: 'UTC' }) : 'N/A'}</p>
                            ) : (
                                <p></p>
                            )}

                            <label className="text-xs font-bold text-gray-400">WEBSITE</label>
                            <p><a href={`https://${selectedSavedOpportunity.website}`} target="_blank" rel="noopener noreferrer">{selectedSavedOpportunity.website}</a></p>

                            <label className="text-xs font-bold text-gray-400">NOTES</label>
                            <p className='text-md'>{selectedSavedOpportunity.notes}</p>
                                                        
                            <button  
                                className='bg-gray-500 text-white px-4 py-2 rounded-md mx-auto' 
                                onClick={handleEdit}
                            >
                                Edit Details
                            </button>
                            <button className='text-red-500 px-4 py-2 text-xs rounded-md mx-auto' onClick={handleDelete}>
                                Delete Opportunity
                            </button>
                        </div>
                    )}
                </>
            ) : (
                <p>No opportunity selected.</p>
            )}
        </div>
    );
};

export default OverviewTab;