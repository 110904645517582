import { useContext, useState, useEffect } from 'react';
// import { createProgramInFirestore, updateProgramInFirestore, deleteProgramFromFirestore } from '../../utilityFunctions/firestoreUtilities';
import { AuthContext } from '../../AuthContext';
import { fetchOpportunitiesForClientOrgId, createTaskInFirestore } from '../../utilityFunctions/firestoreUtilities';
// import { cleanText } from '../../utilityFunctions/uxUtilities';

const CreateTaskModal = ({ 
    setShowCreateTaskModal,
    organizationPrograms,
    currentClientOrganization,
    selectedSavedOpportunity,
    refreshTasks,
    savedOpportunities,
    currentProgram
    }) => {

    const { authUser } = useContext(AuthContext);


    const handleCreateTask = async (e) => {
        e.preventDefault();

        const task = {
            name: name,
            deadline: deadline,
            linkedOpportunity: linkedOpportunity,
            linkedProgram: linkedProgram,
            currentClientOrganizationId: currentClientOrganization.id,
            userId: authUser.uid,
            status: 'Not Started',
            createdAt: new Date(),
            updatedAt: new Date()
        };
        await createTaskInFirestore(task);
        refreshTasks()
        setShowCreateTaskModal(false);        
    };

    // New state variables for the form fields
    const [name, setName] = useState('');
    const [deadline, setDeadline] = useState('');
    const [linkedOpportunity, setLinkedOpportunity] = useState(selectedSavedOpportunity ? selectedSavedOpportunity.id : '');
    const [linkedProgram, setLinkedProgram] = useState(currentProgram ? currentProgram.id : '');


    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log({ name, deadline, linkedOpportunity, linkedProgram });
    };

    return (
        <div 
            className="modal-overlay" 
            style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}
            onClick={() => setShowCreateTaskModal(false)}
        >
            <div 
                className="modal p-8 overflow-auto max-h-full" 
                style={{ 
                    cursor: 'default', 
                    width: '40vw', 
                    height: '70vh', 
                    backgroundColor: 'white', 
                    borderRadius: '10px'
                }}
                onClick={(e) => e.stopPropagation()} // Prevent click event from propagating to the overlay
            >
                <div className="text-2xl font-bold py-4">Create Task</div>
                <form onSubmit={handleSubmit} className="flex flex-col gap-2 text-left">
                    <label className="mb-1 font-semibold text-sm text-gray-500">Task</label>
                    <input 
                        type="text" 
                        placeholder="Create a first draft of the RFP" 
                        value={name} 
                        onChange={(e) => setName(e.target.value)} 
                        className="mb-2 p-2 border border-gray-300 rounded"
                    />
                    <label className="mb-1 font-semibold text-sm text-gray-500">Deadline</label>
                    <input 
                        type="date" 
                        value={deadline} 
                        onChange={(e) => setDeadline(e.target.value)} 
                        className="mb-2 p-2 border border-gray-300 rounded"
                    />
                    <label className="mb-1 font-semibold text-sm text-gray-500">Linked Program</label>
                    <select 
                        value={linkedProgram} 
                        onChange={(e) => setLinkedProgram(e.target.value)} 
                        className="mb-2 p-2 border border-gray-300 rounded"
                    >
                        <option value="" disabled>Select a Program</option>
                        {organizationPrograms.map((program) => (
                            <option key={program.id} value={program.id}>
                                {program.programName}
                            </option>
                        ))}
                    </select>
                    <label className="mb-1 font-semibold text-sm text-gray-500">Linked Opportunity</label>
                    <select 
                        value={linkedOpportunity} 
                        onChange={(e) => setLinkedOpportunity(e.target.value)} 
                        className="mb-2 p-2 border border-gray-300 rounded"
                    >
                        <option value="" disabled>Select an Opportunity</option>
                        {savedOpportunities
                            .filter(opportunity => opportunity.grantSeekingProgramId === linkedProgram)
                            .map((opportunity) => (
                                <option key={opportunity.id} value={opportunity.id}>
                                    {opportunity.name}
                                </option>
                        ))}
                    </select>

                    <button 
                        type="submit" 
                        onClick={handleCreateTask} 
                        className="p-2 bg-indigo-700 hover:bg-indigo-900 text-white rounded"
                    >
                        Create
                    </button>
                </form>
            </div>
        </div>
    );
};

export default CreateTaskModal;