import React, { useState, useEffect } from 'react';
import daisySprite from '../../assets/daisySprite.png';
// import loading from '../../assets/loading.gif'

// Import KnownProgramBubble
import KnownProgramBubble from '../HelperComponents/KnownProgramBubble';

// Import UnknownProgramBubble
import UnknownProgramBubble from '../HelperComponents/UnknownProgramBubble';

// Import UnknownOpportunityBubble
import UnknownOpportunityBubble from '../HelperComponents/UnknownOpportunityBubble';

// Import KnownOpportunityBubble
import KnownOpportunityBubble from '../HelperComponents/KnownOpportunityBubble';

const TasksDashboard = ({
    setShowCreateTaskModal,
    tasks,
    refreshTasks,
    savedOpportunities, 
    organizationPrograms,
    setSelectedTask,
    setSelectedSavedOpportunity,
    refreshOrganizationDocuments,
    setCurrentView,
    setCurrentProgram
}) => {

    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        setRowData(tasks);
    }, [tasks]);

    // When row clicked, set selected opportunity and show opportunity / funder modal
    const handleRowClick = (data) => {
        setSelectedTask(data);
    };

    

    return (
        <div className='p-12'>
            <p className="text-3xl font-bold text-left mb-4">All Tasks</p>            
            {/* Daisy Header */}
            <div className="w-full rounded-lg bg-gray-200 p-4 ">
                <div className="flex items-center">                     
                    <div className="flex items-center">
                        <img src={daisySprite} alt="Daisy Sprite" className="w-12 h-12 mr-4 rounded-full" />
                    </div>                    
                    <p className="text-lg">Stay organized by creating tasks for all your grantmaking activities</p>                    
                </div>
            </div>    
            
            <div className='flex text-xl items-center mt-10 mb-4 justify-between'>
                <p className="text-left text-gray-400 font-bold">ALL TASKS</p>            
                <button className='bg-indigo-100 hover:bg-indigo-300 text-indigo-900 text-sm px-4 py-2 rounded-lg' onClick={() => setShowCreateTaskModal(true)}>+ Add New</button>
            </div>
            
            <div className="flex-1 border-2 border-gray-100 rounded-md text-center h-full">                            
                <table className="min-w-full text-left divide-y divide-gray-200 text-sm">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Task Name</th>
                            <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                            <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Deadline</th>
                            <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Linked Program</th>
                            <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider">Linked Opportunity</th>                            
                            <th className="px-4 py-4 text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {rowData.map((task) => (
                            <tr key={task.id} 
                                className="bg-white border-b cursor-pointer hover:bg-gray-100"
                                onClick={() => handleRowClick(task)}
                            >
                                <td className="px-4 py-4">{task.name}</td>
                                <td className='px-4 py-4'>{task.status}</td>
                                <td className='px-4 py-4'>{task.deadline ? new Date(task.deadline).toLocaleDateString('en-US', { timeZone: 'UTC' }) : 'N/A'}</td>
                                <td className='px-4 py-4'>
                                    {task.linkedProgram ? (
                                            <span 
                                                onClick={(e) => e.stopPropagation()}                                                 
                                            >
                                                <KnownProgramBubble 
                                                    programId={task.linkedProgram} 
                                                    organizationPrograms={organizationPrograms}
                                                    setCurrentView={setCurrentView}
                                                    setCurrentProgram={setCurrentProgram}
                                                />
                                            </span>
                                        ) : (
                                            <span 
                                                onClick={(e) => e.stopPropagation()} 
                                            >
                                                <UnknownProgramBubble 
                                                    rowData={task}
                                                    organizationPrograms={organizationPrograms}
                                                    documentOrTask={"task"}
                                                    refreshOrganizationDocuments={refreshOrganizationDocuments}
                                                    refreshTasks={refreshTasks}
                                                />
                                            </span>
                                        )}
                                </td>
                                <td className='px-4 py-4'>
                                    {task.linkedProgram ? (
                                        task.linkedOpportunity ? (
                                            <div className=''>
                                                <span 
                                                    onClick={(e) => e.stopPropagation()} 
                                                >
                                                    <KnownOpportunityBubble 
                                                        opportunityId={task.linkedOpportunity}
                                                        savedOpportunities={savedOpportunities}
                                                        setSelectedSavedOpportunity={setSelectedSavedOpportunity}
                                                    />
                                                </span>
                                            </div>
                                        ) : (
                                            <span 
                                                onClick={(e) => e.stopPropagation()} 
                                            >
                                                <UnknownOpportunityBubble 
                                                    rowData={task}
                                                    savedOpportunities={savedOpportunities}
                                                    documentOrTask={"task"}
                                                    refreshOrganizationDocuments={refreshOrganizationDocuments}
                                                    refreshTasks={refreshTasks}
                                                />
                                            </span>
                                        )
                                    ) : (
                                        <span className='text-gray-400'>
                                            No program selected
                                        </span>
                                    )}
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default TasksDashboard;