import React from 'react';

const FunderHighlights = ({ 
    awardsGranted, 
    currentProgram, 
    setProgramToModify, 
    setShowCreateProgramModal 
}) => {
    return (
        <div>
            <p className='orgDetailsLabel'>Funder Highlights</p>
            {currentProgram && currentProgram.primaryState && currentProgram.nteeFull ? (
                <div className='bg-gray-100 p-4 rounded-md'>
                    <p>Grants Awarded in {currentProgram.primaryState}: {awardsGranted.filter(award => award.state_abbreviation_cd === currentProgram.primaryState).length} (
                        {(() => {
                            const percentage = (awardsGranted.filter(award => award.state_abbreviation_cd === currentProgram.primaryState).length / awardsGranted.length) * 100;
                            return (
                                <span className={percentage < 33 ? 'text-red-500' : (percentage <= 67 ? 'text-yellow-500' : 'text-green-500')}>
                                    {percentage.toFixed(0)}% of total
                                </span>
                            );
                        })()}
                    )</p>
                    <p>Grants Awarded to nonprofits with NTEE major {currentProgram.nteeFull[0]}: {awardsGranted.filter(award => award.ntee_codes.ntee_major === currentProgram.nteeFull[0]).length} (
                        {(() => {
                            const percentageNTEEMajor = (awardsGranted.filter(award => award.ntee_codes.ntee_major === currentProgram.nteeFull[0]).length / awardsGranted.length) * 100;
                            const colorNTEEMajor = percentageNTEEMajor < 33 ? 'text-red-500' : (percentageNTEEMajor <= 67 ? 'text-yellow-500' : 'text-green-500');
                            return (
                                <span className={colorNTEEMajor}>{percentageNTEEMajor.toFixed(0)}% of total</span>
                            );
                        })()}
                    )</p>
                    <p>Grants Awarded to nonprofits with NTEE code {currentProgram.nteeFull}: {awardsGranted.filter(award => award.ntee_codes.ntee_full === currentProgram.nteeFull).length} (
                        {(() => {
                            const percentageNTEE = (awardsGranted.filter(award => award.ntee_codes.ntee_full === currentProgram.nteeFull).length / awardsGranted.length) * 100;
                            const colorNTEE = percentageNTEE < 33 ? 'text-red-500' : (percentageNTEE <= 67 ? 'text-yellow-500' : 'text-green-500');
                            return (
                                <span className={colorNTEE}>{percentageNTEE.toFixed(0)}% of total</span>
                            );
                        })()}
                    )</p>
                </div>
            ) : (
                <div>
                    <p>Add a Primary State and NTEE Code for your program to see in-state giving. </p>
                    <button className='bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 mt-2 text-xs rounded' onClick={() => {
                        setProgramToModify(currentProgram);
                        setShowCreateProgramModal(true);
                    }}>Modify Program</button>
                </div>
            )}
        </div>
    );
};

export default FunderHighlights;