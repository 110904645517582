import React, { useEffect, useState, useCallback } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { fetchSingleGrantDocumentFromFirestore, updateGrantDocumentContentInFirestore, createGrantDocumentBackupInFirestore } from '../../utilityFunctions/firestoreUtilities';

const GrantDocumentEditor = ({ 
    currentGrantDocumentId,
    setShowGrantDocumentVersionHistoryModal,
    setCurrentView,
    setShowEditExcerptWithAiModal,
    setHighlightedTextForEditing,
    setCurrentGrantDocumentId, 
    membershipDetails,
    setShowPaywallModal
}) => {
    
    // State variables
    const [content, setContent] = useState("");
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const [isAutosaving, setIsAutosaving] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState({});
    
    // Fetch the selected document from Firestore
    useEffect(() => {
        fetchSingleGrantDocumentFromFirestore(currentGrantDocumentId).then(doc => {
            setContent(doc.content);
            setSelectedDocument({
                documentName: doc.documentName,
                createdAt: doc.createdAt,
                updatedAt: doc.updatedAt,
            });
        });
    }, [currentGrantDocumentId]);

    // Handle when content changes in the editor
    const handleEditorChange = (newContent) => {
        setContent(newContent);
        
        // Clear the previous timeout if it exists
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        // Set a new timeout to update Firestore after 2 seconds
        setIsAutosaving(true);
        const timeout = setTimeout(() => {
            updateGrantDocumentContentInFirestore(currentGrantDocumentId, newContent);
            createGrantDocumentBackupInFirestore(currentGrantDocumentId, newContent);
            setIsAutosaving(false);
        }, 2000); 

        setDebounceTimeout(timeout);
    };

    // Handle when the edit with AI button is clicked
    const handleEditWithAiButtonClicked = () => {
        if (!membershipDetails) {
            setShowPaywallModal(true);
        } else {
            const editor = document.querySelector('.tox-edit-area__iframe').contentWindow;
            const selectedText = editor.getSelection().toString();
            if (selectedText) {
            console.log(selectedText);
            setHighlightedTextForEditing(selectedText);
            setShowEditExcerptWithAiModal(true);            
            } else {
                alert('Highlight some text to edit');
            }
        }
    };

    return (    
        <div className="p-12">
            <div className='flex justify-between'>
                <button 
                    // onClick={() => setCurrentView('selectedProposalPage')} 
                    onClick={() => {
                        setCurrentView('documentLibrary');
                        setCurrentGrantDocumentId(null);
                    }} 
                    className="py-2 px-4 mb-4 bg-gray-500 text-white rounded-lg"
                >
                    Return to Magic Drafts
                </button>
                <div className="flex items-center">
                    <div className="text-right text-gray-500 italic mr-4 py-2 mb-4">
                        {isAutosaving ? 'Autosaving...' : ''}
                    </div>
                    <button onClick={() => setShowGrantDocumentVersionHistoryModal(true)} className="py-2 px-4 mb-4 bg-gray-500 text-white rounded-lg">Version History</button>
                </div>                
            </div>
            
            
            <div className="bg-gray-100 p-4 mb-4 text-left">
                <p className="text-lg font-bold">{selectedDocument?.documentName}</p>
                <p className="text-sm text-gray-500">Created At: {typeof selectedDocument?.createdAt === 'number' ? 'N/A' : selectedDocument?.createdAt?.toDate().toLocaleString()}</p>
                <p className="text-sm text-gray-500">Updated At: {typeof selectedDocument?.updatedAt === 'number' ? 'N/A' : selectedDocument?.updatedAt?.toDate().toLocaleString()}</p>
                
            </div>

            <Editor
                apiKey='cekn05m1tu7m80bul3avb22bykji24m4i2lnf4fnto0qs7lf'
                init={{
                    menubar: false,
                    plugins: '',
                    toolbar: false,
                    height: "60vh",
                    statusbar: false,
                }}
                value={content}
                onEditorChange={handleEditorChange}
                
                
            />

            <div className='flex justify-center mt-8'>
                <button 
                    className='py-2 px-4 mb-4 bg-indigo-500 hover:bg-indigo-600 text-white rounded-lg w-1/4'
                    onClick={handleEditWithAiButtonClicked}
                >
                    ✨ Edit With AI ✨
                </button>
            </div>
        </div>
    );    
}

export default GrantDocumentEditor;