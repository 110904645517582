import React, { useState } from 'react';
import { updateProgramIdOfADocumentInFirestore, updateProgramIdOfATaskInFirestore } from '../../utilityFunctions/firestoreUtilities';

function UnknownProgramBubble({ 
        rowData,
        organizationPrograms,
        documentOrTask,
        refreshOrganizationDocuments,
        refreshTasks
    }) {
    
    const [isSelectProgramModalOpen, setIsSelectProgramModalOpen] = useState(false);
    const [modalPosition, setModalPosition] = useState({ top: '50%', left: '50%' });

    const handleCloseModal = () => {
        setIsSelectProgramModalOpen(false);
    };

    const handleAddProgamClicked = (event) => {
        const { clientX, clientY } = event;
        setModalPosition({ top: `${clientY}px`, left: `${clientX}px` });
        setIsSelectProgramModalOpen(true);
    };

    const programSelectedInModal = async (programId) => {    
        
        if (documentOrTask === "document") {
            await updateProgramIdOfADocumentInFirestore(rowData.id, programId);
            console.log("Document updated successfully!");
            refreshOrganizationDocuments();
        } else if (documentOrTask === "task") {
            await updateProgramIdOfATaskInFirestore(rowData.id, programId);
            console.log("Task updated successfully!");
            refreshTasks();
        }
    };

    return (
        <>                
        <button 
            className="px-2 py-1 rounded text-indigo-500 hover:font-bold hover:text-indigo-700"
            onClick={handleAddProgamClicked}
        >
            + Program
        </button>
        
        {/* Select Program Modal */}
        {isSelectProgramModalOpen && (
            <div 
                onClick={handleCloseModal}
                style={{ 
                    position: 'absolute', 
                    top: '0', 
                    left: '0', 
                    width: '100%', 
                    height: '100%', 
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    cursor: 'default',
                    zIndex: 999
                }}
            >
                <div 
                    onClick={(e) => e.stopPropagation()}
                    style={{ 
                        position: 'absolute', 
                        top: modalPosition.top, 
                        left: modalPosition.left, 
                        transform: 'translate(-50%, -50%)', 
                        backgroundColor: 'white', 
                        border: '1px solid #ccc', 
                        borderRadius: '8px', 
                        padding: '20px', 
                        zIndex: 1000
                    }}
                >
                    <p className='text-xs text-gray-500'>Select a Program</p>
                    <ul className='py-4'>
                        {organizationPrograms.map(program => (
                            <li 
                                key={program.id} 
                                onClick={() => { programSelectedInModal(program.id); handleCloseModal(); }}
                                className="cursor-pointer hover:bg-gray-100 px-2 py-2 rounded-md"
                            >
                                {program.programName}
                            </li>
                        ))}
                    </ul>
                    <button className='text-gray-500 hover:font-bold hover:text-gray-700' onClick={handleCloseModal}>Close</button>
                </div>
            </div>
        )}
        </>
    );
}

export default UnknownProgramBubble;