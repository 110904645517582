import React, { useState, useEffect } from 'react';
import DrawerOpportunityView from './DrawerOpportunityView';
import DrawerFunderView from './DrawerFunderView';

function OpportunityAndFunderDrawer({
    selectedDrawerView,
    setSelectedDrawerView,
    setShowLoadingModal,
    // selectedFunderEin,
    grantmakingProgramIdForDrawerView,
    funderEinForDrawerView,
    setFunderEinForDrawerView,
    openSaveOpportunityModalWithKnownFunderAndOrGrantmakingProgram,
    showSelectedOpportunityModal,
    currentProgram,
    setProgramToModify,
    setShowCreateProgramModal
}) {        

    const [selectedGrantmakingProgramDataForDrawerView, setSelectedGrantmakingProgramDataForDrawerView] = useState(null)

    return (
        <div className='h-full border border-gray-300' >
            {/* Header Buttons */}
            <div className="flex w-full h-16">
                <button 
                    onClick={() => setSelectedDrawerView('opportunity')} 
                    className={`flex-1 px-4 py-3 bg-gray-100 ${selectedDrawerView === 'opportunity' ? 'font-bold bg-gray-500 text-white' : 'text-gray-400'}`}
                >
                    Grantmaking Program
                </button>
                <button 
                    onClick={() => setSelectedDrawerView('funder')} 
                    className={`flex-1 px-4 py-3 bg-gray-100  ${selectedDrawerView === 'funder' ? 'font-bold bg-gray-500 text-white' : 'text-gray-400'}`}
                >
                    Funder
                </button>
            </div>            
            <div className='w-full h-full '>
                {selectedDrawerView === 'opportunity' ? (
                    <DrawerOpportunityView
                        setShowLoadingModal={setShowLoadingModal}
                        grantmakingProgramIdForDrawerView={grantmakingProgramIdForDrawerView}
                        selectedGrantmakingProgramDataForDrawerView={selectedGrantmakingProgramDataForDrawerView}
                        setSelectedGrantmakingProgramDataForDrawerView={setSelectedGrantmakingProgramDataForDrawerView}
                        openSaveOpportunityModalWithKnownFunderAndOrGrantmakingProgram={openSaveOpportunityModalWithKnownFunderAndOrGrantmakingProgram}
                        showSelectedOpportunityModal={showSelectedOpportunityModal}
                        setFunderEinForDrawerView={setFunderEinForDrawerView}
                        setProgramToModify={setProgramToModify}
                        setShowCreateProgramModal={setShowCreateProgramModal}
                        currentProgram={currentProgram}
                    />
                ) : (
                    <DrawerFunderView
                        // selectedFunderEin={selectedFunderEin}
                        setShowLoadingModal={setShowLoadingModal}
                        funderEinForDrawerView={funderEinForDrawerView}
                        grantmakingProgramIdForDrawerView={grantmakingProgramIdForDrawerView}
                        selectedGrantmakingProgramDataForDrawerView={selectedGrantmakingProgramDataForDrawerView}
                        selectedDrawerView={selectedDrawerView}    
                        openSaveOpportunityModalWithKnownFunderAndOrGrantmakingProgram={openSaveOpportunityModalWithKnownFunderAndOrGrantmakingProgram}
                        showSelectedOpportunityModal={showSelectedOpportunityModal}
                        currentProgram={currentProgram}
                        setProgramToModify={setProgramToModify}
                        setShowCreateProgramModal={setShowCreateProgramModal}
                        // funderEinForDrawerView={funderEinForDrawerView}
                    />
                )}
            </div>
        </div>                
    );
}

export default OpportunityAndFunderDrawer;