import React, { useEffect, useState } from 'react';
// import { fetchSingleProgramFitScoreFromSupabase, fetchSingleGrantmakingProgramFromSupabase } from '../utilityFunctions/supabaseUtilities';
import { fetchSingleGrantmakingProgramFromSupabase, updateArchivedGrantmakingProgramInSupabase, updateSavedGrantmakingProgramInSupabase, fetchAwardsGrantedFromSupabase } from '../../utilityFunctions/supabaseUtilities';
import FunderHighlights from '../HelperComponents/FunderHighlights';

const DrawerOpportunityView = ({     
    setShowLoadingModal,
    grantmakingProgramIdForDrawerView,
    selectedGrantmakingProgramDataForDrawerView,
    setSelectedGrantmakingProgramDataForDrawerView,
    openSaveOpportunityModalWithKnownFunderAndOrGrantmakingProgram,
    showSelectedOpportunityModal,
    setProgramToModify,
    setShowCreateProgramModal,
    currentProgram
}) => {

    const [awardsGranted, setAwardsGranted] = useState([])

    useEffect(() => {
        const fetchAwardsGranted = async () => {
            if (selectedGrantmakingProgramDataForDrawerView) {
                const awards = await fetchAwardsGrantedFromSupabase(selectedGrantmakingProgramDataForDrawerView.ein)
                console.log("Awards Granted: ", awards)
                console.log()
                setAwardsGranted(awards)
            } else {
                console.warn("selectedGrantmakingProgramDataForDrawerView is null");
            }
        }
        fetchAwardsGranted()
    }, [grantmakingProgramIdForDrawerView, selectedGrantmakingProgramDataForDrawerView])

    useEffect(() => {
        console.log("Selected Grantmaking Program ID from DOW: ", grantmakingProgramIdForDrawerView)
        if (grantmakingProgramIdForDrawerView) {
            setShowLoadingModal(true);
            const fetchSingleGrantmakingProgram = async () => {
                const programData = await fetchSingleGrantmakingProgramFromSupabase(grantmakingProgramIdForDrawerView)
                console.log("Program Data: ", programData)
                setSelectedGrantmakingProgramDataForDrawerView(programData[0])
                setShowLoadingModal(false);
            }
            fetchSingleGrantmakingProgram()
        }
    }, [grantmakingProgramIdForDrawerView])    

    return (
        selectedGrantmakingProgramDataForDrawerView ? (
            <div className='text-left p-4 text-sm overflow-y-auto' style={{ maxHeight: '80vh' }}>
                <div className='text-sm'>
                    <div className='flex flex-row justify-between'>
                        <div className="ml-6 mt-2 text-xl font-bold">{selectedGrantmakingProgramDataForDrawerView.name}</div>
                        <div className='flex space-x-2'>                            
                            {!showSelectedOpportunityModal && (
                                <button 
                                    className='bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded' 
                                    onClick={() => openSaveOpportunityModalWithKnownFunderAndOrGrantmakingProgram(selectedGrantmakingProgramDataForDrawerView.ein, grantmakingProgramIdForDrawerView, selectedGrantmakingProgramDataForDrawerView.name)}
                                >
                                    Save to Tracker
                                </button>
                            )}
                        </div>
                    </div>                    
                    <div className="ml-6 mt-4">
                        {/* <a 
                            href={`/profiles/organization/${selectedGrantmakingProgramDataForDrawerView.ein}`} 
                            rel="noopener noreferrer" 
                            className='text-blue-800'
                        >
                            View Funder
                        </a>
                        <span className='text-gray-500'> | </span> */}
                        <a className='text-blue-800' href={selectedGrantmakingProgramDataForDrawerView.website_url} target="_blank" rel="noopener noreferrer">Visit Website</a>
                    </div>                                        

                    {/* Key Details */}
                    <hr className='my-4 mx-6' />
                    <p className='text-xl ml-6 font-bold'>Key Details</p>
                    {selectedGrantmakingProgramDataForDrawerView.grant_overview && (
                            <div className="m-6"><strong>Overview</strong><br /><span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedGrantmakingProgramDataForDrawerView.grant_overview }} /></div>
                    )}
                    {selectedGrantmakingProgramDataForDrawerView.funder_details && (
                            <div className="m-6"><strong>Funder Details</strong><br /><span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedGrantmakingProgramDataForDrawerView.funder_details }} /></div>
                    )}              

                    {awardsGranted.length > 0 && (
                        <div className='m-6'>
                            <FunderHighlights 
                                awardsGranted={awardsGranted} 
                                currentProgram={currentProgram} 
                                setProgramToModify={setProgramToModify} 
                                setShowCreateProgramModal={setShowCreateProgramModal} 
                            />
                        </div>
                    )}
                    {/* Eligibility Requirements */}
                    <hr className='my-4 mx-6' />
                    <p className='text-xl ml-6 font-bold'>Eligibility Requirements</p>
                    {selectedGrantmakingProgramDataForDrawerView.eligibility_criteria && (
                        <div className="m-6"><span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedGrantmakingProgramDataForDrawerView.eligibility_criteria }} /></div>
                    )}

                    {/* Application Information */}
                    <hr className='my-4 mx-6' />
                    <p className='text-xl ml-6 font-bold'>Application Information</p>
                    {selectedGrantmakingProgramDataForDrawerView.application_details && (
                        <div className="m-6"><span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedGrantmakingProgramDataForDrawerView.application_details }} /></div>
                    )}

                    {/* Additional Information */}
                    <hr className='my-4 mx-6' />
                    <p className='text-xl ml-6 font-bold'>Additional Information</p>
                    {selectedGrantmakingProgramDataForDrawerView.ein && (
                        <div className="m-6"><strong>Employer Identification Number (EIN)</strong><br />{selectedGrantmakingProgramDataForDrawerView.ein}</div>
                    )}

                </div>
                <div className='flex flex-row justify-center bg-gray-100 p-6 rounded-lg'>
                    <p className='ml-6 italic text-xs text-gray-700'>
                        This RFP Profile was created using AI. Spot anything weird? <a href="https://tally.so/r/mDVa5j" className='underline' target="_blank" rel="noopener noreferrer">Report it here</a>.  
                    </p>
                </div>
                
            </div>
        ) : (
            <div className='p-24 text-center flex items-center justify-center text-gray-500'>
                Select an opportunity to view details
            </div>
        )
    );
};

export default DrawerOpportunityView;
