import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import posthog from 'posthog-js';

// Import functions
import {  fetchClientProgramsFromFirestore, fetchGrantDocumentsFromFirestoreForClientOrgId, fetchOpportunitiesForClientOrgId, fetchTasksFromFirestoreForClientOrgId, fetchFilesMetadataFromFirestore, fetchAssistantDetailsFromFirestoreForOrganization, fetchClientOrganizationsFromFirestore, fetchMembershipDetailsFromFirestoreForOrganization } from '../utilityFunctions/firestoreUtilities';
import { fetchFilesFromOpenAI } from '../utilityFunctions/openAiUtilities';
import { fetchProgramFitScoresFromSupabase, fetchFunderScoresFromSupabase } from '../utilityFunctions/supabaseUtilities';

// Import configs
import { AuthContext } from '../AuthContext';
import { auth } from '../config/firebaseConfig'; 

// Import components
import AwardsAdvancedSearch from './AwardsSearch/AwardsAdvancedSearch';
import OnboardingFlow from './HelperComponents/OnboardingFlow';
import LoadingModal from './HelperComponents/LoadingModal';
import PaywallComponent from './HelperComponents/PaywallComponent';
import SideNavBar from './SideNavBar';
import LoggedOutWelcome from './AuthComponents/LoggedOutWelcome';
import GenerateAiDocumentModal from './GrantWritingComponents/GenerateAiDocumentModal';
import CreateProgramModal from './CreateProgramModal';
import AccountSettings from './AccountSettings';
import UserSimulator from './HelperComponents/UserSimulator';
import MobileWelcome from './AuthComponents/MobileWelcome';
import OrganizationProfilePage from './OrganizationProfileComponents/OrganizationProfilePage';
import GrantDocumentEditor from './GrantWritingComponents/GrantDocumentEditor';
import GrantDocumentVersionHistoryModal from './GrantWritingComponents/GrantDocumentVersionHistoryModal';
import EditExcerptWithAiModal from './GrantWritingComponents/EditExcerptWithAiModal';
import HomeDashboard from './HomeDashboard/HomeDashboard';
import DocumentLibrary from './GrantWritingComponents/DocumentLibrary';
import DaisySettings from './DaisySettings';
import SelectedProgramView from './SelectedProgramViewComponents/SelectedProgramView';
import SaveOpportunityModal from './SelectedProgramViewComponents/SaveOpportunityModal';
import SelectedOpportunityModal from './SelectedOpportunityModal/SelectedOpportunityModal';
import CreateTaskModal from './TaskComponents/CreateTaskModal';
import TasksDashboard from './TaskComponents/TasksDashboard';
import ManageTaskModal from './TaskComponents/ManageTaskModal';
import HelpModal from './HomeDashboard/HelpModal';
import GrantDiscoveryOptionsModal from './HomeDashboard/GrantDiscoveryOptionsModal';
import ManageDocumentModal from './GrantWritingComponents/ManageDocumentModal';
// Utility function to detect mobile devices
const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

function Home() {

  const { authUser } = useContext(AuthContext);  
  const navigate = useNavigate();
  const { ein } = useParams();

  // Top level state variables
  const [currentView, setCurrentView] = useState('homeDashboard'); 
  const [membershipDetails, setMembershipDetails] = useState(null);
  const [clientOrganizationsBelongedTo, setClientOrganizationsBelongedTo] = useState([]);
  const [currentClientOrganization, setCurrentClientOrganization] = useState(null);
  const [organizationPrograms, setOrganizationPrograms] = useState([]);  
  const [activeChildViewOfSelectedProgramView, setActiveChildViewOfSelectedProgramView] = useState('opportunitiesTracker'); 
  
  // Assistant state variables
  const [assistantId, setAssistantId] = useState(null);
  const [assistantVectorStoreId, setAssistantVectorStoreId] = useState(null);
  const [assistantFiles, setAssistantFiles] = useState([])
  
  // Tasks state variables
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);

  // Grant organization state variables
  const [savedOpportunities, setSavedOpportunities] = useState([]);
  const [selectedSavedOpportunity, setSelectedSavedOpportunity] = useState(null)  
  
  // Grant discovery state variables
  const [selectedOrganizationEin, setSelectedOrganizationEin] = useState(ein); // ein as default value allows url routing to work for organization profile page
  const [currentProgram, setCurrentProgram] = useState(null)
  const [programToModify, setProgramToModify] = useState(null)
  const [grantmakingProgramIdForSaveOpportunityModalFromEntryPoint, setGrantmakingProgramIdForSaveOpportunityModalFromEntryPoint] = useState(null)
  const [funderEinForSaveOpportunityModalFromEntryPoint, setFunderEinForSaveOpportunityModalFromEntryPoint] = useState(null)
  const [funderNameForSaveOpportunityModalFromEntryPoint, setFunderNameForSaveOpportunityModalFromEntryPoint] = useState(null)
  
  // State variables for funder scores and gmp fit scores
  const [allGmpGspFitScoresForOrganization, setAllGmpGspFitScoresForOrganization] = useState([]);
  const [allFunderScoresForOrganization, setAllFunderScoresForOrganization] = useState([]);
  const [allGoodFitScoresWithActiveStatusForOrganization, setAllGoodFitScoresWithActiveStatusForOrganization] = useState([]);
  const [allFunderScoresWithActiveStatusForOrganization, setAllFunderScoresWithActiveStatusForOrganization] = useState([]);
  
  // Grant writing state variables
  const [currentGrantDocumentId, setCurrentGrantDocumentId] = useState(null);
  const [organizationDocuments, setOrganizationDocuments] = useState([]);
  const [highlightedTextForEditing, setHighlightedTextForEditing] = useState(null);
  const [generateAiDocumentModalDefaultValue, setGenerateAiDocumentModalDefaultValue] = useState(null);
  const [grantDocumentToManage, setGrantDocumentToManage] = useState(null);

    // Modal state variables
  const [showLoadingModal, setShowLoadingModal] = useState(false)
  const [showPaywallModal, setShowPaywallModal] = useState(false)
  const [showCreateProgramModal, setShowCreateProgramModal] = useState(false)
  const [showOnboardingFlow, setShowOnboardingFlow] = useState(null);
  const [showGenerateAiDocumentModal, setShowGenerateAiDocumentModal] = useState(false);
  const [showGrantDocumentVersionHistoryModal, setShowGrantDocumentVersionHistoryModal] = useState(false);
  const [showEditExcerptWithAiModal, setShowEditExcerptWithAiModal] = useState(false);
  const [showSaveOpportunityModal, setShowSaveOpportunityModal] = useState(false);
  const [showSelectedOpportunityModal, setShowSelectedOpportunityModal] = useState(false);
  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);
  const [showManageTaskModal, setShowManageTaskModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showGrantDiscoveryOptionsModal, setShowGrantDiscoveryOptionsModal] = useState(false);  
  const [showManageDocumentModal, setShowManageDocumentModal] = useState(false);

  // When authUser changes, get client organizations
  useEffect(() => {        
    if (authUser) { 
      if (authUser.uid) { 
        fetchClientOrganizationsBelongedTo(authUser.uid);
        try {
          posthog.identify(authUser.uid);
          posthog.capture('home_page_loaded');
          console.log("Successfully identified user: ", authUser.uid);
          console.log("Successfully captured home page loaded event");
        } catch (error) {
          console.error("Error identifying user: ", error);
        }
      }
    }
  }, [authUser]); 

  // Define current client organization
  useEffect(() => {
    if (clientOrganizationsBelongedTo.length > 0) {
      setCurrentClientOrganization(clientOrganizationsBelongedTo[0])  // TODO: Provide a way to select a client organization
    } else {
      if (authUser) {
        console.log("User found, but no client organizations found, showing onboarding flow");
        setShowOnboardingFlow(true);
      }
    }
  }, [clientOrganizationsBelongedTo]);

  // Once current client organization set, fetch programs, membership details, and refresh grant proposals
  useEffect(() => {
    if (currentClientOrganization) {
      fetchProgramsForOrganization(currentClientOrganization.id);
      fetchMembershipDetails()
      fetchAssistantDetails()
      refreshTasks()
      refreshSavedOpportunities()
      refreshOrganizationDocuments()      
    }
  }, [currentClientOrganization, assistantId]);
  
  // Define current program
  useEffect(() => {
    if (!currentProgram && organizationPrograms.length > 0) {
        setCurrentProgram(organizationPrograms[0]);
    }
  }, [currentProgram, organizationPrograms]);

  // When organization programs change, fetch all gmp fit scores and funder scores
  useEffect(() => {
    if (organizationPrograms.length > 0) {
      fetchAllGrantMakingProgramsFitScoresForOrganization();
      fetchAllFunderScoresForOrganization();
    }
  }, [organizationPrograms]);

  // When assistant vector store id changes, fetch assistant files
  useEffect(() => {
    if (assistantVectorStoreId) {
      fetchAssistantFiles(assistantVectorStoreId);
    }
  }, [assistantVectorStoreId]);

  // When funder scores change, filter out active scores to populate notification badges
  useEffect(() => {
    if (allFunderScoresForOrganization.length > 0) {
      const funderScoresWithActiveStatus = []
      
      for (const score of allFunderScoresForOrganization) {
        if (score.status === "active") {
          funderScoresWithActiveStatus.push(score);
        }
      }
      setAllFunderScoresWithActiveStatusForOrganization(funderScoresWithActiveStatus);
    }

  }, [allFunderScoresForOrganization]);

  // When gmp fit scores change, filter out active good fit scores to populate notification badges
  useEffect(() => {
    if (allGmpGspFitScoresForOrganization.length > 0) {
      const goodFitScoresWithActiveStatus = []
      
      for (const score of allGmpGspFitScoresForOrganization) {
        if (score.status === "active" && score.fit_category === "Good Fit") {
          goodFitScoresWithActiveStatus.push(score);
        }
      }
      setAllGoodFitScoresWithActiveStatusForOrganization(goodFitScoresWithActiveStatus);
    }

  }, [allGmpGspFitScoresForOrganization]);

  // If selectedOrganizationEin changes, show OrganizationProfilePage
  useEffect(() => {
    if (selectedOrganizationEin) {      
      setCurrentView('organizationProfilePage');
    }
  }, [selectedOrganizationEin]);

  // Update URL when selectedOrganizationEin changes. This is used to navigate to organization profile when URL contains an EIN (e.g. when user clicks on an organization in Advanced Awards Search)
  useEffect(() => {
    if (selectedOrganizationEin && selectedOrganizationEin !== ein) {
      navigate(`/profiles/organization/${selectedOrganizationEin}`);
      setCurrentView('organizationProfilePage');
    }
  }, [selectedOrganizationEin, navigate, ein]);

  // When a saved opportunity is selected, show the selected opportunity modal
  useEffect(() => {
    if (selectedSavedOpportunity) {
      setShowSelectedOpportunityModal(true)
    }
  }, [selectedSavedOpportunity]);

  // When saved opportunities is refreshed, update the selected saved opportunity
  // 3/6/25: what is purpose of this?
  useEffect(() => {
    const previousSelectedSavedOpportunityId = selectedSavedOpportunity?.id;
    if (savedOpportunities.length > 0) {
      const newSelectedSavedOpportunity = savedOpportunities.find(opportunity => opportunity.id === previousSelectedSavedOpportunityId);
      setSelectedSavedOpportunity(newSelectedSavedOpportunity);
    }
  }, [savedOpportunities]);

  // When a task is selected, show the manage task modal
  useEffect(() => {
    if (selectedTask) {
      setShowManageTaskModal(true)
    }
  }, [selectedTask]);

  // When save opportunity modal closes, reset the state variables
  useEffect(() => {
    if (!showSaveOpportunityModal) {
      setGrantmakingProgramIdForSaveOpportunityModalFromEntryPoint(null)
      setFunderEinForSaveOpportunityModalFromEntryPoint(null)
      setFunderNameForSaveOpportunityModalFromEntryPoint(null)
    }
  }, [showSaveOpportunityModal]);

  // If current grant document id changes, show the grant document editor modal
  useEffect(() => {
    if (currentGrantDocumentId) {
      setCurrentView('grantDocumentEditor')
    }
  }, [currentGrantDocumentId]);

  // Helper Functions
  
  async function fetchAssistantFiles(assistantVectorStoreId) {
    if (!assistantVectorStoreId) {
        console.log("assistantVectorStoreId is null, skipping fetch.");
        return;
    }

    try {
        const vectorStoreFiles = await fetchFilesFromOpenAI(assistantVectorStoreId);
        const vectorStoreFilesWithMetadata = await fetchFilesMetadataFromFirestore(vectorStoreFiles);            
        setAssistantFiles(vectorStoreFilesWithMetadata);
        console.log("Successfully fetched vector store files with metadata:", vectorStoreFilesWithMetadata);
    } catch (error) {
        console.error("Error fetching vector store files: ", error);
    }
  }
  
  const fetchProgramsForOrganization = async (organizationId) => {
    console.log("Fetching client programs for organization ID:", organizationId);
    const clientPrograms = await fetchClientProgramsFromFirestore(organizationId);
    console.log("Fetched client programs:", clientPrograms);
    setOrganizationPrograms(clientPrograms);

    // Show onboarding flow if no programs are found
    if (!clientPrograms || clientPrograms.length === 0) {
      if (currentClientOrganization) {
        console.log("Current client organization found, but no programs found, showing onboarding flow");
        setShowOnboardingFlow(false);
        setShowCreateProgramModal(true);
      } else {
        console.log("No client organization found, showing onboarding flow");
        setShowOnboardingFlow(true);
      }
    } else {
      console.log("Programs found for organization, hiding onboarding flow");
      setShowOnboardingFlow(false);
    }

    return clientPrograms;
  };

  const refreshTasks = async () => {
    if (!currentClientOrganization || !currentClientOrganization.id) {
        console.error("Current client organization is not set.");
        return;
    }
    const fetchedTasks = await fetchTasksFromFirestoreForClientOrgId(currentClientOrganization.id);
    console.log("fetchedTasks: ", fetchedTasks);
    setTasks(fetchedTasks);
  };

  const refreshSavedOpportunities = async () => {
    const fetchedSavedOpportunities = await fetchOpportunitiesForClientOrgId(currentClientOrganization.id);
    console.log("fetchedSavedOpportunities: ", fetchedSavedOpportunities);
    setSavedOpportunities(fetchedSavedOpportunities);
  };

  const refreshOrganizationDocuments = async () => {
    const fetchedOrganizationDocuments = await fetchGrantDocumentsFromFirestoreForClientOrgId(currentClientOrganization.id);
    console.log("fetchedOrganizationDocuments: ", fetchedOrganizationDocuments);
    setOrganizationDocuments(fetchedOrganizationDocuments);
  }

  // Main function to fetch clientOrganizations which user belongs to
  const fetchClientOrganizationsBelongedTo = async (uid) => {
    try {
      const clientOrganizations = await fetchClientOrganizationsFromFirestore(uid);
        setClientOrganizationsBelongedTo(clientOrganizations)
    } catch (error) {
        console.error("Error fetching client organizations: ", error);
    }
  };

  // Main function to fetch plan details
  const fetchMembershipDetails = async () => {
    if (!auth.currentUser || !currentClientOrganization) {
        console.error("No current user / current clientOrg found.");
        return;
    }
    try {
        const membershipDetails = await fetchMembershipDetailsFromFirestoreForOrganization(currentClientOrganization.id);
        console.log("membershipDetails: ", membershipDetails);
        setMembershipDetails(membershipDetails)
    } catch (error) {
        console.error("Error fetching membership details: ", error);
    }
  };

  const fetchAssistantDetails = async () => {
    const assistantDetails = await fetchAssistantDetailsFromFirestoreForOrganization(currentClientOrganization.id);
    
    if (assistantDetails) {
      setAssistantId(assistantDetails.assistantId);
      setAssistantVectorStoreId(assistantDetails.assistantVsId);
    } else {
      console.error("No assistant details found for the organization.");
    }
  }
  // Refresh funder scores for organization
  const refreshFunderScoresForOrganization = async () => {
    console.log("refreshFunderScoresForOrganization");
    setShowLoadingModal(true);
    await fetchAllFunderScoresForOrganization();
    setShowLoadingModal(false);
  }

  // Refresh gmp fit scores for organization
  const refreshGrantMakingPrograms = async () => {
    console.log("refreshGrantMakingPrograms");
    setShowLoadingModal(true);
    await fetchAllGrantMakingProgramsFitScoresForOrganization();
    setShowLoadingModal(false);
  } 

  // Fetch all funder scores for organization
  const fetchAllFunderScoresForOrganization = async () => {

    const allFunderScoresForOrganization = []
    for (const program of organizationPrograms) {
      const funderScores = await fetchFunderScoresFromSupabase(program.id);
      allFunderScoresForOrganization.push(...funderScores);
    }
    setAllFunderScoresForOrganization(allFunderScoresForOrganization);
  }

  // Fetch all gmp fit scores for organization
  const fetchAllGrantMakingProgramsFitScoresForOrganization = async () => {

    const allFitScoresForOrganization = []

    for (const program of organizationPrograms) {
      const fitScores = await fetchProgramFitScoresFromSupabase(program.id);
      allFitScoresForOrganization.push(...fitScores);
    }

    setAllGmpGspFitScoresForOrganization(allFitScoresForOrganization);
  }

  // Open save opportunity modal with known funder and grantmaking program
  const openSaveOpportunityModalWithKnownFunderAndOrGrantmakingProgram = (knownFunderEin = null, knownGrantmakingProgramId = null, knownFunderName = null) => {    
    console.log("openSaveOpportunityModalWithKnownFunderAndOrGrantmakingProgram called with: ", knownFunderEin, knownGrantmakingProgramId, knownFunderName);
    
    setGrantmakingProgramIdForSaveOpportunityModalFromEntryPoint(knownGrantmakingProgramId);
    setFunderEinForSaveOpportunityModalFromEntryPoint(knownFunderEin);
    setFunderNameForSaveOpportunityModalFromEntryPoint(knownFunderName);
    setShowSaveOpportunityModal(true);
  }
    
  const renderView = () => {
    // if (isMobileDevice()) {
    //   return <MobileWelcome />;
    // }

    switch (currentView) {
      case 'awardsAdvancedSearch':
        return <AwardsAdvancedSearch 
          setSelectedOrganizationEin={setSelectedOrganizationEin}
          setShowLoadingModal={setShowLoadingModal}
        />;

      case 'homeDashboard':
        return <HomeDashboard 
          setCurrentView={setCurrentView}
          setShowHelpModal={setShowHelpModal}
          setShowGrantDiscoveryOptionsModal={setShowGrantDiscoveryOptionsModal}
        />;
      
      case 'tasksDashboard':
        return <TasksDashboard 
          setShowCreateTaskModal={setShowCreateTaskModal}
          tasks={tasks}
          refreshTasks={refreshTasks}
          setSelectedTask={setSelectedTask}
          savedOpportunities={savedOpportunities}
          organizationPrograms={organizationPrograms}
          renderLocation={"home"}
          setSelectedSavedOpportunity={setSelectedSavedOpportunity}
          setShowSelectedOpportunityModal={setShowSelectedOpportunityModal}
          setCurrentView={setCurrentView}
          setCurrentProgram={setCurrentProgram}
          refreshOrganizationDocuments={refreshOrganizationDocuments}
        />;

      case 'documentLibrary':
        return <DocumentLibrary 
          setCurrentView={setCurrentView}
          setCurrentProgram={setCurrentProgram}
          setShowLoadingModal={setShowLoadingModal}
          currentClientOrganization={currentClientOrganization}
          setShowGenerateAiDocumentModal={setShowGenerateAiDocumentModal}          
          setGenerateAiDocumentModalDefaultValue={setGenerateAiDocumentModalDefaultValue}
          organizationPrograms={organizationPrograms}
          organizationDocuments={organizationDocuments}
          refreshOrganizationDocuments={refreshOrganizationDocuments}
          setCurrentGrantDocumentId={setCurrentGrantDocumentId}
          savedOpportunities={savedOpportunities}
          setSelectedSavedOpportunity={setSelectedSavedOpportunity}
          setShowManageDocumentModal={setShowManageDocumentModal}
          setGrantDocumentToManage={setGrantDocumentToManage}
          membershipDetails={membershipDetails}
          setShowPaywallModal={setShowPaywallModal}
        />;

      case 'daisySettings':
        return <DaisySettings
          assistantId={assistantId}
          assistantVectorStoreId={assistantVectorStoreId}
          setShowLoadingModal={setShowLoadingModal}
          fetchAssistantFiles={fetchAssistantFiles}
          currentClientOrganization={currentClientOrganization}
          assistantFiles={assistantFiles}
        />;

      case 'selectedProgramView':
        return <SelectedProgramView
          currentProgram={currentProgram}        
          setProgramToModify={setProgramToModify}
          setShowCreateProgramModal={setShowCreateProgramModal}  
          currentClientOrganization={currentClientOrganization}
          setShowSaveOpportunityModal={setShowSaveOpportunityModal}
          setShowSelectedOpportunityModal={setShowSelectedOpportunityModal}
          setSelectedSavedOpportunity={setSelectedSavedOpportunity}    
          setShowCreateTaskModal={setShowCreateTaskModal}
          tasks={tasks}
          refreshTasks={refreshTasks}
          setSelectedTask={setSelectedTask}
          savedOpportunities={savedOpportunities}
          organizationDocuments={organizationDocuments}
          setShowLoadingModal={setShowLoadingModal}
          refreshGrantMakingPrograms={refreshGrantMakingPrograms}
          allGmpGspFitScoresForOrganization={allGmpGspFitScoresForOrganization}
          allGoodFitScoresWithActiveStatusForOrganization={allGoodFitScoresWithActiveStatusForOrganization}
          allFunderScoresWithActiveStatusForOrganization={allFunderScoresWithActiveStatusForOrganization}
          allFunderScoresForOrganization={allFunderScoresForOrganization}
          refreshFunderScoresForOrganization={refreshFunderScoresForOrganization}
          setGrantmakingProgramIdForSaveOpportunityModalFromEntryPoint={setGrantmakingProgramIdForSaveOpportunityModalFromEntryPoint}
          setFunderEinForSaveOpportunityModalFromEntryPoint={setFunderEinForSaveOpportunityModalFromEntryPoint}  
          openSaveOpportunityModalWithKnownFunderAndOrGrantmakingProgram={openSaveOpportunityModalWithKnownFunderAndOrGrantmakingProgram}
          activeChildViewOfSelectedProgramView={activeChildViewOfSelectedProgramView} 
          setActiveChildViewOfSelectedProgramView={setActiveChildViewOfSelectedProgramView}
          fetchProgramsForOrganization={fetchProgramsForOrganization}
          membershipDetails={membershipDetails}
          setShowPaywallModal={setShowPaywallModal}
        />;
      
        case 'accountSettings':
        return <AccountSettings                
          currentClientOrganization={currentClientOrganization}
          membershipDetails={membershipDetails}
          assistantId={assistantId}
          assistantVectorStoreId={assistantVectorStoreId}
          assistantFiles={assistantFiles}
          setShowPaywallModal={setShowPaywallModal}
        />;
      case 'organizationProfilePage':
        return <OrganizationProfilePage
          selectedOrganizationEin={selectedOrganizationEin}
          setShowLoadingModal={setShowLoadingModal}
          openSaveOpportunityModalWithKnownFunderAndOrGrantmakingProgram={openSaveOpportunityModalWithKnownFunderAndOrGrantmakingProgram}
        />;

      case 'grantDocumentEditor':
        return <GrantDocumentEditor
          currentGrantDocumentId={currentGrantDocumentId}
          setCurrentGrantDocumentId={setCurrentGrantDocumentId}
          setShowGrantDocumentVersionHistoryModal={setShowGrantDocumentVersionHistoryModal}
          setCurrentView={setCurrentView}
          setShowEditExcerptWithAiModal={setShowEditExcerptWithAiModal}
          setHighlightedTextForEditing={setHighlightedTextForEditing}          
          membershipDetails={membershipDetails}
          setShowPaywallModal={setShowPaywallModal}
        />
    }
  };

  return (
  <div className="flex" style={{ height: '100vh', width: '100vw', overflow: 'hidden' }}>
    
    {/* Modals */}
    <div>          
      {/* {true && (
        <UserSimulator 
          fetchClientOrganizationsBelongedTo={fetchClientOrganizationsBelongedTo}   
          setCurrentProgram={setCurrentProgram}
          fetchProgramsForOrganization={fetchProgramsForOrganization}           
          refreshTasks={refreshTasks}
          refreshSavedOpportunities={refreshSavedOpportunities}
          refreshOrganizationDocuments={refreshOrganizationDocuments}
          organizationPrograms={organizationPrograms}
        />
      )} */}

      {showLoadingModal && (
        <LoadingModal              
        />
      )}

      {showPaywallModal && (
        <PaywallComponent
          onClose={() => setShowPaywallModal(false)}           
        />
      )}

      {showCreateProgramModal && (
        <CreateProgramModal         
          currentClientOrganization={currentClientOrganization} 
          existingProgram={programToModify}
          fetchProgramsForOrganization={fetchProgramsForOrganization}
          setCurrentProgram={setCurrentProgram}
          setShowCreateProgramModal={setShowCreateProgramModal}
          refreshTasks={refreshTasks}
          refreshSavedOpportunities={refreshSavedOpportunities}
          refreshOrganizationDocuments={refreshOrganizationDocuments}                                 
          organizationPrograms={organizationPrograms}
        />
      )}

      {showHelpModal && (
        <HelpModal
          setShowHelpModal={setShowHelpModal}
        />
      )}

      {showGrantDiscoveryOptionsModal && (
        <GrantDiscoveryOptionsModal
          setShowGrantDiscoveryOptionsModal={setShowGrantDiscoveryOptionsModal}
          setCurrentView={setCurrentView}
          setActiveChildViewOfSelectedProgramView={setActiveChildViewOfSelectedProgramView}
          membershipDetails={membershipDetails}
          setShowPaywallModal={setShowPaywallModal}
        />
      )}

      {showEditExcerptWithAiModal && (
        <EditExcerptWithAiModal
          setShowEditExcerptWithAiModal={setShowEditExcerptWithAiModal}
          highlightedTextForEditing={highlightedTextForEditing}
          setShowLoadingModal={setShowLoadingModal}
        />
      )}

      {showGrantDocumentVersionHistoryModal && (
        <GrantDocumentVersionHistoryModal
          grantDocumentId={currentGrantDocumentId}
          setShowVersionHistoryModal={setShowGrantDocumentVersionHistoryModal}
        />
      )}

      {showGenerateAiDocumentModal && (
        <GenerateAiDocumentModal
          setShowGenerateAiDocumentModal={setShowGenerateAiDocumentModal}              
          currentProgram={currentProgram}
          setShowLoadingModal={setShowLoadingModal}
          assistantId={assistantId}
          assistantVectorStoreId={assistantVectorStoreId}
          assistantFiles={assistantFiles}
          currentClientOrganization={currentClientOrganization}
          setCurrentGrantDocumentId={setCurrentGrantDocumentId}
          setCurrentView={setCurrentView}
          generateAiDocumentModalDefaultValue={generateAiDocumentModalDefaultValue}
          refreshOrganizationDocuments={refreshOrganizationDocuments}
          setShowSelectedOpportunityModal={setShowSelectedOpportunityModal}
          organizationPrograms={organizationPrograms}
          savedOpportunities={savedOpportunities}
        />
      )}

      {showSaveOpportunityModal && (
        <SaveOpportunityModal
          setShowSaveOpportunityModal={setShowSaveOpportunityModal}
          organizationPrograms={organizationPrograms}
          currentProgram={currentProgram}              
          currentClientOrganization={currentClientOrganization}
          setShowLoadingModal={setShowLoadingModal}
          grantmakingProgramIdForSaveOpportunityModalFromEntryPoint={grantmakingProgramIdForSaveOpportunityModalFromEntryPoint}
          funderEinForSaveOpportunityModalFromEntryPoint={funderEinForSaveOpportunityModalFromEntryPoint}
          funderNameForSaveOpportunityModalFromEntryPoint={funderNameForSaveOpportunityModalFromEntryPoint}
          refreshSavedOpportunities={refreshSavedOpportunities}
        />
      )}

      {showSelectedOpportunityModal && (
        <SelectedOpportunityModal
          setShowSelectedOpportunityModal={setShowSelectedOpportunityModal}
          selectedSavedOpportunity={selectedSavedOpportunity}
          setSelectedSavedOpportunity={setSelectedSavedOpportunity}
          setSelectedTask={setSelectedTask}
          setShowManageTaskModal={setShowManageTaskModal}
          tasks={tasks}
          organizationPrograms={organizationPrograms}
          setShowLoadingModal={setShowLoadingModal}
          showSelectedOpportunityModal={showSelectedOpportunityModal}
          organizationDocuments={organizationDocuments}
          setShowCreateTaskModal={setShowCreateTaskModal}
          setShowGenerateAiDocumentModal={setShowGenerateAiDocumentModal}
          setCurrentGrantDocumentId={setCurrentGrantDocumentId}
          refreshSavedOpportunities={refreshSavedOpportunities}
          refreshTasks={refreshTasks}
          refreshOrganizationDocuments={refreshOrganizationDocuments}
          membershipDetails={membershipDetails}
          setShowPaywallModal={setShowPaywallModal}
        />
      )}

      {showCreateTaskModal && (
        <CreateTaskModal
          setShowCreateTaskModal={setShowCreateTaskModal}
          selectedSavedOpportunity={selectedSavedOpportunity}
          organizationPrograms={organizationPrograms}
          currentClientOrganization={currentClientOrganization}
          refreshTasks={refreshTasks}
          savedOpportunities={savedOpportunities}
          currentProgram={currentProgram}
        />
      )}

      {showManageTaskModal && (
        <ManageTaskModal
          setShowManageTaskModal={setShowManageTaskModal}
          selectedTask={selectedTask}
          organizationPrograms={organizationPrograms}
          currentClientOrganization={currentClientOrganization}
          refreshTasks={refreshTasks}
          savedOpportunities={savedOpportunities}
          setSelectedTask={setSelectedTask}
        />
      )}

      {showManageDocumentModal && (
        <ManageDocumentModal
          setShowManageDocumentModal={setShowManageDocumentModal}
          organizationPrograms={organizationPrograms}
          currentClientOrganization={currentClientOrganization}
          refreshDocuments={refreshOrganizationDocuments}
          savedOpportunities={savedOpportunities}
          grantDocumentToManage={grantDocumentToManage}
          setGrantDocumentToManage={setGrantDocumentToManage}
        />
      )}

    </div>           
    <SideNavBar 
        currentProgram={currentProgram}
        currentView={currentView}
        membershipDetails={membershipDetails}
        organizationPrograms={organizationPrograms}
        setCurrentProgram={setCurrentProgram}
        setCurrentView={setCurrentView}                         
        setProgramToModify={setProgramToModify}
        setShowCreateProgramModal={setShowCreateProgramModal}
        setShowPaywallModal={setShowPaywallModal}
        allGoodFitScoresWithActiveStatusForOrganization={allGoodFitScoresWithActiveStatusForOrganization}
        allFunderScoresWithActiveStatusForOrganization={allFunderScoresWithActiveStatusForOrganization}
        setSelectedOrganizationEin={setSelectedOrganizationEin}
        showOnboardingFlow={showOnboardingFlow}
        setShowOnboardingFlow={setShowOnboardingFlow}
    />
    <div className='w-full h-full overflow-y-auto overflow-x-auto'>
    {/* <div style={{ flex: 1, overflowY: 'auto' }}> */}
      {authUser ? (                            
          showOnboardingFlow ? (
            // true ? (
              <OnboardingFlow 
                fetchClientOrganizationsBelongedTo={fetchClientOrganizationsBelongedTo}
                fetchProgramsForOrganization={fetchProgramsForOrganization}                             
                setShowLoadingModal={setShowLoadingModal}
              />
            ) : (
            // App functionality
          <div>
            <div className='md:hidden p-12'>
              <button 
                className='w-full text-sm py-3 text-left px-2 rounded-lg hover:bg-gray-200' 
                onClick={() => {
                  navigate('/');
                  setCurrentView('homeDashboard');
                }}
              >
                Return Home
              </button>
              <p className='text-sm text-left text-gray-700 mt-2'>Mobile view is not supported at this time. For the best experience, please use a desktop browser.</p>
            </div>
            {renderView()}
          </div>
        )
      ) : (
        <LoggedOutWelcome></LoggedOutWelcome>
      )}
    </div>
  </div>
  );
}

export default Home;