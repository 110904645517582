import React, {useState} from 'react';
import nteeCodes from "../../assets/ntee_codes.json";

export const NteeDropdown = ({onChange, code, formDataName = 'nteeFull', children}) => {
    const [showNteeList, setShowNteeList] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const nteeData = code ? `${code} - ${nteeCodes.find(({ntee_code}) => ntee_code === code)?.description}` : 'Select NTEE Code'

    const onClick = (field, val) => {
        onChange(field, val)

        setShowNteeList(false)
    }
    
    return (
        <>
            <div>
                {children}
            </div>

            <div className="relative mb-4">
                <div
                    className="w-full p-2 border rounded cursor-pointer bg-white"
                    onClick={() => setShowNteeList(prev => !prev)} // Toggle the visibility of the list
                >
                    {nteeData}
                </div>
                
                {showNteeList && ( // Conditional rendering of the list
                    <div className="absolute z-10 w-full bg-white border rounded shadow-lg">
                        {/* <p className="text-xs mb-2 pl-2 text-left italic text-gray-500">Search</p> */}
                        <input
                            required
                            type="text"
                            placeholder="Search NTEE Code..."
                            className="w-full p-2 border border-gray-300 rounded mb-2"
                            onChange={(e) => setSearchQuery(e.target.value)} // Update search query
                        />
                        {/* <p className="text-xs mb-2 pl-2 text-left italic text-gray-500">Results</p> */}
                        <ul className="max-h-60 overflow-y-auto">
                            {nteeCodes.filter(code =>
                                code.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
                                code.ntee_code.toLowerCase().includes(searchQuery.toLowerCase())
                            ).map((code) => (
                                <li
                                    key={code.ntee_code}
                                    className="p-2 hover:bg-gray-200 cursor-pointer"
                                    onClick={() => onClick(formDataName, code.ntee_code)}
                                >
                                    <span className="font-bold">{code.description} ({code.ntee_code})</span>
                                    <span className="text-xs block">{code.definition}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </>
    )
}
