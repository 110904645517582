import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { fetchAwardsReceivedFromSupabase, fetchAwardsGrantedFromSupabase, fetchLatest990FromSupabase } from '../../utilityFunctions/supabaseUtilities';
// import '../OrganizationProfile.css';
import FunderRenderer from '../HelperComponents/FunderRenderer';
import RecipientRenderer from '../HelperComponents/RecipientRenderer';
import FunderHighlights from '../HelperComponents/FunderHighlights'; // Import the new Highlights component

const DrawerFunderView = ({        
    // selectedFunderEin, // is this redundant with funderEinForDrawerView?
    setShowLoadingModal,
    funderEinForDrawerView, // is this redundant with funderEinForDrawerView?
    grantmakingProgramIdForDrawerView,
    selectedGrantmakingProgramDataForDrawerView,
    selectedDrawerView,    
    openSaveOpportunityModalWithKnownFunderAndOrGrantmakingProgram,
    showSelectedOpportunityModal,
    currentProgram,
    setProgramToModify,
    setShowCreateProgramModal
}) => {
    
    // State variables with organization details
    const [latest990, setLatest990] = useState(null)
    const [selectedOrganizationName, setSelectedOrganizationName] = useState(null);
    const [description, setDescription] = useState('');
    const [awardsReceived, setAwardsReceived] = useState([]);
    const [awardsGranted, setAwardsGranted] = useState([]);        

    const fetchLatest990ForFunderEin = async (funderEin) => {
        setShowLoadingModal(true);
        const fetched990 = await fetchLatest990FromSupabase(funderEin)
        console.log("Latest 990: ", fetched990)
        setLatest990(fetched990)
        setShowLoadingModal(false);
    }

    useEffect(() => {
        console.log("funderEinForDrawerView change triggered in DFW: ", funderEinForDrawerView)
    }, [funderEinForDrawerView])

    useEffect(() => {
        if (funderEinForDrawerView) {
            fetchLatest990ForFunderEin(funderEinForDrawerView)
        } else if (selectedGrantmakingProgramDataForDrawerView) {
            fetchLatest990ForFunderEin(selectedGrantmakingProgramDataForDrawerView.ein)
        }
    }, [funderEinForDrawerView, grantmakingProgramIdForDrawerView, selectedDrawerView])

    // Fetch organization details from Supabase
    useEffect(() => {
        if (latest990) {
            // setShowLoadingModal(true)

            Promise.all([
                fetchAwardsReceivedFromSupabase(latest990.ein),
                fetchAwardsGrantedFromSupabase(latest990.ein)
            ]).then(([awardsReceived, awardsGranted]) => {
                setAwardsReceived(awardsReceived);
                setAwardsGranted(awardsGranted);
                // setShowLoadingModal(false);
            }).catch(error => {
                console.error("Error loading data:", error);
                // setShowLoadingModal(false);
            });
        }
    }, [latest990]);

    // Fetch organization description from Supabase
    useEffect(() => {
        if (latest990) {
            const descriptions = [
                latest990.activity_or_mission_desc,
                latest990.desc,
                latest990.mission_desc
            ].filter(Boolean); // Filter out undefined or null values

            const longestDescription = descriptions.reduce((longest, current) =>
                current.length > longest.length ? current : longest, "");

            setDescription(longestDescription);
        }
    }, [latest990]);


    // If no Firestore object, then populate profile page with info from grant awards
    useEffect(() => {
        if (latest990?.business_name) {
            setSelectedOrganizationName(latest990.business_name);
        } else if (awardsReceived.length > 0) {
            const recipientName = awardsReceived[0].recipient_name;
            setSelectedOrganizationName(recipientName);
        }
    }, [latest990]);

    console.log("funderEinForDrawerView: ", funderEinForDrawerView)
    // console.log("selectedFunderEin: ", selectedFunderEin)
    
    
    // Define the column definitions for awardsReceived
    const awardsReceivedColumnDefs = [
        { 
            headerName: "Tax Year", 
            field: "tax_year",
            width:100
        },
        { 
            headerName: "Funder Name", 
            field: "funder_name",
            cellRenderer: FunderRenderer
        },
        { 
            headerName: "Recipient Name", 
            field: "recipient_name",
            filter: "agTextColumnFilter",
            cellRenderer: RecipientRenderer
        },
        { 
            headerName: "Amount", 
            field: "cash_grant_amt", 
            valueGetter: ({ data }) => data.cash_grant_amt ? parseFloat(data.cash_grant_amt) : 0, // Ensure sorting uses numeric values
            valueFormatter: ({ value }) => `$${value.toLocaleString()}`,
            filter: "agNumberColumnFilter"
        },
        { 
            headerName: "Purpose", 
            field: "purpose",
            filter: "agTextColumnFilter",            
        },
        { 
            headerName: "Recipient NTEE", 
            field: "ntee_codes.ntee_full",   
            width: 100         
        },
        { 
            headerName: "Recipient City", 
            field: "city_name",
            filter: "agTextColumnFilter",            
        },
        { 
            headerName: "Recipient State", 
            field: "state_abbreviation_cd",
            filter: "agTextColumnFilter",            
        },
        { 
            headerName: "Recipient ZIP", 
            field: "zip_code",
            filter: "agTextColumnFilter",            
        },

    ]



    return (
        <div className='text-left p-12 text-sm overflow-y-auto' style={{ maxHeight: '80vh' }}>

            {/* {(!funderEinForDrawerView && !selectedFunderEin) ? ( */}
            {(!funderEinForDrawerView) ? (            
                <div className="p-24 text-center flex items-center justify-center text-gray-500">
                    <p>Select a funder to view details</p>
                </div>
            ) : (
                // Known Funder View
                <div>
                    <div className='flex justify-between items-center'>
                        {selectedOrganizationName ? <p className='text-4xl font-bold'>{selectedOrganizationName.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}</p> : <p className='text-4xl font-bold'>Unknown Funder</p>}
                        <div className='flex space-x-2'>
                            {/* <button className='bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded' onClick={() => {
                                navigator.clipboard.writeText(window.location.href);
                                alert('URL copied to clipboard');
                            }}>Copy URL</button> */}
                            {!showSelectedOpportunityModal && (
                                <button 
                                    className='bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded' 
                                    onClick={() => openSaveOpportunityModalWithKnownFunderAndOrGrantmakingProgram(funderEinForDrawerView, grantmakingProgramIdForDrawerView, selectedOrganizationName)}
                                >
                                    Save to Tracker
                                </button>
                            )}
                        </div>
                    </div>
                    
                    <div className="mt-3 flex justify-start items-center space-x-4">
                        {awardsReceived.length > 0 && <div className="bg-green-200 text-green-900 p-2 rounded-lg text-sm">Grant Winner</div>}
                        {awardsGranted.length > 0 && <div className="bg-blue-200 text-blue-800 p-2 rounded-lg text-sm">Grant Maker</div>}

                    </div>

                    {/* in state giving callout */}
                    {awardsGranted.length > 0 && (
                        <FunderHighlights 
                            awardsGranted={awardsGranted} 
                            currentProgram={currentProgram} 
                            setProgramToModify={setProgramToModify} 
                            setShowCreateProgramModal={setShowCreateProgramModal} 
                        />
                    )}

                    <h3 className="orgDetailsLabel">General Details</h3>
                    <div className="bg-gray-100 p-4 rounded-md mb-4">
                        <table className="w-full border-collapse">
                            <tbody>
                                {latest990?.website_address_txt && (
                                    <tr className="border-b border-gray-300">
                                        <td className="py-2 px-4">Website</td>
                                        <td className="text-right py-2 px-4">
                                            <a href={`http://${latest990.website_address_txt}`} target="_blank" rel="noopener noreferrer" className="underline">{latest990.website_address_txt}</a>
                                        </td>
                                    </tr>
                                )}
                                {latest990?.address_line_1_text && latest990?.city_name && latest990?.state_abbreviation_cd && latest990?.zip_code && (
                                    <tr className="border-b border-gray-300">
                                        <td className="py-2 px-4">Address</td>
                                        <td className="text-right py-2 px-4">
                                            {`${latest990.address_line_1_text}, ${latest990.city_name}, ${latest990.state_abbreviation_cd} ${latest990.zip_code}`}
                                        </td>
                                    </tr>
                                )}
                                {latest990?.phone_number && (
                                    <tr className="border-b border-gray-300">
                                        <td className="py-2 px-4">Phone Number</td>
                                        <td className="text-right py-2 px-4">
                                            {latest990?.phone_number?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}
                                        </td>
                                    </tr>
                                )}                  
                                <tr className="border-b border-gray-300">
                                    <td className="py-2 px-4">EIN</td>
                                    <td className="text-right py-2 px-4">
                                        {latest990?.ein}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                    {description && (
                        <>
                            <h3 className="orgDetailsLabel">Mission / Description</h3>
                            <div className="bg-gray-100 p-4 rounded-md mb-4">
                                <table className="w-full border-collapse">
                                    <tbody>
                                        <tr className="border-b border-gray-300">
                                            <td className="py-2 px-4">Description</td>
                                            <td className="text-right py-2 px-4">{description}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}

                    {(
                        latest990?.total_assets_boy_amt != null ||
                        latest990?.total_assets_eoy_amt != null ||
                        latest990?.cy_total_revenue_amt != null ||
                        latest990?.cy_grants_and_similar_paid_amt != null
                    ) && (
                        <>
                            <h3 className="orgDetailsLabel">Financial Details</h3>
                            <div className="bg-gray-100 p-4 rounded-md mb-4">
                                <table className="w-full border-collapse">
                                    <tbody>
                                        {latest990?.total_assets_boy_amt != null && (
                                            <tr className="border-b border-gray-300">
                                                <td className="py-2 px-4">Total Assets (Beginning of Year)</td>
                                                <td className="text-right py-2 px-4">${latest990.total_assets_boy_amt.toLocaleString()}</td>
                                            </tr>
                                        )}
                                        {latest990?.total_assets_eoy_amt != null && (
                                            <tr className="border-b border-gray-300">
                                                <td className="py-2 px-4">Total Assets (End of Year)</td>
                                                <td className="text-right py-2 px-4">${latest990.total_assets_eoy_amt.toLocaleString()}</td>
                                            </tr>
                                        )}
                                        {latest990?.total_assets_boy_amt != null && latest990?.total_assets_eoy_amt != null && (
                                            <tr className="border-b border-gray-300">
                                                <td className="py-2 px-4">Growth Rate in Assets (YoY)</td>
                                                <td className="text-right py-2 px-4">
                                                    {`${((latest990.total_assets_eoy_amt - latest990.total_assets_boy_amt) / latest990.total_assets_boy_amt * 100).toFixed(2)}%`}
                                                </td>
                                            </tr>
                                        )}
                                        {latest990?.cy_total_revenue_amt != null && (
                                            <tr className="border-b border-gray-300">
                                                <td className="py-2 px-4">Latest Revenue</td>
                                                <td className="text-right py-2 px-4">${latest990.cy_total_revenue_amt.toLocaleString()}</td>
                                            </tr>
                                        )}
                                        {latest990?.cy_grants_and_similar_paid_amt != null && (
                                            <tr className="border-b border-gray-300">
                                                <td className="py-2 px-4">Latest Expenses</td>
                                                <td className="text-right py-2 px-4">${latest990.cy_grants_and_similar_paid_amt.toLocaleString()}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}

                    {awardsGranted.length > 0 && (
                        <>
                            <h3 className="orgDetailsLabel">Giving History</h3>
                            <div className="bg-gray-100 p-4 rounded-md mb-4">
                                <table className="w-full border-collapse">
                                    <tbody>                       
                                        <tr className="border-b border-gray-300">
                                            <td className="py-2 px-4">Grants Given ($)</td>                                    
                                            <td className="text-right py-2 px-4">
                                                ${awardsGranted.reduce((sum, award) => sum + parseFloat(award.cash_grant_amt || 0), 0).toLocaleString()}
                                            </td>
                                        </tr>
                                        <tr className="border-b border-gray-300">
                                            <td className="py-2 px-4">Grants Given (#)</td>
                                            <td className="text-right py-2 px-4">
                                                {awardsGranted.length.toLocaleString()}
                                            </td>
                                        </tr>
                                        <tr className="border-b border-gray-300">
                                            <td className="py-2 px-4">Average Grant Size</td>
                                            <td className="text-right py-2 px-4">
                                                ${(Math.round(awardsGranted.reduce((sum, award) => sum + parseFloat(award.cash_grant_amt || 0), 0) / awardsGranted.length)).toLocaleString()}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                    <h3 className="orgDetailsLabel">Awards Granted ({awardsGranted.length === 1000 ? '1,000+' : awardsGranted.length.toLocaleString()})</h3>
                    {awardsGranted.length > 0 ? (
                        <div className="ag-theme-alpine" style={{ height: 300, width: '100%' }}>
                            <AgGridReact
                                rowData={awardsGranted}
                                columnDefs={awardsReceivedColumnDefs}
                            />
                        </div>
                    ) : (
                        <p>No awards granted available.</p>
                    )}

                    <h3 className="orgDetailsLabel">Awards Received ({awardsReceived.length})</h3>
                    {awardsReceived.length > 0 ? (
                        <div className="ag-theme-alpine" style={{ height: 300, width: '100%' }}>
                            <AgGridReact
                                rowData={awardsReceived}
                                columnDefs={awardsReceivedColumnDefs}
                            />
                        </div>
                    ) : (
                        <p>No awards received available.</p>
                    )}

                    

                </div>            
            )}
        </div>
    );
};

export default DrawerFunderView;