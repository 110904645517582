import React, {useContext, useState} from 'react';
import { createClientOrganizationInFirestore, createProgramInFirestore, createGoalInFirestore, saveAssistantToFirestore } from '../../utilityFunctions/firestoreUtilities';
import { createVectorStore, createAssistantInOpenAi,  } from '../../utilityFunctions/openAiUtilities';
import { AuthContext } from '../../AuthContext';
import { cleanText } from '../../utilityFunctions/uxUtilities';
import {NteeDropdown} from "./NteeDropdown";
// import NteeData from './NteeData';

function OnboardingFlow({ 
    fetchClientOrganizationsBelongedTo,
    fetchProgramsForOrganization,
    setShowLoadingModal
}) {
    const { authUser } = useContext(AuthContext);
    const [nteeFull, setNteeFull] = useState('');
    // const [selectedNteeCode, setSelectedNteeCode] = useState(null);

    const states = [
        "AK", "AL", "AR", "AZ", "CA", "CO", "CT", "DE", "FL", "GA",
        "HI", "IA", "ID", "IL", "IN", "KS", "KY", "LA", "MA", "MD",
        "ME", "MI", "MN", "MO", "MS", "MT", "NC", "ND", "NE", "NH",
        "NJ", "NM", "NV", "NY", "OH", "OK", "OR", "PA", "RI", "SC",
        "SD", "TN", "TX", "UT", "VA", "VT", "WA", "WI", "WV", "WY"
    ];

    const handleCreateOrganizationButtonPressed = async (event) => {
        event.preventDefault();
        console.log("Form submission started");

        // Clean input values
        const organizationName = cleanText(event.target.organizationName?.value || '');
        
        const programName = cleanText(event.target.programName?.value || '');
        const primaryState = cleanText(event.target.primaryState?.value || '');
        const areasServed = cleanText(event.target.areasServed?.value || '');
        const populationServed = cleanText(event.target.populationServed?.value || '');
        const servicesProvided = cleanText(event.target.servicesProvided?.value || '');

        if (!nteeFull) {
            alert('NTEE code is required!')
        }
        
        if (!organizationName || !programName || !primaryState || !areasServed || !nteeFull || !populationServed || !servicesProvided) {
            console.error("Missing required fields");
            return;
        }

        console.log("Input values cleaned:", {
            organizationName,
            programName,
            primaryState,
            areasServed,
            nteeFull,
            populationServed,
            servicesProvided,            
        });

        try {
            setShowLoadingModal(true);
            console.log("Loading modal set to true");

            const createdClientOrganizationRef = await createClientOrganizationInFirestore(organizationName, authUser.uid);
            const createdClientOrganizationId = createdClientOrganizationRef.id; 
            console.log("Client organization created with ID:", createdClientOrganizationId);

            try {
                const createProgramWithTimeout = new Promise((resolve, reject) => {
                    const timeout = setTimeout(() => {
                        reject(new Error("Timeout: Failed to create program in Firestore within 60 seconds"));
                    }, 60000);

                    console.log("Attempting to create program in Firestore with the following data:", {
                        overview: servicesProvided,
                        populationsServed: populationServed,
                        areasServed: areasServed,
                        primaryState: primaryState,
                        nteeFull: nteeFull,
                        programName: programName
                    });

                    createProgramInFirestore({
                        overview: servicesProvided,
                        populationsServed: populationServed,
                        areasServed: areasServed,
                        primaryState: primaryState,
                        nteeFull: nteeFull,
                        programName: programName
                    }, authUser.uid, createdClientOrganizationId, programName)
                        .then((result) => {
                            clearTimeout(timeout);
                            console.log("Program created successfully:", result);
                            resolve(result);
                        })
                        .catch((error) => {
                            clearTimeout(timeout);
                            console.error("Error creating program in Firestore:", error);
                            reject(error);
                        });
                });

                await createProgramWithTimeout;
                
                const createdVectorStore = await createVectorStore();
                console.log("Created vector store:", createdVectorStore);
                const createdAssistant = await createAssistantInOpenAi(organizationName, createdVectorStore.id);
                console.log("Created assistant in OpenAI:", createdAssistant);

                await saveAssistantToFirestore(createdAssistant, createdVectorStore.id, createdClientOrganizationId);
                console.log("Saved assistant to Firestore:", createdAssistant);
            } catch (error) {
                console.error("Failed to create program in Firestore:", error);
            }

            setShowLoadingModal(false);
            console.log("Loading modal set to false");
            fetchClientOrganizationsBelongedTo(authUser.uid);
            console.log("Fetched client organizations for user:", authUser.uid);
            fetchProgramsForOrganization(createdClientOrganizationId);
            console.log("Fetched programs for organization ID:", createdClientOrganizationId);
            
        } catch (error) {
            console.error("Error during organization creation process:", error);        
        }
    };

    const renderStepContent = () => (
        <div className="space-y-8 w-full">
            <div className="w-full bg-orange-100 p-8">
                <p className="text-lg font-bold text-gray-600">Tell us about your nonprofit</p>
                <p className="text-sm mb-8 italic text-gray-600">Daisy will use this information to assess if grant opportunities are a good fit for your program.</p>
                <div>
                    <label htmlFor="organizationName" className="block mb-2 text-sm font-medium text-gray-900">What is the name of your nonprofit?<span className="text-red-500"> *</span></label>                        
                    <input type="text" id="organizationName" name="organizationName" placeholder='Public Housing Community Fund' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                </div>
            </div>
            <div className="w-full bg-orange-100 p-8">
                <p className="text-lg font-bold text-gray-600">Tell us about one of your programs</p>
                <p className="text-sm mb-8 italic text-gray-600">Daisy will use this information to assess if grant opportunities are a good fit for your program.</p>
                <div>
                    <label htmlFor="programName" className="block mb-2 text-sm font-medium text-gray-900">What is the name of the program you want grant funding for?<span className="text-red-500"> *</span></label>
                    <input type="text" id="programName" name="programName" placeholder='Program Name' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                </div>
                <div>
                    <label htmlFor="primaryState" className="block mb-2 mt-10 text-sm font-medium text-gray-900">
                        What state does this program primarily serve?<span className="text-red-500"> *</span>
                    </label>
                    <select id="primaryState" name="primaryState" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required>
                        <option value="">Select a state</option>
                        {states.map((state) => (
                            <option key={state} value={state}>{state}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="areasServed" className="block mb-2 mt-10 text-sm font-medium text-gray-900">Does your program primarily focus on any specific parts of this state?<span className="text-red-500"> *</span></label>
                    <input type="text" id="areasServed" name="areasServed" placeholder='Additional geographic details' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                </div>
                <div>
                    <NteeDropdown onChange={(_, code) => setNteeFull(code)} code={nteeFull}>
                        <label htmlFor="nteeFull" className="block mb-2 mt-10 text-sm font-medium text-gray-900">What is this program's NTEE code?<span className="text-red-500"> *</span></label>
                        <p className="text-sm mb-2 italic text-gray-600">If you don't know your NTEE code, you can find it <a href="https://urbaninstitute.github.io/nccs-legacy/ntee/ntee.html" target="_blank" rel="noopener noreferrer" className="text-indigo-500">here</a>.</p>
                    </NteeDropdown>
                </div>
                <div>
                    <label htmlFor="populationServed" className="block mb-2 mt-10 text-sm font-medium text-gray-900">Write a few details about the populations this program serves<span className="text-red-500"> *</span></label>
                    <input type="text" id="populationServed" name="populationServed" placeholder='Population / Community' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                </div>
                <div>
                    <label htmlFor="servicesProvided" className="block mb-2 mt-10 text-sm font-medium text-gray-900">Write a few details about the services this program provides<span className="text-red-500"> *</span></label>
                    <textarea id="servicesProvided" name="servicesProvided" placeholder='Services / Work' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required></textarea>
                </div>
                {/* <NteeData setSelectedNteeCode={setSelectedNteeCode} /> */}
            </div>
        </div>
    );

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        
        // Ensure the event target is a form element with a value property
        // if (typeof value === 'undefined') {
        //     return;
        // }

        // if (['organizationName', 'websiteLink', 'headquarterState', 'happyFunding'].includes(name)) {
        //     validateStep1(event);
        // } else if (['programName', 'geographicAreas', 'populationServed', 'servicesProvided'].includes(name)) {
        //     validateStep2(event);
        // }
    };

    return (
        <div className="flex justify-start items-start w-full h-full p-12 md:p-24 text-left bg-gray-100 bg-opacity-100 overflow-y-auto">
            <form onSubmit={handleCreateOrganizationButtonPressed} onChange={handleInputChange} className="flex flex-wrap">
                <h1 className="text-3xl font-bold w-full">Welcome to GrantMagic!</h1>
                <p className="text-md mt-3 mb-10 text-gray-600 w-full">Answer eight questions about your nonprofit. Daisy will learn from your responses to help discover and edit grants for your nonprofit.</p>
                
                {renderStepContent()}

                <div className="flex space-x-4 w-full mt-8">
                    <button
                        type="submit"
                        className="bg-indigo-600 hover:bg-indigo-800 text-white font-bold py-2 px-4 rounded"
                        // disabled={!selectedNteeCode}
                    >
                        Create
                    </button>
                </div>
            </form>
        </div>
    );
}

export default OnboardingFlow;
