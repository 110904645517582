import React, { useState, useEffect } from 'react';
import OpportunityAndFunderDrawer from '../OpportunityAndFunderDrawer/OpportunityAndFunderDrawer';
import { updateStatusOfFunderScoreInSupabase } from '../../utilityFunctions/supabaseUtilities';

function PotentialFundersTab({
    currentProgram,    
    setShowLoadingModal,
    allFunderScoresForOrganization,
    refreshFunderScoresForOrganization,
    openSaveOpportunityModalWithKnownFunderAndOrGrantmakingProgram,
    funderEinForDrawerView,
    setFunderEinForDrawerView,
    membershipDetails,
    setProgramToModify,
    setShowCreateProgramModal
}) {    
    
    const [selectedDrawerView, setSelectedDrawerView] = useState('funder');
    const [goodFitFundersRowData, setGoodFitFundersRowData] = useState([]);
    const [archivedFundersRowData, setArchivedFundersRowData] = useState([]);
    const [savedFundersRowData, setSavedFundersRowData] = useState([]);    
    const [rowData, setRowData] = useState([goodFitFundersRowData]);
    const [tableViewMode, setTableViewMode] = useState("goodFits");
    
    // const [funderEinForDrawerView, setFunderEinForDrawerView] = useState(null);

    useEffect(() => {    
        // Filter out scores that are not for current program
        const filteredScores = allFunderScoresForOrganization.filter(score => score.grantseeking_program_id === currentProgram.id);
        
        // Group into goodFitGmps, badFitGmps, archivedGmps, savedGmps
        const goodFitFunders = []
        const archivedFunders = []
        const savedFunders = []

        for (const score of filteredScores) {
            if (score.status === "saved") {
                savedFunders.push(score);
            } else if (score.status === "archived") {
                archivedFunders.push(score);
            } else if (score.status === "active") {
                goodFitFunders.push(score);
            }
        }
        
        setGoodFitFundersRowData(goodFitFunders);
        setArchivedFundersRowData(archivedFunders);
        setSavedFundersRowData(savedFunders);
        
    }, [allFunderScoresForOrganization, currentProgram]);

    useEffect(() => {
        let sortedData = [];
        if (tableViewMode === "goodFits") {
            sortedData = goodFitFundersRowData;
            console.log("goodFitFundersRowData", goodFitFundersRowData);
        } else if (tableViewMode === "archived") {
            sortedData = archivedFundersRowData;
            console.log("archivedFundersRowData", archivedFundersRowData);
        } else if (tableViewMode === "saved") {
            sortedData = savedFundersRowData;
            console.log("savedFundersRowData", savedFundersRowData);
        }

        // Sort the data by relevant_awards_len in descending order
        sortedData.sort((a, b) => b.relevant_awards_len - a.relevant_awards_len);
        setRowData(sortedData);
    }, [tableViewMode, goodFitFundersRowData, archivedFundersRowData, savedFundersRowData]);

    const handleRowClick = (funderScore) => {
        setFunderEinForDrawerView(funderScore.funder_ein);
        setSelectedDrawerView('funder');
    }

    const updateStatusOfFunderScore = async (scoreId, newStatus) => {
        await updateStatusOfFunderScoreInSupabase(scoreId, newStatus);
        await refreshFunderScoresForOrganization();
    }

    // Add this function to apply custom styles to rows
    return (
        <div className="flex w-full h-full" >
            <div className='w-1/3 h-full'>            
                {/* Header Buttons */}
                <div className='flex flex-row px-4 py-3 justify-between border-b border-gray-300 h-16 text-xs'>                    
                    <button className={` px-2 py-1 rounded-md ${tableViewMode === "goodFits" ? 'bg-indigo-500 text-white font-bold' : 'bg-indigo-100'}`} onClick={() => setTableViewMode("goodFits")}>Good Fits ({goodFitFundersRowData.length})</button>
                    <button className={` px-2 py-1 rounded-md ${tableViewMode === "archived" ? 'bg-gray-300 font-bold' : 'bg-gray-100'}`} onClick={() => setTableViewMode("archived")}>Archived ({archivedFundersRowData.length})</button>
                    <button className={` px-2 py-1 rounded-md ${tableViewMode === "saved" ? 'bg-pink-300 font-bold' : 'bg-green-100'}`} onClick={() => setTableViewMode("saved")}>Saved ({savedFundersRowData.length})</button>
                </div>

                {/* Table */}
                {rowData.length > 0 ? (
                    <div className='w-full h-full text-left overflow-auto max-h-[calc(100vh-16rem)]'>                    
                    {rowData.map((funderScore, index) => (
                        <div 
                            key={index} 
                            className='bg-orange-100 p-4 rounded-md my-3 mx-2 cursor-pointer'
                            onClick={() => {
                                handleRowClick(funderScore)
                            }}
                        >
                            <p className=''>{funderScore.funder_name}</p>
                            <p className='text-sm italic'>{funderScore.relevant_awards_len} grants made to nonprofits like yours</p>                            
                            <div className='flex flex-row mt-2 gap-2' onClick={(e) => e.stopPropagation()}>
                            {tableViewMode !== 'saved' && (
                                <button className='text-sm text-blue-500 bg-gray-100 px-2 py-1 rounded-md' onClick={() => updateStatusOfFunderScore(funderScore.id, "saved")}>Save</button>
                            )}
                            {tableViewMode !== 'archived' && (
                                <button className='text-sm text-blue-500 bg-gray-100 px-2 py-1 rounded-md' onClick={() => updateStatusOfFunderScore(funderScore.id, "archived")}>Archive</button>
                            )}
                            {tableViewMode === 'archived' && (
                                <button className='text-sm text-blue-500 bg-gray-100 px-2 py-1 rounded-md' onClick={() => updateStatusOfFunderScore(funderScore.id, "active")}>Unarchive</button>
                            )}
                            {tableViewMode === 'saved' && (
                                <button className='text-sm text-blue-500 bg-gray-100 px-2 py-1 rounded-md' onClick={() => updateStatusOfFunderScore(funderScore.id, "active")}>Unsave</button>
                            )}
                            </div>
                        </div>
                    ))}

                    </div>
                ) : (
                    <div className='w-full h-full text-left p-12'>
                        <p className='text-center text-sm text-gray-500'>
                            This feature requires you to add a Primary State and NTEE Code to your program. Click the Modify button above to make these changes. 
                            <br />
                            <br />
                            Once added, you will start seeing funder matches within the next week.
                            <br />
                            <br />
                            For more information, please see the <span onClick={() => window.open("https://ossified-pluto-bdd.notion.site/GrantMagic-Documentation-17546353e32d8076a042c52f7b792c8d#1b146353e32d803a93d6c80cc75ae80f", "_blank")} className='text-blue-500 underline cursor-pointer'>documentation</span>.
                        </p>
                    </div>
                )}
            </div>
            <div className='w-2/3 h-full'>
                <OpportunityAndFunderDrawer 
                    selectedDrawerView={selectedDrawerView}
                    setSelectedDrawerView={setSelectedDrawerView}
                    setShowLoadingModal={setShowLoadingModal}
                    funderEinForDrawerView={funderEinForDrawerView}
                    setFunderEinForDrawerView={setFunderEinForDrawerView}
                    openSaveOpportunityModalWithKnownFunderAndOrGrantmakingProgram={openSaveOpportunityModalWithKnownFunderAndOrGrantmakingProgram}
                    currentProgram={currentProgram}
                    setProgramToModify={setProgramToModify}
                    setShowCreateProgramModal={setShowCreateProgramModal}
                />
            </div>
        </div>                
    );
}

export default PotentialFundersTab;