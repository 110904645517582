import React from 'react';

const GrantDiscoveryOptionsModal = ({
    setCurrentView,
    // setShowAddMoreGrantsModal,    
    setShowGrantDiscoveryOptionsModal,
    setActiveChildViewOfSelectedProgramView,
    membershipDetails,
    setShowPaywallModal
}) => {
    
    return (
        <div className="bg-gray-900 bg-opacity-50" 
             style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: '9999', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
             onClick={() => setShowGrantDiscoveryOptionsModal(false)}
        >
            
            {/* Modal */}
            <div className='text-left bg-white rounded-lg p-16' 
                 style={{ width: '60vw', height: '60vh', overflowY: 'auto' }}
                 onClick={(e) => e.stopPropagation()}>
                
                {/* Header */}                                
                <div className="flex w-full text-center text-gray-500 p-4">
                    <p className='text-3xl font-bold'>Discover Grants</p>                                        
                </div>
                
                {/* Body */}
                <div className="">
                    <div className="cursor-pointer hover:bg-gray-200 p-4 rounded-lg" onClick={() => { 
                        setCurrentView('awardsAdvancedSearch'); 
                        setShowGrantDiscoveryOptionsModal(false); 
                    }}>
                        <h2 className="text-xl font-bold">📚 Search through past awards ➡️</h2>
                        <p>Browse our database of 1M+ past grant awards to find funders for your nonprofit</p>
                    </div>
                    <div className="cursor-pointer p-4 rounded-lg hover:bg-indigo-100" onClick={() => { 
                        if (!membershipDetails) {
                            // setShowGrantDiscoveryOptionsModal(false);
                            setShowPaywallModal(true);
                        } else {
                            setCurrentView('selectedProgramView'); 
                            setActiveChildViewOfSelectedProgramView('fundersAwardsSearch'); 
                            setShowGrantDiscoveryOptionsModal(false); 
                        }
                    }}>
                        <h2 className="text-xl font-bold">🏡 Browse potential funder matches <span className="text-indigo-500">(Premium)</span> ➡️</h2>
                        <p>Find potential funders who have awarded grants to nonprofits like yours</p>
                    </div>
                    <div className="cursor-pointer p-4 rounded-lg hover:bg-indigo-100" onClick={() => { 
                        if (!membershipDetails) {
                            // setShowGrantDiscoveryOptionsModal(false);
                            setShowPaywallModal(true);
                        } else {
                            setCurrentView('selectedProgramView'); 
                            setActiveChildViewOfSelectedProgramView('potentialFundingPrograms'); 
                            setShowGrantDiscoveryOptionsModal(false); 
                        }
                    }}>
                        <h2 className="text-xl font-bold">🙋🏼‍♂️ Browse potential grant making programs <span className="text-indigo-500">(Premium)</span> ➡️</h2>
                        <p>Find potential grant making programs looking for nonprofits like yours</p>                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GrantDiscoveryOptionsModal;
