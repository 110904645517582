import React, { useState, useEffect } from 'react';

function KnownOpportunityBubble({ 
        opportunityId,
        savedOpportunities,
        setSelectedSavedOpportunity
    }) {

    // Stitch together the opportunity name from the opportunityId
    const opportunityName = savedOpportunities.find(opportunity => opportunity.id === opportunityId).name;    

    const knownOpportunityClicked = () => {
        setSelectedSavedOpportunity(savedOpportunities.find(opportunity => opportunity.id === opportunityId));
    };


    return (
        <>                
        <button 
            className="px-2 py-1 rounded text-indigo-800 bg-indigo-100 hover:bg-indigo-300 text-left text-xs"
            onClick={knownOpportunityClicked}
        >
            {opportunityName?.length > 20 ? opportunityName.substring(0, 20) + '...' : opportunityName} →
        </button>                
        </>
    );
}

export default KnownOpportunityBubble;